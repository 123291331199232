import { useMutation, useQueryClient } from '@tanstack/react-query';
import { partition } from 'lodash-es';
import { useEffect, useState } from 'react';

import {
    emptyProductPayload,
    productCacheKey,
    ProductDto,
    ProductError,
    ProductPayload,
    productsService,
    useProductQuery,
} from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { isResponseError } from '@hofy/rest';
import { useToast } from '@hofy/ui';

import { useProductForm } from './useProductForm';

export const useUpdateProduct = (id: UUID) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();
    const { data: product } = useProductQuery(id);

    const [needReplacement, setNeedReplacement] = useState(false);

    const mutation = useMutation({
        mutationFn: (p: ProductPayload) =>
            productsService.updateProduct(id, {
                ...p,
                variants: p.variants.map(v => ({
                    ...v,
                    recommendedRetailPrice: p.isRefurbished
                        ? v.recommendedRetailPrice
                        : v.unbundledPurchasePrice,
                })),
            }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [productCacheKey] });
            showToast({
                type: 'positive',
                message: 'Product modified',
            });
        },
        onError: error => {
            if (isResponseError(error) && error.response?.code === ProductError.ProductNeedsReplacement) {
                setNeedReplacement(true);
            }
        },
    });

    const formState = useProductForm(payload => {
        mutation.mutate(payload);
    }, emptyProductPayload);

    useEffect(() => {
        if (product) {
            const [active, inactive] = partition(product.variants, v => v.isActive);
            formState.form.setValues({
                brand: product.brand,
                description: product.description,
                specifications: product.specifications,
                isActive: product.isActive,
                isInternal: product.isInternal,
                isRefurbished: product.isRefurbished,
                name: product.name,
                category: product.category,
                tier: product.tier,
                images: product.images,
                variants: [...active, ...inactive].map(v => ({
                    id: v.id,
                    sku: v.sku,
                    isActive: v.isActive,
                    isInternal: v.isInternal,
                    recommendedRetailPrice: v.recommendedRetailPrice,
                    isAvailableForStoreAndReuse: v.isAvailableForStoreAndReuse,
                    os: v.os,
                    size: v.size,
                    style: v.style,
                    price: v.price,
                    unbundledPrice: v.unbundledPrice,
                    purchasePrice: v.purchasePrice,
                    unbundledPurchasePrice: v.unbundledPurchasePrice,
                    manufacturerPartCode: v.manufacturerPartCode,
                    allowedAcquisitionTypes: v.allowedAcquisitionTypes,
                    allowedRentalTerms: v.allowedRentalTerms,
                    availability: v.availability,
                    organizationId: v.organization?.id ?? null,
                    image: v.image.id,
                })),
            });
        }
    }, [product]);

    const replaceProduct = (v: ProductDto) => {
        setNeedReplacement(false);
        formState.form.setValues({ replacementProductId: v.id });
        formState.form.submit();
    };

    const forceReplacementProduct = () => {
        setNeedReplacement(false);
        formState.form.setValues({ forceReplacement: true });
        formState.form.submit();
    };

    return {
        ...formState,
        product,
        needReplacement,
        replaceProduct,
        forceReplacementProduct,
        setNeedReplacement,
        isLoadingMutation: mutation.isPending,
    };
};
