export enum UploadUsersErrorCode {
    InvalidFileForUsersUpload = 'invalid_file_for_users_upload',
    InvalidSheetsCountForUsersUpload = 'invalid_sheets_count_for_users_upload',
    InvalidHeaderForUsersUpload = 'invalid_headers_for_users_upload',
    MissingTeam = 'missing_team',
    MissingFirstName = 'missing_first_name',
    MissingLastName = 'missing_last_name',
    MissingEmails = 'missing_emails',
    MissingCountry = 'missing_country',
    InvalidTeam = 'invalid_team',
    InvalidLoginEmailType = 'invalid_login_email_type',
    InvalidCountry = 'invalid_country',
    InvalidNewJoinerStartDate = 'invalid_new_joiner_start_date',
    EmailExists = 'email_exists',
    AnotherUserWithThisEmailAlreadyExists = 'another_user_with_this_email_already_exists',
}
