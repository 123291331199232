import { omit } from 'lodash-es';
import { useState } from 'react';

import { StockLevelsFilters } from '@hofy/api-admin';
import { anyCategoryToList, AnyProductCategory } from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import { countObjectValues } from '@hofy/helpers';
import { useStructMemo } from '@hofy/hooks';
import { useArrayQueryParam, useStringQueryParam } from '@hofy/router';

import { ProductStatus } from '../products/types/ProductStatus';

export const useStockLevelsFilters = (initialValues: StockLevelsFilters) => {
    const [search, setSearch] = useStringQueryParam('search', initialValues.search || '');
    const [category, setCategory] = useStringQueryParam<AnyProductCategory>('category');
    const [productStatus, setProductStatus] = useStringQueryParam<ProductStatus>(ProductStatus.Active);
    const [warehouses, setWarehouses] = useArrayQueryParam<UUID>('warehouses', initialValues.warehouses);
    const [filterZeroStock, setFilterZeroStock] = useState<boolean>(false);

    const queryFilters = useStructMemo({
        search,
        warehouses,
        category: category ? anyCategoryToList(category) : [],
        activeVariants: productStatus === ProductStatus.All ? null : productStatus === ProductStatus.Active,
        filterZeroStock: filterZeroStock,
    });

    const filtersToCount = omit(queryFilters, 'search', 'filterZeroStock', 'activeVariants', 'category');
    const filterCount = countObjectValues(filtersToCount);

    return {
        queryFilters,
        filterCount,
        setSearch,
        setWarehouses,
        category,
        setCategory,
        productStatus,
        setProductStatus,
        setFilterZeroStock,
    };
};
