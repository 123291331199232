import { useMutation, useQueryClient } from '@tanstack/react-query';

import { organizationContractCacheKey, organizationContractService } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { useToast } from '@hofy/ui';

import { useOrganizationContractForm } from './useOrganizationContractForm';

export const useCreateOrganizationContract = (onSuccess: () => void, organizationId: UUID) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: organizationContractService.createOrganizationContract,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [organizationContractCacheKey] });
            showToast({
                type: 'positive',
                message: 'Organization contract saved successfully',
            });
            onSuccess();
        },
    });

    return useOrganizationContractForm(mutation, organizationId);
};
