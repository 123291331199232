import { flatMap, fromPairs } from 'lodash-es';

import { getEnumValues } from '@hofy/global';

import { EventType } from './EventType';

export enum EventGroup {
    Finance = 'finance',
    Collections = 'collections',
    Catalogue = 'catalogue',
    Order = 'order',
    StoreAndReuse = 'store_reuse',
    EndingContracts = 'ending_contracts',
    DeviceRefresh = 'device_refresh',
    HrIntegration = 'hr_integration',
    NewHires = 'new_hires',
}

const eventTypeGroups: Record<EventGroup, EventType[]> = {
    [EventGroup.Collections]: [EventType.DeviceCollection],
    [EventGroup.Catalogue]: [EventType.ProductAdded, EventType.ProductRemoved],
    [EventGroup.Finance]: [EventType.CountryMultiplierChanged, EventType.OrderRequestAutoApprovalDeclined],
    [EventGroup.Order]: [
        EventType.OrderRequest,
        EventType.OrderRequestAutoApprovalDeclined,
        EventType.OrderConfirmed,
        EventType.OrderPreparedManager,
        EventType.OrderDispatchedManager,
        EventType.OrderDeliveredManager,
        EventType.OrderBackorderManager,
    ],
    [EventGroup.StoreAndReuse]: [
        EventType.OrderStoreAndReuseDelivery,
        EventType.OrderStoreAndReuseCollected,
        EventType.OrderStoreAndReuseAvailable,
    ],
    [EventGroup.EndingContracts]: [
        EventType.ContractAlmostExpiringDevice,
        EventType.ContractAlmostExpiringAccessory,
    ],
    [EventGroup.DeviceRefresh]: [EventType.ManageRefreshedDevice],
    [EventGroup.HrIntegration]: [EventType.HrIntegrationIssueReceived],
    [EventGroup.NewHires]: [EventType.NewHireOrderReminder],
};
export const eventTypeToGroup: Partial<Record<EventType, EventGroup>> = fromPairs(
    flatMap(eventTypeGroups, (value, key) => value.map(v => [v, key])),
);

export const allEventGroups = getEnumValues<EventGroup>(EventGroup);
