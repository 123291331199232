import React, { FC } from 'react';

import { isRangeUnbound } from '@hofy/global';

import { FormFieldApi } from '../../../../hooks';
import { Optional } from '../../../../types';
import { LabeledNumberRangeInput, LabeledNumberRangeInputProps } from './LabeledNumberRangeInput';
import { NumberRange } from './types/NumberRange';

type FormNumberRangeInputOptionalProps = Optional<LabeledNumberRangeInputProps, 'onChange'>;
type FormNumberRangeInputOmittedProps = Omit<FormNumberRangeInputOptionalProps, 'errorMessage' | 'value'>;

interface FormNormalNumberInputProps extends FormNumberRangeInputOmittedProps {
    api: FormFieldApi<NumberRange>;
    nullable?: false;
}

interface FormNullableNumberInputProps extends FormNumberRangeInputOmittedProps {
    api: FormFieldApi<NumberRange | null>;
    nullable: true;
}

type FormNumberRangeInputProps = FormNormalNumberInputProps | FormNullableNumberInputProps;

export const FormNumberRangeInput: FC<FormNumberRangeInputProps> = ({
    api,
    onChange,
    onBlur,
    ...inputProps
}) => {
    return (
        <LabeledNumberRangeInput
            {...inputProps}
            ref={api.ref}
            value={api.value!}
            onChange={value => {
                const v = value === null || isRangeUnbound(value) ? null! : value;
                api.setValue(v);
                onChange?.(v);
            }}
            errorMessage={api.errorMessage}
            onBlur={() => {
                api.setTouched(true);
                onBlur?.();
            }}
        />
    );
};
