import { useMutation, useQueryClient } from '@tanstack/react-query';
import { noop } from 'lodash-es';

import {
    assignmentsCacheKey,
    itemCacheKey,
    repairsCacheKey,
    repairsService,
    UpdateRepairStatusPayload,
} from '@hofy/api-admin';
import { isResolvedRepairStatus, ItemGrade, ItemStatus, RepairStatus } from '@hofy/api-shared';
import { Currency, DateString, isGreaterThanZeroDecimal, Price, UUID, zeroPrice } from '@hofy/global';
import { nowISODate } from '@hofy/helpers';
import { is, isRequired, isRequiredIf, useForm, validator } from '@hofy/ui';

interface UpdateRepairStatusFormData {
    status: RepairStatus | null;
    updatedOn: DateString;
    grade: ItemGrade | null;
    itemStatus: ItemStatus | null;
    isBillable: boolean;
    currency: Currency;
    repairFee: Price;
}

interface ValidatedUpdateRepairStatusFormData extends UpdateRepairStatusFormData {
    status: RepairStatus;
    grade: ItemGrade;
}

export const useUpdateRepairStatus = (repairId: UUID, onSuccess = noop) => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (p: UpdateRepairStatusPayload) => repairsService.updateRepairStatus(repairId, p),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [repairsCacheKey],
            });
            queryClient.invalidateQueries({
                queryKey: [assignmentsCacheKey],
            });
            queryClient.invalidateQueries({
                queryKey: [itemCacheKey],
            });
            onSuccess();
        },
    });

    const form = useForm<UpdateRepairStatusFormData, ValidatedUpdateRepairStatusFormData>({
        initial: {
            status: null,
            updatedOn: nowISODate(),
            grade: null,
            itemStatus: null,
            isBillable: false,
            currency: Currency.USD,
            repairFee: zeroPrice(Currency.USD),
        },
        onSubmit: ({ status, updatedOn, grade, itemStatus, isBillable, repairFee }) => {
            mutation.mutate({
                status,
                updatedOn,
                grade,
                itemStatus,
                isBillable,
                repairFee,
            });
        },
        validate: validator<UpdateRepairStatusFormData>({
            status: isRequired('Repair status is required'),
            updatedOn: isRequired('Repair change date is required'),
            grade: isRequiredIf(
                value => value.status !== null && isResolvedRepairStatus(value.status),
                'Item grade is required when resolving the repair',
            ),
            itemStatus: isRequiredIf(
                value => value.status !== null && isResolvedRepairStatus(value.status),
                'Item status is required when resolving the repair',
            ),
            repairFee: is(
                (value, form) =>
                    !form.isBillable || (form.isBillable && isGreaterThanZeroDecimal(value.amount)),
                'Repair fee cannot be zero or negative',
            ),
        }),
    });

    return {
        form,
        isSubmitting: mutation.isPending,
    };
};
