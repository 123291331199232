import { defaults } from 'lodash-es';
import React, { FC, ReactNode } from 'react';

import { useMetaEnter, useStructMemo } from '@hofy/hooks';

import { DisableContextProvider } from '../../../contexts';
import { Box, BoxProps } from '../../base';
import { FormContextProvider, FormContextValue, useFormContext } from './formContext';

interface FormSegmentProps extends Partial<FormContextValue>, BoxProps {
    children: ReactNode;
    /** `disabled` does not come from the `FormContext`, but it will be applied to the `DisableContext` */
    disabled?: boolean;
}

export const FormSegment: FC<FormSegmentProps> = ({
    onSubmit,
    children,
    disabled: formSegmentDisabled = false,
    isLoading = false,
    submitOnEnter,
    ...rest
}) => {
    const currentContext = useFormContext();
    const newSettings: Partial<FormContextValue> = { onSubmit, isLoading, submitOnEnter };

    const settings: FormContextValue = useStructMemo(defaults(newSettings, currentContext));

    const disabled = currentContext.isLoading || formSegmentDisabled;

    const ref = useMetaEnter({
        onActivate: () => {
            if (!disabled) {
                settings.onSubmit();
            }
        },
        requireMetaKey: !settings.submitOnEnter,
    });

    return (
        <DisableContextProvider disabled={disabled}>
            <FormContextProvider value={settings}>
                <Box ref={ref} {...rest}>
                    {children}
                </Box>
            </FormContextProvider>
        </DisableContextProvider>
    );
};
