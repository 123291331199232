import { useMutation, useQueryClient } from '@tanstack/react-query';
import { noop } from 'lodash-es';

import { assignmentsCacheKey, itemCacheKey, repairsCacheKey, repairsService } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { useToast } from '@hofy/ui';

export const useRevertRepairStatus = (repairId: UUID, onSuccess = noop) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: repairsService.revertRepairStatus,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [repairsCacheKey] });
            queryClient.invalidateQueries({ queryKey: [assignmentsCacheKey] });
            queryClient.invalidateQueries({ queryKey: [itemCacheKey] });
            showToast({
                type: 'positive',
                message: 'Repair status reverted successfully',
            });
            onSuccess();
        },
    });

    return {
        revertRepairStatus: () => mutation.mutate(repairId),
        isSubmitting: mutation.isPending,
    };
};
