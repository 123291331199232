import { isString } from 'lodash-es';
import React, { FC, isValidElement, ReactElement, useRef } from 'react';
import styled from 'styled-components';

import { useImageLoad } from '@hofy/hooks';

export interface FallbackImageProps {
    src?: string;
    fallback: string | ReactElement;
}

export const FallbackImage: FC<FallbackImageProps> = ({ src, fallback }) => {
    const ref = useRef<HTMLImageElement>(null);
    const { isError } = useImageLoad(ref, src);

    const showFallback = isError || !src;

    if (isValidElement(fallback) && showFallback) {
        return <>{fallback}</>;
    }

    const fallbackSrc = isString(fallback) ? fallback : undefined;

    return <Img loading='lazy' src={showFallback ? fallbackSrc : src} ref={ref} />;
};

const Img = styled.img`
    width: 100%;
    object-fit: contain;
    max-height: 100%;
    aspect-ratio: 1;
`;
