import { xor } from 'lodash-es';
import React, { ReactNode } from 'react';

import { BlockFilter } from './BlockFilter';
import { BlockFilterItem } from './BlockFilterItem';

interface EnumMultiBlockFilterProps<T> {
    title: string;
    selected: T[];
    onChange(type?: T[]): void;
    items: T[];
    renderItem(type: T): ReactNode;
    icon?: Svg;
}

export const EnumMultiBlockFilter = <T,>({
    title,
    selected,
    onChange,
    items,
    renderItem,
    icon,
}: EnumMultiBlockFilterProps<T>) => {
    return (
        <BlockFilter title={title} icon={icon}>
            {items.map((item, index) => (
                <BlockFilterItem
                    key={`${item}-${index}`}
                    name={renderItem(item)}
                    selected={selected.includes(item)}
                    onClick={() => onChange(xor(selected, [item]))}
                />
            ))}
        </BlockFilter>
    );
};
