import { sortBy } from 'lodash-es';
import React, { FC, useMemo } from 'react';

import { allCatalogueCategories, ProductCategory, useTrProductCategory } from '@hofy/api-shared';
import { FormFieldApi, FormSelectSearch, TestKeyAware } from '@hofy/ui';

interface BaseProps {
    options?: ProductCategory[];
    label: string;
    isRequired?: boolean;
    disabled?: boolean;
}

interface NormalProps extends BaseProps, TestKeyAware {
    api: FormFieldApi<ProductCategory>;
    nullable?: false;
}

interface NullableProps extends BaseProps, TestKeyAware {
    api: FormFieldApi<ProductCategory | null>;
    nullable: true;
}

type FormCategorySelectProps = NormalProps | NullableProps;

export const FormCategorySelect: FC<FormCategorySelectProps> = ({
    label,
    options = allCatalogueCategories,
    api,
    isRequired,
    disabled,
}) => {
    const trProductCategory = useTrProductCategory();
    const categories = useMemo(() => sortBy(options, [category => trProductCategory(category)]), []);

    return (
        <FormSelectSearch
            label={label}
            api={api}
            options={categories}
            toText={trProductCategory}
            isRequired={isRequired}
            disabled={disabled}
        />
    );
};
