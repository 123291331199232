import { stringifyUrl } from 'query-string';

import { PageRequest, PageResponse, UUID } from '@hofy/global';
import { restClient } from '@hofy/rest';

import { PlatformSubscriptionPayload } from './types/PlatformSubscriptionPayload';
import { SubscriptionDetailsDto } from './types/SubscriptionDetailsDto';
import { SubscriptionDto } from './types/SubscriptionDto';
import { SubscriptionFilters } from './types/SubscriptionFilters';

class SubscriptionService {
    public listSubscriptions = async (
        organizationId: UUID,
        page: PageRequest,
        filters: SubscriptionFilters,
    ): Promise<PageResponse<SubscriptionDto>> => {
        return restClient.getJson<PageResponse<SubscriptionDto>>(
            stringifyUrl({
                url: `/api/admin/organizations/${organizationId}/subscriptions`,
                query: {
                    ...page,
                    ...filters,
                },
            }),
        );
    };

    public createSubscription = async (
        organizationId: UUID,
        payload: PlatformSubscriptionPayload,
    ): Promise<number> => {
        const response = await restClient.postJson<{ id: number }>(
            `/api/admin/organizations/${organizationId}/subscriptions`,
            payload,
        );
        return response.id;
    };

    public updateSubscription = async (
        organizationId: UUID,
        subscriptionId: UUID,
        payload: PlatformSubscriptionPayload,
    ) => {
        return restClient
            .put(`/api/admin/organizations/${organizationId}/subscriptions/${subscriptionId}`, payload)
            .then();
    };

    public replaceSubscription = async (
        organizationId: UUID,
        subscriptionId: UUID,
        payload: PlatformSubscriptionPayload,
    ) => {
        return restClient
            .post(
                `/api/admin/organizations/${organizationId}/subscriptions/${subscriptionId}/replace`,
                payload,
            )
            .then();
    };

    public getSubscription = async (organizationId: UUID, subscriptionId: UUID) => {
        return restClient.getJson<SubscriptionDetailsDto>(
            `/api/admin/organizations/${organizationId}/subscriptions/${subscriptionId}`,
        );
    };

    public getDefaultSubscription = async (organizationId: UUID) => {
        return restClient.getJson<SubscriptionDto>(
            `/api/admin/organizations/${organizationId}/subscriptions/default`,
        );
    };

    public deleteSubscription = async (organizationId: UUID, subscriptionId: UUID): Promise<void> => {
        return restClient
            .delete(`/api/admin/organizations/${organizationId}/subscriptions/${subscriptionId}`)
            .then();
    };
}

export const subscriptionService = new SubscriptionService();
