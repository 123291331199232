import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { ShipmentType } from '@hofy/api-shared';
import { UUID } from '@hofy/global';

import { AdminNavLink } from '../../components/routing/AdminNavLink';
import { ShipmentAction } from '../shipmentSlideout/ShipmentAction';
import { useAssignmentsPageTab } from './AssignmentsTabContext';

export const useAssignmentsLinks = () => {
    const { location } = useHistory();

    const tab = useAssignmentsPageTab();

    const link = useCallback(
        (path: string, query?: string) => {
            const params = !query
                ? location.search
                : location.search
                  ? `${location.search}&${query}`
                  : `?${query}`;
            return `${path}${params}`;
        },
        [location.search],
    );

    // shipment navigation
    const getCreateNewShipmentLink = useCallback(
        (type: ShipmentType) => link(`${AdminNavLink.Assignments}/${tab}/new-shipment/${type}`),
        [link, tab],
    );

    const getOpenShipmentLink = useCallback(
        (id: UUID) => link(`${AdminNavLink.Assignments}/${tab}/shipment/${id}`),
        [link, tab],
    );

    const getOpenShipmentNotesLink = useCallback(
        (id: UUID) => link(`${AdminNavLink.Assignments}/${tab}/shipment/${id}/notes`),
        [link, tab],
    );

    const getShipmentActionLink = useCallback(
        (id: UUID, action: ShipmentAction) =>
            link(`${AdminNavLink.Assignments}/${tab}/shipment/${id}/details/${action}`),
        [link, tab],
    );

    // assignment navigation
    const getOpenAssignmentLink = useCallback(
        (id: UUID) => link(`${AdminNavLink.Assignments}/${tab}/assignment/${id}`),
        [link, tab],
    );

    const getOpenAssignmentDetailLink = useCallback(
        (id: UUID) => link(`${AdminNavLink.Assignments}/${tab}/assignment/${id}/details`),
        [link, tab],
    );

    const getAssignmentNotesLink = useCallback(
        (id: UUID) => link(`${AdminNavLink.Assignments}/${tab}/assignment/${id}/notes`),
        [link, tab],
    );

    const getConfigureDeviceLink = useCallback(
        (id: UUID) => link(`${AdminNavLink.Assignments}/${tab}/assignment/${id}/details/configure`),
        [link, tab],
    );

    const getDataErasureLink = useCallback(
        (id: UUID) => link(`${AdminNavLink.Assignments}/${tab}/assignment/${id}/details/data-erasure`),
        [link, tab],
    );

    const getSubstituteProductLink = useCallback(
        (id: UUID) => link(`${AdminNavLink.Assignments}/${tab}/assignment/${id}/details/substitute`),
        [link, tab],
    );

    const getPickCustomProductLink = useCallback(
        (id: UUID) => link(`${AdminNavLink.Assignments}/${tab}/assignment/${id}/details/custom-product`),
        [link, tab],
    );

    const getCreateConsumableLink = useCallback(
        (id: UUID) => link(`${AdminNavLink.Assignments}/${tab}/assignment/${id}/details/create-consumable`),
        [link, tab],
    );

    // item navigation
    const getOpenItemLink = useCallback(
        (id: UUID) => link(`${AdminNavLink.Assignments}/${tab}/item/${id}`),
        [link, tab],
    );

    // shipment navigation
    const getShipmentAssignmentsLink = useCallback(
        (id: UUID) => link(`${AdminNavLink.Assignments}/${tab}/shipment/${id}/assignments`),
        [link, tab],
    );

    // repair navigation
    const getCreateRepairLink = useCallback(
        (id: UUID) => link(`${AdminNavLink.Assignments}/${tab}/create-repair/${id}`),
        [link, tab],
    );

    const getOpenRepairLink = useCallback(
        (id: UUID) => link(`${AdminNavLink.Assignments}/${tab}/repair/${id}`),
        [link, tab],
    );

    // purchase orders navigation
    const getCreateNewPurchaseOrderLink = useCallback(
        (id: UUID) => link(`${AdminNavLink.Assignments}/${tab}/create-purchase-order`, `shipmentId=${id}`),
        [link, tab],
    );

    const getInPersonTransferLink = useCallback(
        () => link(`${AdminNavLink.Assignments}/${tab}/in-person-transfer`),
        [link, tab],
    );

    return {
        getCreateNewShipmentLink,
        getOpenShipmentLink,
        getOpenShipmentNotesLink,
        getShipmentActionLink,
        getOpenAssignmentLink,
        getAssignmentNotesLink,
        getConfigureDeviceLink,
        getDataErasureLink,
        getSubstituteProductLink,
        getPickCustomProductLink,
        getCreateConsumableLink,
        getOpenItemLink,
        getShipmentAssignmentsLink,
        getOpenAssignmentDetailLink,
        getCreateRepairLink,
        getOpenRepairLink,
        getCreateNewPurchaseOrderLink,
        getInPersonTransferLink,
    };
};
