import { isObject } from 'lodash-es';
import React, { FC, Fragment, ReactNode } from 'react';

import { DataRecordEntry, isHiddenData } from '@hofy/api-admin';
import { DateTimeString } from '@hofy/global';
import { formatDateTime } from '@hofy/helpers';
import { Color } from '@hofy/theme';
import { Box, FormSection, Paragraph3 } from '@hofy/ui';

import { FieldChangeEntry, UseChangedData } from '../../store/auditLogs/useAuditLog';

interface AuditLogsDetailsSlideoutFieldsProps {
    changedData: UseChangedData;
}

export const AuditLogsDetailsSlideoutFields: FC<AuditLogsDetailsSlideoutFieldsProps> = ({ changedData }) => {
    const renderValue = (v: DataRecordEntry) => {
        if (isHiddenData(v)) {
            return '<<hidden>>';
        }
        if (v === null || v === undefined) {
            return '<<empty>>';
        }
        if (v === true) {
            return 'true';
        }
        if (v === false) {
            return 'false';
        }
        if (Array.isArray(v)) {
            return `[${v.join(', ')}]`;
        }
        if (`${v}`.match('^([0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}.[0-9]{1,9}Z)$')) {
            return formatDateTime(v as DateTimeString);
        }
        if (isObject(v)) {
            return JSON.stringify(v, null, 2);
        }
        return v;
    };

    const changedEntry = (v: FieldChangeEntry) => {
        return (
            <>
                {renderValue(v.from)}
                &nbsp; ➞ &nbsp;
                {renderValue(v.to)}
            </>
        );
    };

    const keyToLabel = (pathKey: string) => {
        const v = pathKey.split('.');
        if (v.length === 1) {
            return v[0];
        }

        const separator = <>&nbsp; ➞ &nbsp;</>;
        return v.map((k, index) => (
            <Fragment key={index}>
                {index > 0 && separator}
                {k}
            </Fragment>
        ));
    };

    return (
        <>
            {changedData.changedData.length > 0 && (
                <FormSection label='Changed data' labelGap={20} gap={10} marginBottom={30}>
                    {changedData.changedData.map((d, index) => (
                        <EntryRow key={index} label={keyToLabel(d.key)} value={changedEntry(d)} />
                    ))}
                </FormSection>
            )}

            <FormSection label='Stored data' labelGap={20} gap={10}>
                {changedData.storedData.map((d, index) => (
                    <EntryRow key={index} label={keyToLabel(d.key)} value={renderValue(d.value)} />
                ))}
            </FormSection>
        </>
    );
};

interface EntryRowProps {
    label: ReactNode;
    value: ReactNode;
}

const EntryRow: FC<EntryRowProps> = ({ label, value }) => {
    return (
        <Box row justify='flex-start'>
            <Paragraph3 shrink={0} width={250} color={Color.Neutral300}>
                {label}
            </Paragraph3>
            <Paragraph3 shrink={0} flex={1} color={Color.Neutral500}>
                {value}
            </Paragraph3>
        </Box>
    );
};
