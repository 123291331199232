import { filter, includes, map, xor } from 'lodash-es';
import React, { FC } from 'react';

import { allFixedAssetTypes, useHofySubsidiaryRefs } from '@hofy/api-admin';
import { HofySubsidiaryRef } from '@hofy/api-shared';
import { MonthDropdown } from '@hofy/common';
import { UUID } from '@hofy/global';
import { endOfPreviousMonth, toISODate } from '@hofy/helpers';
import { Box, FilterChip, FilterChipList, PageHeader, SearchInput, SvgIcon } from '@hofy/ui';

import { BlockFilter } from '../../../components/design/blockFilters/BlockFilter';
import { BlockFilterButton } from '../../../components/design/blockFilters/BlockFilterButton';
import { BlockFilterChipContainer } from '../../../components/design/blockFilters/BlockFilterChipContainer';
import { BlockFilterContainer } from '../../../components/design/blockFilters/BlockFilterContainer';
import { useBlockFilters } from '../../../components/design/blockFilters/hooks/useBlockFilters';
import { ListBlockFilter } from '../../../components/design/blockFilters/ListBlockFilter';
import { ListMultiBlockFilter } from '../../../components/design/blockFilters/ListMultiBlockFilter';
import { useFixedAssetValuationFilters } from '../../../store/fixedAssets/useFixedAssetValuationFilters';
import { useTrFixedAssetType } from '../../../store/fixedAssets/useTrFixedAssetType';
import { AccountingTabs } from '../AccountingTabs';
import { AdminAccountingTab } from '../AdminAccountingTab';
import { FixedAssetValuationTable } from './components/FixedAssetValuationTable';
import { FixedAssetValuationTotals } from './components/FixedAssetValuationTotals';

export const FixedAssetValuationPage: FC = () => {
    const { search, filters, filterCount, setSearch, setHofySubsidiaries, setUpToMonth, setFixedAssetType } =
        useFixedAssetValuationFilters();

    const { showFilters, toggleShowFilters, filterElRef } = useBlockFilters();

    const { hofySubsidiaries } = useHofySubsidiaryRefs();
    const subsidiaryName = (id: UUID) =>
        hofySubsidiaries.find(subsidiary => subsidiary.id === id)?.name || '';

    const trFixedAssetType = useTrFixedAssetType();

    return (
        <Box column flex='auto'>
            <PageHeader
                title='Fixed asset valuation'
                rightSlot={
                    <>
                        <SearchInput value={search} onChange={setSearch} placeholder='Search' autoFocus />
                        <BlockFilterButton
                            onClick={toggleShowFilters}
                            isOpened={showFilters}
                            count={filterCount}
                        />
                    </>
                }
                tabsSlot={<AccountingTabs tab={AdminAccountingTab.FixedAssetValuation} />}
            />
            <BlockFilterContainer ref={filterElRef} show={showFilters}>
                <ListMultiBlockFilter
                    title='Subsidiary'
                    selected={filter(hofySubsidiaries, subsidiary =>
                        includes(filters.hofySubsidiaries, subsidiary.id),
                    )}
                    items={hofySubsidiaries}
                    onChange={filter =>
                        setHofySubsidiaries(map<HofySubsidiaryRef, UUID>(filter, subsidiary => subsidiary.id))
                    }
                    renderItem={subsidiary => subsidiary.name}
                />
                <ListBlockFilter
                    title='Fixed asset type'
                    selected={filters.fixedAssetType}
                    onChange={setFixedAssetType}
                    items={allFixedAssetTypes}
                    renderItem={trFixedAssetType}
                />
                <BlockFilter title='Up to month' icon={SvgIcon.Calendar}>
                    <MonthDropdown
                        onChange={setUpToMonth}
                        date={filters.upToMonth}
                        maxDate={toISODate(endOfPreviousMonth())}
                    />
                </BlockFilter>
            </BlockFilterContainer>
            <BlockFilterChipContainer show={filterCount > 0}>
                <FilterChipList
                    toKey={subsidiary => subsidiary}
                    selected={filters.hofySubsidiaries}
                    toLabel={subsidiaryName}
                    onClear={value => setHofySubsidiaries(xor(filters.hofySubsidiaries, [value]))}
                    color='blue'
                />
                {filters.fixedAssetType && (
                    <FilterChip
                        label={trFixedAssetType(filters.fixedAssetType)}
                        onClear={() => setFixedAssetType(null)}
                        color='green'
                    />
                )}
            </BlockFilterChipContainer>

            <FixedAssetValuationTotals filters={filters} />
            <FixedAssetValuationTable filters={filters} />
        </Box>
    );
};
