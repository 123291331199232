import { compact } from 'lodash-es';
import React, { FC } from 'react';

import { AssignmentDto } from '@hofy/api-shared';
import { CountryLabel } from '@hofy/core';
import { BaseTable, BaseTableColumnConfig, Placeholder, SvgIllustration, TextCell } from '@hofy/ui';

import { AssignmentCompletionReasonBadge } from '../../components/domain/assignments/badge/AssignmentCompletionReasonBadge';
import { ProductOverview } from '../../components/domain/products/ProductOverview';
import { AssignmentDetailsLink } from '../assignmentsPage/AssignmentLink';
import { AssignmentTypeCell } from '../assignmentsPage/components/AssignmentTypeCell';
import { UserDetailsLink } from '../peoplePage/users/UserLink';
import { AssignmentDateCell } from './components/AssignmentDateCell';
import { ItemDetailsLink } from './ItemDetailsLink';

interface AssignmentsTableProps {
    assignments: AssignmentDto[];
    inSlideout?: boolean;
    withProduct?: boolean;
    maxWidth?: string | number;
}

export const AssignmentsTable: FC<AssignmentsTableProps> = ({
    assignments,
    maxWidth = 1000,
    inSlideout = true,
    withProduct = true,
}) => {
    return (
        <BaseTable
            data={assignments}
            toKey={assignment => assignment.id}
            emptyContent={<Placeholder illustration={SvgIllustration.PackageSearch} title='No assignments' />}
            maxWidth={maxWidth}
            headerHorizontalPadding={inSlideout ? 40 : undefined}
            rowHorizontalPadding={inSlideout ? 30 : undefined}
            columns={
                compact([
                    {
                        id: 'Assignment',
                        header: 'Assignment',
                        flexGrow: 0,
                        width: 90,
                        renderer: assignment => (
                            <TextCell>
                                {assignment.item ? <AssignmentDetailsLink assignment={assignment} /> : `--`}
                            </TextCell>
                        ),
                    },
                    withProduct && {
                        id: 'Product',
                        header: 'Product',
                        flexGrow: 1,
                        width: 250,
                        renderer: assignment => {
                            return (
                                <ProductOverview
                                    images={assignment.variant.image}
                                    imageSize={60}
                                    product={assignment.product}
                                    showCategory
                                />
                            );
                        },
                    },
                    {
                        id: 'Item',
                        header: 'Item',
                        flexGrow: 0,
                        width: 90,
                        renderer: assignment => (
                            <TextCell>
                                {assignment.item ? <ItemDetailsLink item={assignment.item} /> : `--`}
                            </TextCell>
                        ),
                    },
                    {
                        id: 'Team member',
                        header: 'Team member',
                        flexGrow: 1,
                        width: 180,
                        renderer: assignment => (
                            <TextCell>
                                <UserDetailsLink user={assignment.user} />
                            </TextCell>
                        ),
                    },
                    {
                        id: 'country',
                        header: 'User country',
                        flexGrow: 1,
                        width: 155,
                        renderer: assignment => <CountryLabel country={assignment.user.country} />,
                    },
                    {
                        id: 'status',
                        header: 'Status',
                        flexGrow: 1,
                        width: 160,
                        renderer: assignment => <AssignmentTypeCell assignment={assignment} />,
                    },
                    {
                        id: 'completion-reason',
                        header: 'Completion reason',
                        flexGrow: 0,
                        width: 180,
                        renderer: assignment =>
                            assignment.exception ? (
                                <AssignmentCompletionReasonBadge reason={assignment.exception} />
                            ) : (
                                '--'
                            ),
                    },
                    {
                        id: 'history',
                        header: 'History',
                        flexGrow: 0,
                        width: 180,
                        renderer: assignment => <AssignmentDateCell assignment={assignment} />,
                    },
                ]) as BaseTableColumnConfig<AssignmentDto>[]
            }
        />
    );
};
