import { xor } from 'lodash-es';
import React, { FC, useState } from 'react';

import { useBillingEntitiesQuery, useRepaymentPlansQuery } from '@hofy/api-admin';
import { allRealRepaymentStatuses, useTrRepaymentStatus } from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import { Box, PageHeader, SearchInput } from '@hofy/ui';

import { BlockFilterButton } from '../../../components/design/blockFilters/BlockFilterButton';
import { BlockFilterChipContainer } from '../../../components/design/blockFilters/BlockFilterChipContainer';
import { BlockFilterContainer } from '../../../components/design/blockFilters/BlockFilterContainer';
import { EnumMultiBlockFilter } from '../../../components/design/blockFilters/EnumMultiBlockFilter';
import { useBlockFilters } from '../../../components/design/blockFilters/hooks/useBlockFilters';
import { BillingEntityBlockFilter } from '../../../components/domain/filters/BillingEntityBlockFilter';
import { OrganizationBlockFilter } from '../../../components/domain/filters/OrganizationBlockFilter';
import { useOrganizationsRefsQuery } from '../../../store/organizations/useOrganizationsQuery';
import { useRepaymentPlansFilters } from '../../../store/repaymentPlan/useRepaymentPlansFilters';
import { IsInvoicingBlockFilter } from '../invoiceEntries/filters/IsInvoicingBlockFilter';
import { InvoicingTabs } from '../InvoicingTabs';
import { InvoicingTabRouterProps } from '../types/InvoicingTabRouterProps';
import { RepaymentPlansTable } from './components/RepaymentPlansTable';
import { RepaymentPlansActiveFilterChips } from './filters/RepaymentPlansActiveFilterChips';
import { RepaymentPlansMenu } from './RepaymentPlansMenu';

interface RepaymentPlansPageProps extends InvoicingTabRouterProps {
    onOpenPlan(id: UUID): void;
}

export const RepaymentPlansPage: FC<RepaymentPlansPageProps> = ({ tab, tabs, onChangeTab, onOpenPlan }) => {
    const {
        filters,
        setOrganizations,
        setBillingEntities,
        setStatus,
        setSearch,
        setIsInvoiced,
        filterCount,
    } = useRepaymentPlansFilters();

    const {
        repaymentPlans,
        response: { isLoading, isFetchingNextPage, hasNextPage, fetchNextPage },
    } = useRepaymentPlansQuery(filters);

    const [billingEntitySearch, setBillingEntitySearch] = useState('');
    const [orgSearchQuery, setOrgSearchQuery] = useState('');

    const { showFilters, toggleShowFilters, filterElRef } = useBlockFilters();
    const trRepaymentStatus = useTrRepaymentStatus();
    const { data: organizations } = useOrganizationsRefsQuery(filters.organizations, orgSearchQuery);
    const { billingEntities } = useBillingEntitiesQuery({
        organizationIds: filters.organizations,
        search: billingEntitySearch,
        includeIds: filters.billingEntities,
    });

    return (
        <Box column flex='auto'>
            <PageHeader
                title='Two repayment plans'
                rightSlot={
                    <>
                        <SearchInput value={filters.search} onChange={setSearch} />
                        <BlockFilterButton
                            onClick={toggleShowFilters}
                            isOpened={showFilters}
                            count={filterCount}
                        />
                        <RepaymentPlansMenu />
                    </>
                }
                tabsSlot={<InvoicingTabs tabs={tabs} tab={tab} onChangeTab={onChangeTab} />}
            />
            <BlockFilterContainer ref={filterElRef} show={showFilters}>
                <OrganizationBlockFilter
                    selected={filters.organizations}
                    onChange={setOrganizations}
                    organizations={organizations}
                    searchQuery={orgSearchQuery}
                    setSearchQuery={setOrgSearchQuery}
                />
                <BillingEntityBlockFilter
                    selected={filters.billingEntities}
                    onChange={setBillingEntities}
                    billingEntities={billingEntities}
                    search={billingEntitySearch}
                    onSearchChange={setBillingEntitySearch}
                />
                <EnumMultiBlockFilter
                    title='Plan status'
                    selected={filters.status}
                    onChange={setStatus}
                    items={allRealRepaymentStatuses}
                    renderItem={trRepaymentStatus}
                />
                <IsInvoicingBlockFilter isInvoiced={filters.isInvoiced} onChange={setIsInvoiced} />
            </BlockFilterContainer>
            <BlockFilterChipContainer show={filterCount > 0}>
                <RepaymentPlansActiveFilterChips
                    filters={filters}
                    organizations={organizations}
                    onClearIsInvoiced={() => setIsInvoiced(null)}
                    onClearStatus={s => setStatus(xor(filters.status, [s]))}
                    onClearOrganization={s => setOrganizations(xor(filters.organizations, [s]))}
                />
            </BlockFilterChipContainer>
            <RepaymentPlansTable
                entries={repaymentPlans}
                onEntryClick={onOpenPlan}
                infinityScroll={{
                    hasMore: hasNextPage,
                    isLoading: isLoading,
                    isLoadingMore: isFetchingNextPage,
                    loadMore: fetchNextPage,
                }}
            />
        </Box>
    );
};
