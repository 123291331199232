import { useHistory } from 'react-router-dom';

import { UUID } from '@hofy/global';

import { OrganizationContractTab } from '../contracts/types/OrganizationContractTab';

export const useNavigateOrganizationContract = (rootPath: string) => {
    const history = useHistory();

    const getCreateOrganizationContractLink = () => `${rootPath}/create`;
    const handleOpenDetailsOrganizationContract = (id: UUID) =>
        history.push(`${rootPath}/${id}/${OrganizationContractTab.Details}`);
    const navigateOrganizationContractTab = (id: UUID, tab: OrganizationContractTab) =>
        history.push(`${rootPath}/${id}/${tab}`);

    return {
        getCreateOrganizationContractLink,
        handleOpenDetailsOrganizationContract,
        navigateOrganizationContractTab,
    };
};
