import React, { Children, FC, ReactNode } from 'react';
import styled, { CSSObject } from 'styled-components';

import { Color, NumberValues } from '@hofy/theme';
import { Box, BoxProps } from '@hofy/ui';

export interface SeparatedContainerProps extends BoxProps {
    spacing?: NumberValues;
    withFirstElement?: boolean;
    orientation?: 'horizontal' | 'vertical';
    stretch?: boolean;
    lined?: boolean;
}

export const SeparatedContainer: FC<SeparatedContainerProps> = ({
    children,
    spacing = 0,
    withFirstElement = false,
    orientation = 'vertical',
    stretch = false,
    lined = false,
    ...boxProps
}) => {
    const childArray = Children.toArray(children);
    const Renderer = lined ? LinedSeparatedItem : SeparatedItem;
    return (
        <Box row={orientation === 'horizontal'} column={orientation === 'vertical'} {...boxProps}>
            {childArray.map((t: Exclude<ReactNode, boolean | null | undefined>, index: number) => (
                <Renderer
                    index={index}
                    stretch={stretch}
                    spacing={spacing}
                    orientation={orientation}
                    withFirstElement={withFirstElement}
                    key={index}
                    last={index === childArray.length - 1}
                >
                    {t}
                </Renderer>
            ))}
        </Box>
    );
};

interface ItemProps {
    withFirstElement: boolean;
    spacing: NumberValues;
    orientation: 'horizontal' | 'vertical';
    stretch: boolean;
    index: number;
    last: boolean;
}

const SeparatedItem = styled(Box)<ItemProps>(({
    stretch,
    withFirstElement,
    spacing,
    orientation,
    index,
}: ItemProps) => {
    const styles: CSSObject = {};
    const shouldApply = (index: number) => index > 0 || withFirstElement;
    const value = (index: number) => (shouldApply(index) ? spacing : 0);
    if (stretch) {
        styles.flex = 1;
    }
    if (orientation === 'vertical') {
        styles.paddingTop = value(index);
    }
    if (orientation === 'horizontal') {
        styles.paddingLeft = value(index);
    }
    return styles;
});

const LinedSeparatedItem = styled(Box)<ItemProps>(({
    stretch,
    withFirstElement,
    spacing,
    orientation,
    index,
    last,
}: ItemProps) => {
    const styles: CSSObject = {};
    const shouldApply = (index: number) => index > 0 || withFirstElement;
    const value = (index: number) => (shouldApply(index) ? spacing : 0);
    if (stretch) {
        styles.flex = 1;
    }
    if (orientation === 'vertical') {
        if (shouldApply(index)) {
            styles.borderTop = `1px solid ${Color.BackgroundSubtleNeutral}`;
            styles.paddingTop = value(index);
        }
        if (!last) {
            styles.paddingBottom = spacing;
        }
    }
    if (orientation === 'horizontal') {
        if (shouldApply(index)) {
            styles.borderLeft = `1px solid ${Color.BackgroundSubtleNeutral}`;
            styles.paddingLeft = value(index);
        }
        if (!last) {
            styles.paddingRight = spacing;
        }
    }
    return styles;
});
