import { capitalize } from 'lodash-es';

import { getEnumValues } from '@hofy/global';

export enum Permission {
    TeamMemberProfileAccess = 'profile.access',
    ProfileAccessView = 'profile.access.view',

    AuthBasic = 'auth.basic',
    AuthInvite = 'auth.invite',
    AuthSSOConfig = 'auth.sso.config',
    AuthApiConfig = 'auth.api.config',

    AddonsView = 'addons.view',
    AssignmentsUpdate = 'item-assignments.update',
    AssignmentsView = 'item-assignments.view',
    ContractSettingsUpdate = 'contract-settings.update',
    ContractExpirySettingsView = 'contract-expiry-settings.view',
    ContractExpirySettingsUpdate = 'contract-expiry-settings.update',
    ContractsUpdate = 'contracts.update',
    ContractsView = 'contracts.view',
    CountryMultipliersView = 'country-multipliers.view',
    DashboardView = 'dashboard.view',
    DseCreate = 'dse.create',
    DseView = 'dse.view',
    FulfilmentCountrySettingsView = 'fulfilment-country-settings.view',
    HrisUpdate = 'hris.update',
    HrisView = 'hris.view',
    InvoicesView = 'invoices.view',
    IssuesCreate = 'issues.create',
    IssuesManage = 'issues.manage',
    ItemsCreate = 'items.create',
    ItemsUpdate = 'items.update',
    ItemsView = 'items.view',
    NotesView = 'notes.view',
    NotificationPreferencesUpdate = 'notification-preferences.update',
    NotificationPreferencesView = 'notification-preferences.view',
    NotificationsView = 'notifications.view',
    OrdersCreate = 'orders.create',
    OrdersUpdate = 'orders.update',
    OrdersView = 'orders.view',
    OrganizationSecuritySettingsUpdate = 'organization.security-settings-update',
    OrganizationUpdate = 'organization.update',
    OrganizationUpdateSettings = 'organization.update-settings',
    OrganizationView = 'organization.view',
    ProductsUpdate = 'products.update',
    ProductsView = 'products.view',
    ShipmentsView = 'shipments.view',
    SupportedCountriesView = 'supported-countries.view',
    TeamsCreate = 'teams.create',
    TeamsDelete = 'teams.delete',
    TeamsUpdate = 'teams.update',
    TeamsUpdateFinance = 'teams.update-finance',
    TeamsView = 'teams.view',
    HardwarePoliciesUpdate = 'hardware-policies.update',
    UsersCreate = 'users.create',
    UsersPiiView = 'users.pii-view',
    UsersRoleUpdate = 'users.role-update',
    UsersUpdate = 'users.update',
    UsersView = 'users.view',
    WarehousesArchive = 'warehouses.archive',
    WarehousesCreate = 'warehouses.create',
    WarehousesUpdate = 'warehouses.update',
    WarehousesView = 'warehouses.view',
    WelcomePacksView = 'welcome-packs.view',

    AdminActivityLogsView = 'admin.activity-logs.view',
    AdminActivityLogsUpdate = 'admin.activity-logs.update',
    AdminAdminsView = 'admin.admins.view',
    AdminAdminsUpdate = 'admin.admins.update',
    AdminAccountingNonTransactionalSync = 'admin.accounting.non-transactional-sync',
    AdminAccountingTransactionalSync = 'admin.accounting.transactional-sync',
    AdminAccountingView = 'admin.accounting.view',
    AdminAddonDiscountsUpdate = 'admin.addon-discounts.update',
    AdminAddonsCreate = 'admin.addons.create',
    AdminAddonsUpdate = 'admin.addons.update',
    AdminAddonsView = 'admin.addons.view',
    AdminApiKeyCreate = 'admin.api-key.create',
    AdminApiKeyRevoke = 'admin.api-key.revoke',
    AdminApiKeyView = 'admin.api-key.view',
    AdminAssignmentsConfigurationUpdate = 'admin.item-assignments-configuration.update',
    AdminAssignmentsConfigurationView = 'admin.item-assignments-configuration.view',
    AdminAssignmentsFulfill = 'admin.item-assignments.fulfill',
    AdminAssignmentsRevert = 'admin.item-assignments.revert',
    AdminAssignmentsStoreAndReuse = 'admin.item-assignments.store-and-reuse',
    AdminAssignmentsUpdateOnHold = 'admin.item-assignments.update-on-hold',
    AdminAssignmentsView = 'admin.item-assignments.view',
    AdminAuditLogsView = 'admin.audit-logs.view',
    AdminBasic = 'admin.basic',
    AdminBetaFeaturesCreate = 'admin.beta-features.create',
    AdminBetaFeaturesUpdate = 'admin.beta-features.update',
    AdminBetaFeaturesView = 'admin.beta-features.view',
    AdminBillingEntityCollectPayments = 'admin.billing-entity.collect-payments',
    AdminBillingEntityView = 'admin.billing-entity.view',
    AdminBYODOrdersCreate = 'admin.byod-orders.create',
    AdminBYODOrdersReceive = 'admin.byod-orders.receive',
    AdminBYODOrdersView = 'admin.byod-orders.view',
    AdminCollectionRequestSendSurvey = 'admin.collection-request.send-survey',
    AdminCollectionRequestUpdate = 'admin.collection-request.update',
    AdminContractsActivate = 'admin.contracts.activate',
    AdminContractsCancel = 'admin.contracts.cancel',
    AdminContractsCancelStoreAndReuse = 'admin.contracts.cancel-store-and-reuse',
    AdminContractsEnableStoreAndReuse = 'admin.contracts.enable-store-and-reuse',
    AdminContractsEnd = 'admin.contracts.end',
    AdminContractsRegenerate = 'admin.contracts.regenerate',
    AdminContractsRevertEnd = 'admin.contracts.revert-end',
    AdminContractsRollover = 'admin.contracts.rollover',
    AdminContractsUpdate = 'admin.contracts.update',
    AdminContractsUpdatePending = 'admin.contracts.update-pending',
    AdminContractsView = 'admin.contracts.view',
    AdminCountryMultipliersUpdate = 'admin.country-multipliers.update',
    AdminCountryMultipliersView = 'admin.country-multipliers.view',
    AdminCreateOrder = 'admin.create-order',
    AdminFulfilmentCountrySettingsView = 'admin.fulfilment-country-settings.view',
    AdminFulfilmentCountrySettingsUpdate = 'admin.fulfilment-country-settings.update',
    AdminDataErasureUpdate = 'admin.data-erasures.update',
    AdminDataErasureView = 'admin.data-erasures.view',
    AdminErpErrorsUpdate = 'admin.erp-errors.update',
    AdminErpErrorsView = 'admin.erp-errors.view',
    AdminEventsCreate = 'admin.events.create',
    AdminEventsView = 'admin.events.view',
    AdminExchangeRateCreate = 'admin.exchange-rates.create',
    AdminExchangeRateDelete = 'admin.exchange-rates.delete',
    AdminExchangeRateView = 'admin.exchange-rates.view',
    AdminExport = 'admin.export',
    AdminFixedAssetValuationView = 'admin.fixed-asset-valuation.view',
    AdminHostsUploadConfigs = 'admin.hosts.upload-configs',
    AdminHrisSetKey = 'admin.hris.set-key',
    AdminImport = 'admin.import',
    AdminInspectionsCreate = 'admin.inspections.create',
    AdminInspectionsUpdate = 'admin.inspections.update',
    AdminInspectionsView = 'admin.inspections.view',
    AdminNotesManage = 'admin.notes.manage',
    AdminNotesView = 'admin.notes.view',
    AdminInvoicesEntriesCreate = 'admin.invoice-entries.create',
    AdminInvoicesEntriesDelete = 'admin.invoice-entries.delete',
    AdminInvoicesEntriesUpdate = 'admin.invoice-entries.update',
    AdminInvoicesEntriesUpdateEnded = 'admin.invoice-entries.update-ended',
    AdminInvoicesEntriesView = 'admin.invoice-entries.view',
    AdminInvoicesDunning = 'admin.invoices.dunning',
    AdminInvoicesGenerate = 'admin.invoices.generate',
    AdminInvoicesSend = 'admin.invoices.send',
    AdminInvoicesUpload = 'admin.invoices.upload',
    AdminInvoicesView = 'admin.invoices.view',
    AdminIssuesUpdate = 'admin.issues.update',
    AdminIssuesView = 'admin.issues.view',
    AdminItemEventsView = 'admin.item-events.view',
    AdminItemsArchive = 'admin.items.archive',
    AdminItemsChangeLocation = 'admin.items.change-location',
    AdminItemsChangeStatus = 'admin.items.change-status',
    AdminItemsChangeOwnership = 'admin.items.change-ownership',
    AdminItemsCreate = 'admin.items.create',
    AdminItemsCreateCode = 'admin.items.create-code',
    AdminItemsUpdate = 'admin.items.update',
    AdminItemsView = 'admin.items.view',
    AdminItemsEnrolIntoServicePackage = 'admin.items.enrol-into-service-package',
    AdminItemsEnrollWithRelaxedValidation = 'admin.items.enrol-with-relaxed-validation',
    AdminItemValuationView = 'admin.item-valuation.view',
    AdminJobsCancel = 'admin.jobs.cancel',
    AdminJobsView = 'admin.jobs.view',
    AdminJournalsView = 'admin.journals.view',
    AdminOrganizationActivate = 'admin.organization.activate',
    AdminOrganizationCreate = 'admin.organization.create',
    AdminOrganizationDelete = 'admin.organization.delete',
    AdminOrganizationForkBillingEntity = 'admin.organization.fork-billing-entity',
    AdminOrganizationReplaceSubscription = 'admin.organization.replace-subscription',
    AdminOrganizationSyncToZendesk = 'admin.organization.sync-to-zendesk',
    AdminOrganizationUpdate = 'admin.organization.update',
    AdminOrganizationUpdateCurrency = 'admin.organization.update-currency',
    AdminOrganizationUpdateDataErasureSettings = 'admin.organization.update-data-erasure-settings',
    AdminOrganizationUpdateContractExpirySettings = 'admin.organization.update-contract-expiry-settings',
    AdminOrganizationUpdateFinancialSettings = 'admin.organization.update-financial-settings',
    AdminOrganizationUpdateFeatures = 'admin.organization.update-features',
    AdminOrganizationUpdateMdm = 'admin.organization.update-mdm',
    AdminOrganizationUpdateModelSettings = 'admin.organization.update-model-settings',
    AdminOrganizationUpdatePlatformTierConfig = 'admin.organization.update-platform-tier-config',
    AdminOrganizationUpdateStoreAndReuse = 'admin.organization.update-store-and-reuse',
    AdminOrganizationUpdateTrialStatus = 'admin.organization.update-trial-status',
    AdminOrganizationView = 'admin.organization.view',
    AdminProductsCreate = 'admin.products.update',
    AdminProductsImport = 'admin.products.import',
    AdminProductsUpdate = 'admin.products.create',
    AdminProductsView = 'admin.products.view',
    AdminPurchaseOrderChangeStatus = 'admin.purchase-orders.change-status',
    AdminPurchaseOrdersApprove = 'admin.purchase-orders.approve',
    AdminPurchaseOrdersArchive = 'admin.purchase-orders.archive',
    AdminPurchaseOrdersCreate = 'admin.purchase-orders.create',
    AdminPurchaseOrdersUpdate = 'admin.purchase-orders.update',
    AdminPurchaseOrdersView = 'admin.purchase-orders.view',
    AdminRepairsCreate = 'admin.repairs.create',
    AdminRepairsUpdate = 'admin.repairs.update',
    AdminRepairsView = 'admin.repairs.view',
    AdminRepaymentPlanManage = 'admin.repayment-plan.manage',
    AdminRepaymentPlanView = 'admin.repayment-plan.view',
    AdminOrdersView = 'admin.orders.view',
    AdminRevenueRecognitionGenerate = 'admin.revenue-recognition.generate',
    AdminRevenueRecognitionView = 'admin.revenue-recognition.view',
    AdminSalesOrdersCancel = 'admin.sales-orders.cancel',
    AdminSalesOrdersView = 'admin.sales-orders.view',
    AdminShipmentsCreate = 'admin.shipments.create',
    AdminShipmentsUpdate = 'admin.shipments.update',
    AdminShipmentsView = 'admin.shipments.view',
    AdminSignInToOrganization = 'admin.sign-in-to-organization',
    AdminSignInAsUser = 'admin.sign-in-as-user',
    AdminStockUpdate = 'admin.stock.update',
    AdminStockView = 'admin.stock.view',
    AdminSuppliersArchive = 'admin.suppliers.archive',
    AdminSuppliersCreate = 'admin.suppliers.create',
    AdminSuppliersUpdate = 'admin.suppliers.update',
    AdminSuppliersView = 'admin.suppliers.view',
    AdminTermAndConditionCreate = 'admin.term-and-condition.create',
    AdminTermAndConditionUpdate = 'admin.term-and-condition.update',
    AdminTermAndConditionView = 'admin.term-and-condition.view',
    AdminUsersChangeBasicPermissions = 'admin.users.change-basic-permissions',
    AdminUsersChangePermissions = 'admin.users.change-permissions',
    AdminUsersCreate = 'admin.users.create',
    AdminUsersDelete = 'admin.users.delete',
    AdminUsersInvite = 'admin.users.invite',
    AdminUsersResetPassword = 'admin.users.reset-password',
    AdminUsersSyncToZendesk = 'admin.users.sync-to-zendesk',
    AdminUsersUpdate = 'admin.users.update',
    AdminUsersView = 'admin.users.view',
    AdminWarehouseManage = 'admin.warehouse.manage',

    Partner = 'partner',
    Netsuite = 'netsuite',
    Scheduler = 'scheduler',
    Debug = 'debug',
    ShippyPro = 'shippy-pro',
    Deel = 'deel',
    Seed = 'seed',

    PublicUsersView = 'public.users.view',
    PublicOrdersView = 'public.orders.view',
    PublicItemsView = 'public.items.view',
}

export const permissionToName = (v: Permission) => {
    return v
        .split('.')
        .map(i => capitalize(i.replace(/-/g, ' ')))
        .join('/');
};

export const allPermissions = getEnumValues<Permission>(Permission);
