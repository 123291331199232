import { isArray, isEqual, isUndefined, mapValues, omitBy, values, xor } from 'lodash-es';
import { useMemo } from 'react';

import { DateRangeStrings, Sort } from '@hofy/global';
import { asArray } from '@hofy/helpers';
import { QueryDefinitionsObject, SerializableQueryDef, useQueryParams } from '@hofy/router';

type FiltersDefinitionObject<T> = {
    [K in keyof T]: FilterDefinitionSingle<T[K]> | FilterDefinitionMulti<T[K] extends (infer U)[] ? U : T[K]>;
};

export interface FilterDefinitionSingle<T> {
    type: 'single';
    /** Name of the filter, only used in ActiveFilters */
    name?: string;
    /** Query definition for this filter, same as in useQueryParams */
    query: SerializableQueryDef<T>;
    /** All possible values for this filter */
    allValues?: NonNullable<T>[];
    /** Convert value to label, eg. `user-id` -> `'John Doe'` by default uses `String()` */
    toLabel?(value: T): string;
    /** Show this filter in active filter list, default is true */
    showInActive?: boolean;
}

export interface FilterDefinitionMulti<T> {
    type: 'multi';
    /** Name of the filter, only used in ActiveFilters */
    name?: string;
    /** Query definition for this filter, same as in useQueryParams */
    query: SerializableQueryDef<T[]>;
    /** All possible values for this filter */
    allValues?: NonNullable<T>[];
    /** Convert value to label, eg. `user-id` -> `'John Doe'` by default uses `String()` */
    toLabel?(value: T): string;
    /** Show this filter in active filter list, default is true */
    showInActive?: boolean;
    /** Limit of values to show in active filters, default is 3 */
    limit?: number;
}

export interface UseFiltersResult<T, U = {}> {
    filterValues: T;
    filters: FilterApiRecord<T>;
    setFilters(newFilters: Partial<T>): void;
    clearFilters(): void;
    filterCount: number;
    filterContext: U;
}

export type FilterApiRecord<T> = {
    [P in keyof T]: T[P] extends (infer U)[] ? FilterApiMulti<U> : FilterApiSingle<T[P]>;
};

export interface FilterApiSingle<T> {
    type: 'single';
    name: string;
    value: T;
    showInActive: boolean;
    allValues?: NonNullable<T>[];
    isChanged(): boolean;
    set(value: T): void;
    clear(): void;
    toLabel(value: T): string;
    hasValue(): boolean;
}

export interface FilterApiMulti<T> {
    type: 'multi';
    name: string;
    value: T[];
    showInActive: boolean;
    allValues?: NonNullable<T>[];
    limit?: number;
    isChanged(): boolean;
    set(value: T[]): void;
    toggle(value: T[]): void;
    clear(): void;
    toLabel(value: T): string;
    hasValue(): boolean;
}

type FilterApi<T> = FilterApiSingle<T> | FilterApiMulti<T>;

type FilterValues = string | number | boolean | null | Sort<any> | DateRangeStrings;

export type Filters<T> = Record<keyof T, FilterValues | FilterValues[]>;

const DEFAULT_FILTER_LIMIT = 3;

export const useFilters = <T extends Filters<T>, U = any>(
    filterDefinitions: FiltersDefinitionObject<T>,
    defaultValues: T,
    filterContext?: U,
): UseFiltersResult<T, U> => {
    const { query, setQuery } = useQueryParams(
        mapValues(filterDefinitions, filter => filter.query) as QueryDefinitionsObject<T>,
        defaultValues,
    );

    type Key = keyof T;

    const filteredQuery = useMemo(() => {
        const newQuery = mapValues(query, (value, key) => {
            // Also remove all keys that are not in filterDefinitions
            if (!filterDefinitions[key as Key]) {
                return undefined;
            }
            return value ?? defaultValues[key as Key];
        });

        return omitBy(newQuery, isUndefined);
    }, [query]) as T;

    const filters = useMemo<FilterApiRecord<T>>(() => {
        return mapValues(filterDefinitions, (filter, key) => {
            const currentValue = filteredQuery[key as Key] as T[Key];

            switch (filter.type) {
                case 'single':
                    return {
                        type: filter.type,
                        name: filter.name ?? '--',
                        value: currentValue,
                        showInActive: filter.showInActive !== false,

                        allValues: filter.allValues,

                        isChanged: () => !isEqual(currentValue, defaultValues[key as Key]),
                        hasValue: () => currentValue !== null,
                        set: (value: any) =>
                            setQuery({
                                [key]: value,
                            } as Partial<T>),
                        clear: () =>
                            setQuery({
                                [key]: undefined,
                            } as Partial<T>),
                        toLabel: filter.toLabel || String,
                    } satisfies FilterApiSingle<T[Key]>;

                case 'multi':
                    return {
                        type: filter.type,
                        name: filter.name ?? '--',
                        value: currentValue as T[Key][],
                        showInActive: filter.showInActive !== false,

                        allValues: filter.allValues as NonNullable<T[Key]>[],
                        limit: 'limit' in filter ? (filter.limit ?? DEFAULT_FILTER_LIMIT) : undefined,

                        isChanged: () => !isEqual(currentValue, defaultValues[key as Key]),
                        hasValue: () => currentValue !== null && asArray(currentValue).length > 0,
                        set: (value: any) =>
                            setQuery({
                                [key]: value,
                            } as Partial<T>),
                        toggle: (value: any) => {
                            const newValue = isArray(currentValue) ? xor(currentValue, [value]) : undefined;
                            return setQuery({
                                [key]: newValue,
                            } as Partial<T>);
                        },
                        clear: () =>
                            setQuery({
                                [key]: undefined,
                            } as Partial<T>),
                        toLabel: (filter.toLabel || String) as any,
                    } satisfies FilterApiMulti<T[Key]>;
            }
        }) as FilterApiRecord<T>;
    }, [filteredQuery, filterDefinitions]);

    const filterCount = useMemo(() => {
        const filterValues = values<FilterApi<T[keyof T]>>(filters);
        const activeFilters = filterValues.filter(filter => filter.showInActive && filter.hasValue());
        return activeFilters.length;
    }, [filters]);

    const clearFilters = () => {
        setQuery(mapValues(filterDefinitions, () => undefined) as Partial<T>);
    };

    return {
        filterValues: filteredQuery,
        filters,
        setFilters: setQuery,
        clearFilters,
        filterCount,
        filterContext: filterContext || ({} as U),
    };
};
