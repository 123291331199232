import { isEmpty } from 'lodash-es';
import React, { FC } from 'react';

import { useHofySubsidiaries } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { errorMap, nowDate, toISODate } from '@hofy/helpers';
import {
    FormContainer,
    FormDateInput,
    FormInput,
    FormModal,
    FormSelect,
    ModalContent,
    ModalFooter,
    ModalHeader,
    SubmitButton,
    useForm,
} from '@hofy/ui';

import { CancelButton } from '../../../../components/design/button/CancelButton';
import { useTriggerTwoInvoicing } from '../../../../store/invoicing/billingEntities/useTriggerTwoInvoicing';

interface TriggerTwoInvoicingModalProps {
    billingEntityId: UUID;
    onClose(): void;
}

export const TriggerTwoInvoicingModal: FC<TriggerTwoInvoicingModalProps> = ({ billingEntityId, onClose }) => {
    const { triggerInvoicing, isLoading, isError } = useTriggerTwoInvoicing(billingEntityId, onClose);
    const { hofySubsidiaries } = useHofySubsidiaries();

    const form = useForm({
        initial: {
            groupingKey: '',
            hofySubsidiaryId: hofySubsidiaries.length ? hofySubsidiaries[0].id : 0,
            cutOff: toISODate(nowDate()),
        },
        validate: ({ groupingKey, hofySubsidiaryId }) => ({
            groupingKey: errorMap([isEmpty(groupingKey), 'Key cannot be empty']),
            hofySubsidiaryId: errorMap([!hofySubsidiaryId, 'Subsidiary cannot be empty']),
        }),
        onSubmit: triggerInvoicing,
        onDiscard: onClose,
        initialDeps: [hofySubsidiaries],
    });

    return (
        <FormModal
            width={450}
            onSubmit={form.submit}
            onClose={form.discard}
            isLoading={isLoading}
            isError={isError}
        >
            <ModalHeader title='Trigger sandbox invoicing' />
            <ModalContent>
                <FormContainer>
                    <FormInput
                        label='Grouping key'
                        api={form.fields.groupingKey}
                        onChange={(groupingKey: string) =>
                            form.setValues({
                                groupingKey,
                            })
                        }
                        nullable={false}
                        isRequired
                    />
                    <FormDateInput label='Cut off date' api={form.fields.cutOff} />
                    <FormSelect
                        label='Hofy subsidiary'
                        api={form.fields.hofySubsidiaryId}
                        options={hofySubsidiaries.map(v => v.id)}
                        onChange={(hofySubsidiaryId: UUID) =>
                            form.setValues({
                                hofySubsidiaryId,
                            })
                        }
                        toText={hofySubsidiaryId =>
                            hofySubsidiaries.find(v => v.id === hofySubsidiaryId)?.name!
                        }
                    />
                </FormContainer>
            </ModalContent>
            <ModalFooter>
                <CancelButton onClick={form.discard} />
                <SubmitButton label='Submit' />
            </ModalFooter>
        </FormModal>
    );
};
