import styled, { CSSObject } from 'styled-components';

import { Color } from '@hofy/theme';

import { LayoutBox, LayoutBoxProps } from './LayoutBox';

export interface BorderedBoxProps extends LayoutBoxProps {
    borderLeft?: boolean;
    borderRight?: boolean;
    borderTop?: boolean;
    borderBottom?: boolean;
    border?: boolean;
    borderHorizontal?: boolean;
    borderVertical?: boolean;
    borderColor?: Color;
    borderWidth?: number;
    borderStyle?: 'solid' | 'dashed';
}

export const BorderedBox = styled(LayoutBox)<BorderedBoxProps>(({
    border,
    borderHorizontal,
    borderVertical,
    borderLeft = borderHorizontal,
    borderRight = borderHorizontal,
    borderTop = borderVertical,
    borderBottom = borderVertical,
    borderColor = Color.NonContextualBorderDivider,
    borderWidth = 1,
    borderStyle = 'solid',
}) => {
    const styles: CSSObject = {};
    const borderFormat = `${borderWidth}px ${borderStyle} ${borderColor}`;

    if (border) {
        styles.border = borderFormat;
    }

    if (borderLeft) {
        styles.borderLeft = borderFormat;
    }

    if (borderRight) {
        styles.borderRight = borderFormat;
    }

    if (borderTop) {
        styles.borderTop = borderFormat;
    }

    if (borderBottom) {
        styles.borderBottom = borderFormat;
    }

    return styles;
});
