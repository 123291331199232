import { omit } from 'lodash-es';

import { AdminSuppliersFilters } from '@hofy/api-admin';
import { NetsuiteSyncStatus, SupplierStatus, SupplierType } from '@hofy/api-shared';
import { Country, UUID } from '@hofy/global';
import { countObjectValues } from '@hofy/helpers';
import { useStructMemo } from '@hofy/hooks';
import { useArrayQueryParam, useStringQueryParam } from '@hofy/router';

export const useSupplierFilters = () => {
    const [search, setSearch] = useStringQueryParam('search', '');

    const [statuses, setStatuses] = useArrayQueryParam<SupplierStatus>('status', []);
    const [types, setTypes] = useArrayQueryParam<SupplierType>('type', []);
    const [country, setCountry] = useStringQueryParam<Country>('country');
    const [subsidiaries, setSubsidiaries] = useArrayQueryParam<UUID>('subsidiary', []);
    const [netsuiteStatuses, setNetsuiteStatuses] = useArrayQueryParam<NetsuiteSyncStatus>(
        'netsuiteStatus',
        [],
    );

    const filters = useStructMemo<AdminSuppliersFilters>({
        search,
        statuses,
        types,
        country,
        subsidiaries,
        netsuiteStatuses,
    });

    const filtersToCount = omit(filters, 'search');
    const filterCount = countObjectValues(filtersToCount);

    return {
        search,
        filters,
        filterCount,
        setStatuses,
        setTypes,
        setCountry,
        setSubsidiaries,
        setSearch,
        setNetsuiteStatuses,
    };
};
