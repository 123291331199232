import { compact } from 'lodash-es';
import React, { FC } from 'react';

import { BillingEntityContactDto } from '@hofy/api-shared';
import { Box, Paragraph3 } from '@hofy/ui';

interface ContactCellProps {
    contact: BillingEntityContactDto;
}

export const ContactCell: FC<ContactCellProps> = ({ contact }) => {
    const total = compact([
        compact([contact.firstName, contact.lastName]).join(' '),
        contact.email,
        contact.phone,
    ]);
    if (total.length === 0) {
        return <Paragraph3>--</Paragraph3>;
    }
    return (
        <Box>
            {total.map((c, index) => (
                <Paragraph3 key={index}>{c}</Paragraph3>
            ))}
        </Box>
    );
};
