import { cloneDeep, identity, isString } from 'lodash-es';
import { useState } from 'react';

import { uuid } from '@hofy/global';
import { arrayRemove, arrayUpdateAt } from '@hofy/helpers';

import { FormFieldApi, FormFieldRecord } from './formTypes';

/**
 * Extend single form field api to handle array fields
 *
 * @example ```tsx
 *    const { fields, add, remove } = useFormArrayField(form.fields.emails, { email: '' });
 *
 *    {fields.map(({key, api}, index) => (
 *       <FormInput key={key} api={api.email} />
 *    ))}
 * ```
 */

export interface ArrayFieldRecord<T> {
    key: string;
    api: FormFieldRecord<T>;
}
export interface ArrayField<T> {
    add(v?: T): void;
    duplicate(key: string, mapper?: (v: T) => T): void;
    remove(key: string): void;
    clear(): void;
    fields: ArrayFieldRecord<T>[];
}

export const useFormArrayField = <T extends object>(fieldApi: FormFieldApi<T[]>, empty: T): ArrayField<T> => {
    const [listKey, setListKey] = useState<string[]>(() => fieldApi.value.map(() => uuid()));

    type FieldName = keyof T & string;
    const fieldNames = Object.keys(empty) as FieldName[];

    const add = (customEmpty?: T) => {
        fieldApi.setValue([...fieldApi.value, customEmpty ?? empty]);
        setListKey([...listKey, uuid()]);
    };

    const duplicate = (key: string, mapper?: (v: T) => T) => {
        const index = listKey.indexOf(key);
        const duplicatedValue = (mapper ?? identity)(cloneDeep(fieldApi.value[index])) as T;

        const newFieldApiValue = [...fieldApi.value];
        newFieldApiValue.splice(index + 1, 0, duplicatedValue);
        fieldApi.setValue(newFieldApiValue);

        const newListKey = [...listKey];
        newListKey.splice(index, 0, uuid());
        setListKey(newListKey);
    };

    const remove = (key: string) => {
        const index = listKey.indexOf(key);
        fieldApi.setValue(arrayRemove(fieldApi.value, index));
        setListKey(arrayRemove(listKey, index));
    };

    const clear = () => {
        fieldApi.setValue([]);
        setListKey([]);
    };

    const fields = fieldApi.value.map((_, index) => {
        const key = listKey[index];
        const api = Object.fromEntries(
            fieldNames.map(fieldName => {
                const fieldKey = `${key}-${fieldName}`;

                const fieldValue = fieldApi.value[index][fieldName as FieldName];
                const fieldError = Array.isArray(fieldApi.error)
                    ? fieldApi.error[index][fieldName]
                    : undefined;
                const fieldTouched = Array.isArray(fieldApi.touched)
                    ? fieldApi.touched.includes(fieldKey)
                    : fieldApi.touched;
                const fieldShouldShowError = fieldApi.shouldShowError(fieldKey) && isString(fieldError);

                const api: FormFieldApi<T[FieldName]> = {
                    value: fieldValue,
                    setValue: (value: T[FieldName]) => {
                        fieldApi.setValue(
                            arrayUpdateAt(fieldApi.value, index, (item: T) => ({
                                ...item,
                                [fieldName]: value,
                            })),
                        );
                    },
                    touched: fieldTouched,
                    setTouched: (was: boolean, subKey?: string) => {
                        fieldApi.setTouched(was, subKey || fieldKey);
                    },
                    error: fieldError,
                    errorMessage: fieldShouldShowError ? fieldError : undefined,
                    shouldShowError: (subKey?: string) => fieldApi.shouldShowError(subKey || fieldKey),
                    ref: null, // TODO: implement refs for array fields
                };
                return [fieldName, api];
            }),
        ) as FormFieldRecord<T>;

        return { key, api };
    });

    return {
        add,
        duplicate,
        remove,
        clear,
        fields,
    };
};
