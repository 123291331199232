export const activityLogEventKindI18n = {
    'activity-log-event-kind.asset-created-with-user': 'Asset created',
    'activity-log-event-kind.asset-created-at-warehouse': 'Asset created',
    'activity-log-event-kind.asset-pending-delivery': 'Asset pending delivery',
    'activity-log-event-kind.asset-pending-collection': 'Asset pending collection',
    'activity-log-event-kind.asset-pending-transfer': 'Asset pending transfer',
    'activity-log-event-kind.asset-collected-by-courier': 'Asset collected by courier',
    'activity-log-event-kind.asset-transferred-between-users': 'Asset transferred',
    'activity-log-event-kind.asset-archived': 'Asset archived',
    'activity-log-event-kind.asset-clearance-requested': 'Clearance requested',
    'activity-log-event-kind.asset-collected-to-warehouse': 'Asset collected',
    'activity-log-event-kind.asset-delivered-from-warehouse': 'Asset delivered',
    'activity-log-event-kind.asset-pending-repair': 'Asset pending repair',
    'activity-log-event-kind.asset-repair-resolved': 'Asset out of repair',
    'activity-log-event-kind.asset-inspection-completed': 'Asset inspected',
    'activity-log-event-kind.collection-request-created': 'Collection requested',
    'activity-log-event-kind.collection-request-email-sent': 'Collection survey sent by email',
    'activity-log-event-kind.collection-request-email-reminder-sent':
        'Collection survey reminder sent by email',
    'activity-log-event-kind.collection-request-sms-reminder-sent': 'Collection survey reminder sent by SMS',
    'activity-log-event-kind.collection-request-escalated': 'Collection request escalated due to no response',
    'activity-log-event-kind.collection-request-completed': 'Collection survey completed',
    'activity-log-event-kind.collection-request-cancelled': 'Collection request cancelled',
    'activity-log-event-kind.collection-booked': 'Arranging collection',
    'activity-log-event-kind.collection-completed': 'Collection delivered',
};
