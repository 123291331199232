import { CreateOrganizationContractPayload, emptyCreateOrganizationContractPayload } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { isGreaterThan, useForm, validator } from '@hofy/ui';

interface OrganizationContractMutation {
    mutate(formState: CreateOrganizationContractPayload): void;
    isPending: boolean;
    isError: boolean;
}

export const useOrganizationContractForm = (mutation: OrganizationContractMutation, organizationId: UUID) => {
    const form = useForm<CreateOrganizationContractPayload>({
        initial: {
            ...emptyCreateOrganizationContractPayload,
            organizationId,
        },
        onSubmit: mutation.mutate,
        validate: validator<CreateOrganizationContractPayload>({
            paidSeats: isGreaterThan(0, 'Paid seats must be greater than 0'),
        }),
    });

    return {
        isLoading: mutation.isPending,
        isError: mutation.isError,
        form,
    };
};

export type UseOrganizationContractForm = ReturnType<typeof useOrganizationContractForm>;
