import React, { ElementRef, forwardRef } from 'react';

import { Box, Paragraph3 } from '../../../base';
import { Input } from '../../input/Input';
import { useNumberRangeInput } from './hooks/useNumberRangeInput';
import { NumberRangeInputProps } from './types/NumberRangeInputProps';

export const NumberRangeInput = forwardRef<ElementRef<'input'>, NumberRangeInputProps>((props, ref) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { value, onChange, onBlur, nullable, ...rest } = props;
    const { stringFromValue, stringToValue, changeFrom, blurFrom, changeTo, blurTo } =
        useNumberRangeInput(props);

    return (
        <Box row gap={4} ref={ref}>
            <Input value={stringFromValue} onChange={changeFrom} onBlur={blurFrom} {...rest} />
            <Paragraph3>-</Paragraph3>
            <Input value={stringToValue} onChange={changeTo} onBlur={blurTo} {...rest} />
        </Box>
    );
});
