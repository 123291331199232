import { every, fromPairs, keyBy, toPairs } from 'lodash-es';
import { useMemo, useState } from 'react';

import {
    FilterableTable,
    FilterState,
    SortFilterDataTableColumnConfig,
} from '../../types/SortFilterDataTableTypes';

interface useTableFilterData<T> extends FilterableTable {
    data: T[];
}

export const useTableFilter = <T>(
    data: T[],
    columns: SortFilterDataTableColumnConfig<T>[],
): useTableFilterData<T> => {
    const [filterState, setFilterState] = useState<FilterState>(() =>
        fromPairs(columns.filter(c => c.filter).map(v => [v.id, v.filter?.defaultValues || []])),
    );
    const columnMap = useMemo(() => keyBy(columns, v => v.id), [columns]);

    const predicate = (item: T) => {
        return every(
            toPairs(filterState).map(([id, values]: [string, any[]]) => {
                if (!values.length) {
                    return true;
                }
                return columnMap[id].filter!.predicate(item, values);
            }),
        );
    };

    const effectiveData = useMemo(() => {
        return data.filter(predicate);
    }, [data, filterState, columns]);

    const setFilter = (id: string, values: any[]) => {
        const columnFilterConfig = columnMap[id].filter;

        if (values.length === columnFilterConfig!.entries?.length) {
            setFilterState({ ...filterState, [id]: [] });
            columnFilterConfig?.onChange?.([]);
        } else {
            setFilterState({ ...filterState, [id]: values });
            columnFilterConfig?.onChange?.(values);
        }
    };

    const clearFilters = () => setFilterState({});
    return { data: effectiveData, setFilter, filterState, clearFilters };
};
