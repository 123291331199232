import React, { FC, memo } from 'react';

import {
    OrganizationContractDto,
    useTrOrganizationContractStatus,
    useTrOrganizationContractTerm,
    useTrOrganizationContractType,
} from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { formatDate } from '@hofy/helpers';
import { usePrice } from '@hofy/hooks';
import { InfiniteScrollConfig, InfinityScrollTable, Placeholder, SvgIllustration } from '@hofy/ui';

interface OrganizationContractTableProps {
    // organizationId: UUID;
    organizationContracts: OrganizationContractDto[];
    infinityScroll: InfiniteScrollConfig;
    onClick(id: UUID): void;
}

const OrganizationContractsTableComponent: FC<OrganizationContractTableProps> = ({
    // organizationId,
    organizationContracts,
    infinityScroll,
    onClick,
}) => {
    const { formatPrice } = usePrice();
    const trStatus = useTrOrganizationContractStatus();
    const trType = useTrOrganizationContractType();
    const trTerm = useTrOrganizationContractTerm();

    return (
        <InfinityScrollTable
            data={organizationContracts}
            toKey={organizationContract => organizationContract.id}
            infinityScroll={infinityScroll}
            onRowClick={({ id }) => onClick(id)}
            emptyContent={
                <Placeholder
                    illustration={SvgIllustration.List}
                    title='No organization contracts'
                    message='No organization contracts for organization'
                />
            }
            flex='auto'
            columns={[
                {
                    id: 'type',
                    header: 'Type',
                    width: 100,
                    renderer: organizationContract => trType(organizationContract.type),
                },
                {
                    id: 'startOn',
                    header: 'Start on',
                    width: 100,
                    renderer: organizationContract => formatDate(organizationContract.startOn),
                },
                {
                    id: 'endOn',
                    header: 'End on',
                    width: 100,
                    renderer: organizationContract => formatDate(organizationContract.endOn),
                },
                {
                    id: 'term',
                    header: 'Term',
                    width: 100,
                    renderer: organizationContract => trTerm(organizationContract.term),
                },
                {
                    id: 'status',
                    header: 'Status',
                    width: 100,
                    renderer: organizationContract => trStatus(organizationContract.status),
                },

                {
                    id: 'price',
                    header: 'Monthly seat price',
                    width: 200,
                    renderer: organizationContract => formatPrice(organizationContract.monthlySeatPrice),
                },
            ]}
        />
    );
};

export const OrganizationContractsTable = memo(OrganizationContractsTableComponent);
