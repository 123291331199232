import { xor } from 'lodash-es';
import React, { FC } from 'react';

import { AdminRefDto, formatAdminName, unassignedUser } from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import { FilterChip } from '@hofy/ui';

interface AssignedUsersFilterChipsProps {
    allUsers: AdminRefDto[];
    selectedUserIds: UUID[];
    onChange(admin: UUID[]): void;
}

export const AssignedUsersFilterChips: FC<AssignedUsersFilterChipsProps> = ({
    selectedUserIds,
    allUsers,
    onChange,
}) => {
    const activeUsers = allUsers.filter(user => selectedUserIds.includes(user.id));
    const isUnassignedSelected = selectedUserIds.includes(unassignedUser);

    const toggleUser = (id: UUID) => {
        onChange(xor(selectedUserIds, [id]));
    };

    return (
        <>
            {isUnassignedSelected && (
                <FilterChip color='blue' label='Unassigned' onClear={() => toggleUser(unassignedUser)} />
            )}
            {activeUsers.map(admin => (
                <FilterChip
                    label={formatAdminName(admin)}
                    onClear={() => toggleUser(admin.id)}
                    color='blue'
                    key={admin.id}
                />
            ))}
        </>
    );
};
