import { identity } from 'lodash-es';
import React, { ReactNode } from 'react';

import { BlockFilter } from './BlockFilter';
import { BlockFilterItem } from './BlockFilterItem';

interface ListBlockFilterProps<T, I> {
    title: ReactNode;
    selected: T | undefined;
    onChange(selected: T | undefined): void;
    items: T[];
    renderItem(item: T): ReactNode;
    icon?: Svg;
    compareIteratee?(item: T): I;
}

export const ListBlockFilter = <T, I>({
    title,
    selected,
    onChange,
    items,
    renderItem,
    icon,
    compareIteratee = identity,
}: ListBlockFilterProps<T, I>) => {
    const isSelected = (item: T) => {
        if (!selected) {
            return false;
        }
        return compareIteratee(selected) === compareIteratee(item);
    };

    const handleChange = (item: T) => {
        if (isSelected(item)) {
            onChange(undefined);
        } else {
            onChange(item);
        }
    };

    return (
        <BlockFilter title={title} icon={icon}>
            {items.map((item, index) => (
                <BlockFilterItem
                    key={`${compareIteratee(item)}-${index}`}
                    name={renderItem(item)}
                    selected={isSelected(item)}
                    onClick={() => handleChange(item)}
                />
            ))}
        </BlockFilter>
    );
};
