import { lowerCase } from 'lodash-es';
import React, { FC } from 'react';

import { getShipmentStatusDate, ShipmentDetailsDto } from '@hofy/api-admin';
import {
    isPossibleStatusTransition,
    ShipmentStatus,
    shipmentStatusNotificationType,
    useTrShipmentStatus,
} from '@hofy/api-shared';
import { formatDate } from '@hofy/helpers';
import { Alert, OuterBoxProps } from '@hofy/ui';

interface ShipmentStatusTransitionPaneProps extends OuterBoxProps {
    shipment: ShipmentDetailsDto;
    toStatus: ShipmentStatus;
}

export const ShipmentStatusTransitionPane: FC<ShipmentStatusTransitionPaneProps> = ({
    shipment,
    toStatus,
    ...restProps
}) => {
    const trShipmentStatus = useTrShipmentStatus();

    if (!isPossibleStatusTransition(shipment.status, toStatus)) {
        return null;
    }

    const translatedStatus = trShipmentStatus(shipment.status);
    const title = `${translatedStatus} > ${trShipmentStatus(toStatus)}`;
    const date = getShipmentStatusDate<ShipmentDetailsDto>(shipment);
    const statement = shipment.status === toStatus ? 'Originally' : 'Shipment';
    const description = `${statement} ${lowerCase(translatedStatus)} on ${formatDate(date)}`;

    return (
        <Alert
            type={shipmentStatusNotificationType[shipment.status]}
            title={title}
            description={description}
            {...restProps}
        />
    );
};
