import React, { FC, ReactNode } from 'react';
import { Route, RouteChildrenProps } from 'react-router-dom';

import { UUID } from '@hofy/global';
import { getEnumParam, getStringParam } from '@hofy/router';

import { OrganizationContractTab } from '../../../store/contracts/types/OrganizationContractTab';

interface OrganizationContractTabRouteProps {
    children(v: {
        organizationContractTab: OrganizationContractTab;
        organizationContractId: UUID;
    }): ReactNode;
    path?: string;
}

export const OrganizationContractTabRoute: FC<OrganizationContractTabRouteProps> = ({
    children,
    ...props
}) => {
    const renderChildren = (props: RouteChildrenProps) => {
        const organizationContractId = getStringParam(
            props.match?.params || {},
            'organizationContractId',
        ) as UUID;
        const organizationContractTab = getEnumParam<OrganizationContractTab>(
            props.match?.params || {},
            'organizationContractTab',
            OrganizationContractTab,
        );

        if (organizationContractId && organizationContractTab) {
            return children({
                organizationContractId,
                organizationContractTab,
            });
        }

        return null;
    };
    return <Route {...props}>{renderChildren}</Route>;
};
