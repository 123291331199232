/* eslint-disable import/no-cycle */
import { AuthError, BaseAuthProvider, TokenPair, TokenPairDto, toTokenPair } from '@hofy/api-auth';

import { authService } from './authService';

const baseUrl = `${window.location.protocol}//${window.location.host}`;

interface SignIn {
    redirect: string | null;
}

class AuthProvider extends BaseAuthProvider {
    protected refresh(args: { refresh_token: string }): Promise<TokenPair | AuthError> {
        return authService.refresh(args);
    }

    protected revoke(args: { refresh_token: string }): Promise<void> {
        return authService.revoke(args);
    }

    protected exchangeTemporary(args: { token: string }): Promise<TokenPair | AuthError> {
        return authService.exchangeTemporary(args);
    }

    public signIn = async ({ redirect }: SignIn) => {
        const params = new URLSearchParams();
        params.append('redirect', redirect || '');
        window.location.href = `${baseUrl}/api/admin/auth/init?${params.toString()}`;
    };

    public setTokens = async (token: TokenPairDto) => {
        this.setValueInStorage(toTokenPair(token));
    };
}

export const authProvider = new AuthProvider();
