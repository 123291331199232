import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import {
    AssignmentConfigurationStatus,
    CollectionPackagingStatus,
    DataErasureStatus,
    ShipmentClass,
} from '@hofy/api-shared';
import { Box, BoxProps } from '@hofy/ui';

import { CollectionMissingItemIndicator } from '../../../../../components/domain/assignments/indicators/CollectionMissingItemIndicator';
import { CollectionMissingLaptopAccessories } from '../../../../../components/domain/assignments/indicators/CollectionMissingLaptopAccessories';
import { CollectionPackagingIndicator } from '../../../../../components/domain/assignments/indicators/CollectionPackagingIndicator';
import { DataErasureIndicator } from '../../../../../components/domain/assignments/indicators/DataErasureIndicator';
import { DeviceConfigurationIndicator } from '../../../../../components/domain/assignments/indicators/DeviceConfigurationIndicator';
import { ExpressShippingIndicator } from '../../../../../components/domain/assignments/indicators/ExpressShippingIndicator';
import { ItemIndicator } from '../../../../../components/domain/assignments/indicators/ItemIndicator';
import { PartnerIndicator } from '../../../../../components/domain/assignments/indicators/PartnerIndicator';
import { RepairIndicator } from '../../../../../components/domain/assignments/indicators/RepairIndicator';
import { StoreAndReuseAvailabilityIndicator } from '../../../../../components/domain/assignments/indicators/StoreAndReuseAvailabilityIndicator';
import { SubstitutionIndicator } from '../../../../../components/domain/assignments/indicators/SubstitutionIndicator';

interface AssignmentIndicatorsCellProps extends BoxProps {
    partnerName: string | null;
    configurationStatus: AssignmentConfigurationStatus | null;
    dataErasureStatus: DataErasureStatus | null;
    isSubstituted: boolean;
    isAssigned: boolean;
    assignedItemNeedsScanning: boolean;
    isDelivery: boolean;
    storeAndReuseManagedByHofy: boolean;
    isProductStoreAndReuseAvailable: boolean;
    isCategoryStoreAndReuseAvailable: boolean;
    collectionMissingItem: boolean | null;
    collectionMissingItemNote: string | null;
    collectionMissingLaptopAccessories: boolean;
    collectionManufacturerPackagingStatus: CollectionPackagingStatus | null;
    collectionCardboardBoxPackagingStatus: CollectionPackagingStatus | null;
    linkToOpenShipmentOrders: string | null;
    linkToPartnerDetails: string | null;
    linkToConfigureDevice: string;
    linkToDataErasure: string;
    linkToStoreAndReuseContracts: string;
    linkToItemDetails: string | null;
    linkToRepair: string | null;
    isExpressShipping: boolean;
    shipmentClass: ShipmentClass | null;
}

export const AssignmentIndicatorsCell: FC<AssignmentIndicatorsCellProps> = ({
    partnerName,
    configurationStatus,
    dataErasureStatus,
    isSubstituted,
    isAssigned,
    assignedItemNeedsScanning,
    isDelivery,
    storeAndReuseManagedByHofy,
    isProductStoreAndReuseAvailable,
    isCategoryStoreAndReuseAvailable,
    collectionMissingItem,
    collectionMissingItemNote,
    collectionMissingLaptopAccessories,
    collectionManufacturerPackagingStatus,
    collectionCardboardBoxPackagingStatus,
    linkToOpenShipmentOrders,
    linkToPartnerDetails,
    linkToConfigureDevice,
    linkToDataErasure,
    linkToStoreAndReuseContracts,
    linkToItemDetails,
    linkToRepair,
    isExpressShipping,
    shipmentClass,
    ...boxProps
}) => {
    const showStoreAndReuseIcon =
        storeAndReuseManagedByHofy && (isProductStoreAndReuseAvailable || isCategoryStoreAndReuseAvailable);

    return (
        <Box row gap={10} {...boxProps}>
            {partnerName && linkToPartnerDetails && (
                <Link to={linkToPartnerDetails} onClick={e => e.stopPropagation()}>
                    <Box pointer>
                        <PartnerIndicator partnerName={partnerName} />
                    </Box>
                </Link>
            )}
            {linkToRepair && <RepairIndicator linkToRepair={linkToRepair} />}
            <ItemIndicatorWithLink
                isAssigned={isAssigned}
                assignedItemNeedsScanning={assignedItemNeedsScanning}
                linkToItemDetails={linkToItemDetails}
                linkToShipmentOrders={linkToOpenShipmentOrders}
            />
            {configurationStatus && (
                <Link to={linkToConfigureDevice} onClick={e => e.stopPropagation()}>
                    <Box pointer>
                        <DeviceConfigurationIndicator configurationStatus={configurationStatus} />
                    </Box>
                </Link>
            )}
            {dataErasureStatus && (
                <Link to={linkToDataErasure} onClick={e => e.stopPropagation()}>
                    <Box pointer>
                        <DataErasureIndicator dataErasureStatus={dataErasureStatus} />
                    </Box>
                </Link>
            )}
            {isDelivery && (
                <DeliveryIndicators
                    isSubstituted={isSubstituted}
                    isProductStoreAndReuseAvailable={isProductStoreAndReuseAvailable}
                    isCategoryStoreAndReuseAvailable={isCategoryStoreAndReuseAvailable}
                    showStoreAndReuseIcon={showStoreAndReuseIcon}
                    linkToStoreAndReuseContracts={linkToStoreAndReuseContracts}
                />
            )}
            {!isDelivery && (
                <CollectionIndicatorStatus
                    collectionMissingItem={collectionMissingItem}
                    collectionMissingItemNote={collectionMissingItemNote}
                    collectionMissingLaptopAccessories={collectionMissingLaptopAccessories}
                    collectionManufacturerPackagingStatus={collectionManufacturerPackagingStatus}
                    collectionCardboardBoxPackagingStatus={collectionCardboardBoxPackagingStatus}
                />
            )}
            {isExpressShipping && <ExpressShippingIndicator shipmentClass={shipmentClass} />}
        </Box>
    );
};

interface DeliveryIndicatorsProps {
    isSubstituted: boolean;
    showStoreAndReuseIcon: boolean;
    isCategoryStoreAndReuseAvailable: boolean;
    isProductStoreAndReuseAvailable: boolean;
    linkToStoreAndReuseContracts: string;
}

const DeliveryIndicators: FC<DeliveryIndicatorsProps> = ({
    isSubstituted,
    showStoreAndReuseIcon,
    isProductStoreAndReuseAvailable,
    isCategoryStoreAndReuseAvailable,
    linkToStoreAndReuseContracts,
}) => (
    <>
        {isSubstituted && <SubstitutionIndicator />}
        {showStoreAndReuseIcon && (
            <Link to={linkToStoreAndReuseContracts} onClick={e => e.stopPropagation()}>
                <Box pointer>
                    <StoreAndReuseAvailabilityIndicator
                        isProductStoreAndReuseAvailable={isProductStoreAndReuseAvailable}
                        isCategoryStoreAndReuseAvailable={isCategoryStoreAndReuseAvailable}
                    />
                </Box>
            </Link>
        )}
    </>
);

interface CollectionIndicatorStatusProps {
    collectionMissingItem: boolean | null;
    collectionMissingItemNote: string | null;
    collectionMissingLaptopAccessories: boolean | null;
    collectionManufacturerPackagingStatus: CollectionPackagingStatus | null;
    collectionCardboardBoxPackagingStatus: CollectionPackagingStatus | null;
}

const CollectionIndicatorStatus: FC<CollectionIndicatorStatusProps> = ({
    collectionMissingItem,
    collectionMissingItemNote,
    collectionMissingLaptopAccessories,
    collectionManufacturerPackagingStatus,
    collectionCardboardBoxPackagingStatus,
}) => (
    <>
        {collectionMissingItem && <CollectionMissingItemIndicator reason={collectionMissingItemNote} />}
        {collectionMissingLaptopAccessories && <CollectionMissingLaptopAccessories />}
        {collectionManufacturerPackagingStatus && (
            <CollectionPackagingIndicator
                type='manufacturer'
                packagingStatus={collectionManufacturerPackagingStatus}
            />
        )}
        {collectionCardboardBoxPackagingStatus && (
            <CollectionPackagingIndicator
                type='cardboardBox'
                packagingStatus={collectionCardboardBoxPackagingStatus}
            />
        )}
    </>
);

interface ItemIndicatorWithLinkProps {
    isAssigned: boolean;
    assignedItemNeedsScanning: boolean;
    linkToItemDetails: string | null;
    linkToShipmentOrders: string | null;
}

const ItemIndicatorWithLink: FC<ItemIndicatorWithLinkProps> = ({
    isAssigned,
    assignedItemNeedsScanning,
    linkToItemDetails,
    linkToShipmentOrders,
}) => {
    const link =
        assignedItemNeedsScanning || !isAssigned
            ? (linkToShipmentOrders ?? linkToItemDetails)
            : linkToItemDetails;
    if (link) {
        return (
            <Link to={link} onClick={e => e.stopPropagation()}>
                <Box pointer>
                    <ItemIndicator isAssigned={isAssigned} isScanRequired={assignedItemNeedsScanning} />
                </Box>
            </Link>
        );
    }
    return <ItemIndicator isAssigned={isAssigned} isScanRequired={assignedItemNeedsScanning} />;
};
