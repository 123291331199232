import { keyBy, upperFirst } from 'lodash-es';
import React, { FC, useMemo } from 'react';

import { AddressField } from '@hofy/address';
import { HofyWarehousePayload } from '@hofy/api-admin';
import { HofySubsidiaryRef, hofyWarehouseTypeDropdownItems } from '@hofy/api-shared';
import { BackButton, SlideoutContent, SlideoutFooter } from '@hofy/common';
import { CountryMultiFormDropdown } from '@hofy/core';
import {
    AsyncButton,
    FormCheckbox,
    FormContainer,
    FormInput,
    FormSection,
    FormSelect,
    FormSelectSearch,
    SvgIcon,
    UseForm,
    useFormObjectField,
} from '@hofy/ui';

interface WarehousesFormProps {
    form: UseForm<HofyWarehousePayload>;
    hofySubsidiaries: HofySubsidiaryRef[];
    isUpdate: boolean;
    isLoadingMutation: boolean;
}

export const WarehouseCreateUpdateForm: FC<WarehousesFormProps> = ({
    form,
    isLoadingMutation,
    hofySubsidiaries,
    isUpdate,
}) => {
    const hofySubsidiaryNames = useMemo(() => keyBy(hofySubsidiaries, v => v.id), [hofySubsidiaries]);
    const address = useFormObjectField(form.fields.address);
    return (
        <>
            <SlideoutContent>
                <FormContainer marginTop={40} marginBottom={40}>
                    <FormSection label='Warehouse details'>
                        <FormInput label='Warehouse name' api={form.fields.name} isRequired />
                        <FormSelect
                            label='Warehouse type'
                            api={form.fields.type}
                            options={hofyWarehouseTypeDropdownItems}
                            toText={o => upperFirst(o)}
                            isRequired
                        />
                        <FormSelectSearch
                            label='Hofy subsidiary'
                            options={hofySubsidiaries.map(v => v.id)}
                            api={form.fields.hofySubsidiaryId}
                            toText={warehouse => hofySubsidiaryNames[warehouse]?.name}
                            isRequired
                        />
                        <CountryMultiFormDropdown
                            label='Countries'
                            value={form.values.countries}
                            onChange={countries =>
                                form.setValues({
                                    countries,
                                })
                            }
                            isRequired
                        />
                    </FormSection>
                    <AddressField api={address} label='Address' />
                    <FormSection label='Services'>
                        <FormCheckbox api={form.fields.dataErasureSupported} label='Data erasure supported' />
                        <FormCheckbox api={form.fields.byodSupported} label='Byod supported' />
                    </FormSection>
                </FormContainer>
            </SlideoutContent>
            <SlideoutFooter>
                <BackButton label='Close' leftIcon={SvgIcon.Cross} />
                <AsyncButton
                    isLoading={isLoadingMutation}
                    label={isUpdate ? 'Update' : 'Create'}
                    onClick={form.submit}
                />
            </SlideoutFooter>
        </>
    );
};
