import { fromPairs, xor } from 'lodash-es';

import { getEnumValues } from '@hofy/global';

import { ItemPart } from './ItemPart';
import { OperatingSystem } from './OperatingSystem';

export enum ProductCategory {
    //Devices
    Laptop = 'laptop',
    MobileDevice = 'mobile_device',
    Desktop = 'desktop',
    Tablet = 'tablet',

    //IT Peripherals
    Monitor = 'monitor',
    DockingStation = 'docking_station',
    Headset = 'headset',
    Keyboard = 'keyboard',
    MiceTrackpad = 'mice_trackpad',
    Printer = 'printer',
    Shredder = 'shredder',
    Webcam = 'webcam',
    WifiRangeExtender = 'wifi_range_extender',

    //IT Accessory
    Dongle = 'dongle',
    Cable = 'cable',
    Adapter = 'adapter',
    Charger = 'charger',

    // Furniture
    Desk = 'desk',
    Chair = 'chair',
    DeskRiser = 'desk_riser',

    //Furniture Accessory
    StandingMat = 'standing_mat',
    LaptopStand = 'laptop_stand',
    Footrest = 'footrest',
    TaskLight = 'task_light',
    DeskTidy = 'desk_tidy',
    MonitorArm = 'monitor_arm',
    WelcomePack = 'welcome_pack',

    Other = 'other',
}

export enum ParentProductCategory {
    Devices = 'devices',
    ITPeripherals = 'it_peripherals',
    ITAccessories = 'it_accessories',
    Furniture = 'furniture',
    FurnitureAccessories = 'furniture_accessories',
    Consumable = 'consumables',
}

export enum ParentProductCategoryGroup {
    Devices = 'devices',
    Accessories = 'accessories',
}

export type AnyProductCategory = ProductCategory | ParentProductCategory;

export const allParentProductCategories: ParentProductCategory[] =
    getEnumValues<ParentProductCategory>(ParentProductCategory);
export const visibleParentProductCategories: ParentProductCategory[] = getEnumValues<ParentProductCategory>(
    ParentProductCategory,
).filter(v => v !== ParentProductCategory.Consumable);

const notSupportedProductCategories: ProductCategory[] = [
    ProductCategory.Printer,
    ProductCategory.Shredder,
    ProductCategory.DeskTidy,
];
export const allCategoriesWithOther = getEnumValues<ProductCategory>(ProductCategory);
export const allCategoriesWithoutOther = allCategoriesWithOther.filter(v => v !== ProductCategory.Other);
export const allCategories = allCategoriesWithoutOther.filter(v => v !== ProductCategory.WelcomePack);
export const allCatalogueCategories = xor(allCategoriesWithoutOther, notSupportedProductCategories);

export const isParentCategory = (
    category: ParentProductCategory | ProductCategory,
): category is ParentProductCategory => {
    return allParentProductCategories.includes(category as ParentProductCategory);
};

export const isParentContainsCategory = (
    parentCategory: ParentProductCategory | ProductCategory,
    category: ProductCategory | undefined,
) => {
    return productCategoriesHierarchy[parentCategory as ParentProductCategory].includes(
        category as ProductCategory,
    );
};

export const productCategoriesHierarchy: Record<ParentProductCategory, ProductCategory[]> = {
    [ParentProductCategory.Devices]: [
        ProductCategory.Laptop,
        ProductCategory.MobileDevice,
        ProductCategory.Desktop,
        ProductCategory.Tablet,
    ],

    [ParentProductCategory.ITPeripherals]: [
        ProductCategory.Monitor,
        ProductCategory.DockingStation,
        ProductCategory.Headset,
        ProductCategory.Keyboard,
        ProductCategory.MiceTrackpad,
        ProductCategory.Printer,
        ProductCategory.Shredder,
        ProductCategory.Webcam,
        ProductCategory.WifiRangeExtender,
    ],

    [ParentProductCategory.ITAccessories]: [
        ProductCategory.Dongle,
        ProductCategory.Cable,
        ProductCategory.Adapter,
        ProductCategory.Charger,
    ],

    [ParentProductCategory.Furniture]: [
        ProductCategory.Desk,
        ProductCategory.Chair,
        ProductCategory.DeskRiser,
    ],

    [ParentProductCategory.FurnitureAccessories]: [
        ProductCategory.StandingMat,
        ProductCategory.LaptopStand,
        ProductCategory.Footrest,
        ProductCategory.TaskLight,
        ProductCategory.DeskTidy,
        ProductCategory.MonitorArm,
    ],
    [ParentProductCategory.Consumable]: [ProductCategory.WelcomePack],
};

export const productCategoriesGroupHierarchy: Record<ParentProductCategory, ParentProductCategoryGroup> = {
    [ParentProductCategory.Devices]: ParentProductCategoryGroup.Devices,
    [ParentProductCategory.ITPeripherals]: ParentProductCategoryGroup.Accessories,
    [ParentProductCategory.ITAccessories]: ParentProductCategoryGroup.Accessories,
    [ParentProductCategory.Furniture]: ParentProductCategoryGroup.Accessories,
    [ParentProductCategory.FurnitureAccessories]: ParentProductCategoryGroup.Accessories,
    [ParentProductCategory.Consumable]: ParentProductCategoryGroup.Accessories,
};

export const assetTrackerCategoriesHierarchy: Record<ParentProductCategory, ProductCategory[]> = {
    ...productCategoriesHierarchy,
    [ParentProductCategory.FurnitureAccessories]: [
        ...productCategoriesHierarchy[ParentProductCategory.FurnitureAccessories],
        ProductCategory.Other,
    ],
};

export const catalogueCategoriesHierarchy: Record<ParentProductCategory, ProductCategory[]> = fromPairs(
    Object.entries(productCategoriesHierarchy).map(([key, value]) => [
        key,
        value.filter(v => allCatalogueCategories.includes(v)),
    ]),
) as Record<ParentProductCategory, ProductCategory[]>;

export const parentProductCategoryForProductCategory: Record<ProductCategory, ParentProductCategory> =
    fromPairs(
        allParentProductCategories.flatMap(c => productCategoriesHierarchy[c].map(ui => [ui, c])),
    ) as Record<ProductCategory, ParentProductCategory>;

export const productCategoryToParentProductCategoryGroup: Record<
    ProductCategory,
    ParentProductCategoryGroup
> = fromPairs(
    allParentProductCategories.flatMap(c =>
        productCategoriesHierarchy[c].map(ui => [ui, productCategoriesGroupHierarchy[c]]),
    ),
) as Record<ProductCategory, ParentProductCategoryGroup>;

export const isLaptop = (category?: ProductCategory): boolean => category === ProductCategory.Laptop;

export const anyCategoryToList = (category: AnyProductCategory): ProductCategory[] => {
    if (isParentCategory(category)) {
        return productCategoriesHierarchy[category];
    }
    return [category];
};

export const isStoreAndReuseCategory = (category: ProductCategory): boolean =>
    category !== ProductCategory.Headset &&
    (isParentContainsCategory(ParentProductCategory.Devices, category) ||
        isParentContainsCategory(ParentProductCategory.ITAccessories, category) ||
        isParentContainsCategory(ParentProductCategory.ITPeripherals, category));

type ConfigurableProductCategory =
    | typeof ProductCategory.Laptop
    | typeof ProductCategory.Desktop
    | typeof ProductCategory.MobileDevice
    | typeof ProductCategory.Tablet;

const isAppleBrand = (brand: string | null) => brand?.toLowerCase() === 'apple';

const categoriesOperatingSystems: Record<
    ConfigurableProductCategory,
    {
        supported: OperatingSystem[];
        getDefaultBrand(productName: string): string;
        getDefaultOs(brand: string | null): OperatingSystem;
    }
> = {
    [ProductCategory.Laptop]: {
        supported: [OperatingSystem.Linux, OperatingSystem.Macos, OperatingSystem.Windows],
        getDefaultBrand: productName => (productName.toLowerCase().includes('mac') ? 'Apple' : ''),
        getDefaultOs: brand => (isAppleBrand(brand) ? OperatingSystem.Macos : OperatingSystem.Windows),
    },
    [ProductCategory.Desktop]: {
        supported: [OperatingSystem.Linux, OperatingSystem.Macos, OperatingSystem.Windows],
        getDefaultBrand: productName => (productName.toLowerCase().includes('mac') ? 'Apple' : ''),
        getDefaultOs: brand => (isAppleBrand(brand) ? OperatingSystem.Macos : OperatingSystem.Windows),
    },
    [ProductCategory.MobileDevice]: {
        supported: [OperatingSystem.IosPhone, OperatingSystem.AndroidPhone],
        getDefaultBrand: productName => (productName.toLowerCase().includes('iphone') ? 'Apple' : ''),
        getDefaultOs: brand =>
            isAppleBrand(brand) ? OperatingSystem.IosPhone : OperatingSystem.AndroidPhone,
    },
    [ProductCategory.Tablet]: {
        supported: [OperatingSystem.IosTablet, OperatingSystem.AndroidTablet],
        getDefaultBrand: productName => (productName.toLowerCase().includes('ipad') ? 'Apple' : ''),
        getDefaultOs: brand =>
            isAppleBrand(brand) ? OperatingSystem.IosTablet : OperatingSystem.AndroidTablet,
    },
};

export const isProductConfigurable = (
    category: ProductCategory | null,
): category is ConfigurableProductCategory => (category ? category in categoriesOperatingSystems : false);

export const isCategoryConsumable = (category: ProductCategory | null) =>
    category ? productCategoriesHierarchy[ParentProductCategory.Consumable].includes(category) : false;

export const getProductOperatingSystems = (category: ProductCategory | null): OperatingSystem[] =>
    isProductConfigurable(category) ? categoriesOperatingSystems[category].supported : [];

interface CategoryAndBrandAware {
    category: ProductCategory | null;
    brand: string | null;
}

export const getProductDefaultBrand = (
    category: ProductCategory | null,
    productName: string | null,
): string | null =>
    isProductConfigurable(category)
        ? categoriesOperatingSystems[category].getDefaultBrand(productName || '')
        : null;

export const getProductDefaultOperatingSystem = ({
    category,
    brand,
}: CategoryAndBrandAware): OperatingSystem | null =>
    isProductConfigurable(category) ? categoriesOperatingSystems[category].getDefaultOs(brand) : null;

export const isDeviceCategory = (category: ProductCategory): boolean =>
    productCategoriesHierarchy[ParentProductCategory.Devices].includes(category);

export const allDeviceCategories = allCategories.filter(v => isDeviceCategory(v));

export const isComputerCategory = (category: ProductCategory): boolean =>
    category === ProductCategory.Laptop || category === ProductCategory.Desktop;

export const isSerialNumberRequired = (category: ProductCategory): boolean => isDeviceCategory(category);

export const requiresAccessoryResetOnInspection = (category: ProductCategory, brand: string): boolean => {
    if (!isAppleBrand(brand)) {
        return false;
    }

    return [ProductCategory.Keyboard, ProductCategory.MiceTrackpad, ProductCategory.Headset].includes(
        category,
    );
};

const customProductCategoryParts: Partial<Record<ProductCategory, ItemPart[]>> = {
    [ProductCategory.Laptop]: [ItemPart.PowerAdapter, ItemPart.PowerCable, ItemPart.Battery],
    [ProductCategory.Desktop]: [ItemPart.PowerAdapter, ItemPart.PowerCable],
    [ProductCategory.MobileDevice]: [ItemPart.Charger, ItemPart.UsbCable],
    [ProductCategory.Tablet]: [ItemPart.Charger, ItemPart.UsbCable],
    [ProductCategory.Monitor]: [ItemPart.PowerCable],
    [ProductCategory.DockingStation]: [ItemPart.PowerAdapter, ItemPart.PowerCable],
    [ProductCategory.Headset]: [ItemPart.Dongle, ItemPart.UsbCable],
    [ProductCategory.Keyboard]: [ItemPart.Dongle, ItemPart.UsbCable],
    [ProductCategory.MiceTrackpad]: [ItemPart.Dongle, ItemPart.UsbCable],
    [ProductCategory.Printer]: [ItemPart.PowerCable],
    [ProductCategory.Shredder]: [ItemPart.PowerCable],
    [ProductCategory.Webcam]: [ItemPart.UsbCable],
    [ProductCategory.WifiRangeExtender]: [ItemPart.PowerCable],
};

export const getProductCategoryParts = (category: ProductCategory): ItemPart[] => [
    ...(customProductCategoryParts[category] || []),
    ItemPart.Other,
];
