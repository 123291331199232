import { allItemGrades, ItemGrade } from '@hofy/api-shared';
import { getEnumValues } from '@hofy/global';

export enum ItemCondition {
    New = 'new',
    Used = 'used',
}

export const allItemConditions = getEnumValues<ItemCondition>(ItemCondition);

export const itemConditionToItemGrades = new Map<ItemCondition, ItemGrade[]>([
    [ItemCondition.New, [ItemGrade.New]],
    [ItemCondition.Used, allItemGrades.filter(grade => grade !== ItemGrade.New)],
]);

export const getGradesForCondition = (condition: ItemCondition | null): ItemGrade[] => {
    return condition ? (itemConditionToItemGrades.get(condition) ?? []) : [];
};
