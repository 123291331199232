import React, { FC } from 'react';

import { ApiKeyDto, OrganizationDto } from '@hofy/api-admin';
import { Permission, permissionToName } from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import { NumberValues } from '@hofy/theme';
import { Badge, BaseTable, Box, Placeholder, SvgIllustration } from '@hofy/ui';

import { useOrganizations } from '../../../store/organizations/useOrganizations';

interface ApiKeyTableProps {
    items: ApiKeyDto[];
    isLoading: boolean;
    onEdit(key: ApiKeyDto): void;
}

export const ApiKeysPage: FC<ApiKeyTableProps> = ({ items, isLoading, onEdit }) => {
    const { organizations } = useOrganizations();

    const renderOrganizationName = (organizations: OrganizationDto[], id: UUID | null) => {
        return id === null ? '' : (organizations.find(o => o.id === id)?.name ?? '');
    };

    const renderPermissions = (permissions: Permission[]) => {
        return (
            <Box row wrap marginBottom={-10 as NumberValues}>
                {permissions.map(permission => (
                    <Badge
                        key={permission}
                        color='grape'
                        label={permissionToName(permission)}
                        marginRight={10}
                        marginBottom={10}
                    />
                ))}
            </Box>
        );
    };

    return (
        <BaseTable
            data={items}
            toKey={entry => entry.id}
            onRowClick={onEdit}
            emptyContent={<Placeholder illustration={SvgIllustration.List} title='No Keys found' />}
            isLoading={isLoading}
            minWidth={1000}
            flex='auto'
            columns={[
                {
                    id: 'value',
                    header: 'Identifier',
                    renderer: entry => entry.keyId,
                    width: 150,
                },
                {
                    id: 'name',
                    header: 'Name',
                    flexGrow: 1,
                    width: 100,
                    renderer: entry => entry.name,
                },
                {
                    id: 'org',
                    header: 'Organization',
                    flexGrow: 1,
                    renderer: entry => renderOrganizationName(organizations, entry.organizationId),
                },
                {
                    id: 'permissions',
                    header: 'Permissions',
                    renderer: entry => renderPermissions(entry.permissions),
                    width: 170,
                },
            ]}
        />
    );
};
