import { sortBy } from 'lodash-es';
import { useMemo, useState } from 'react';

import { Sort } from '@hofy/global';

import { SortableTable, SortFilterDataTableColumnConfig } from '../../types/SortFilterDataTableTypes';

interface useTableSortData<T, S extends string> extends SortableTable<S> {
    data: T[];
}

export const useTableSort = <T, S extends string>(
    data: T[],
    columns: SortFilterDataTableColumnConfig<T>[],
    defaultSort?: Sort<S>,
): useTableSortData<T, S> => {
    const [sort, setSort] = useState<Sort<S> | undefined>(defaultSort);

    const getColumn = (id: string) => columns.find(v => (v.dataKey || v.id) === id)!;

    const sortWithComparator = (v: T[], cmp: (a: T, b: T) => number, descending: boolean) => {
        const sortedData = v.map(t => t).sort(cmp);
        if (descending) {
            sortedData.reverse();
        }
        return sortedData;
    };

    const sortByGetter = (v: T[], getter: (a: T) => string | number, descending: boolean) => {
        const sortedData = sortBy(v, getter);
        if (descending) {
            sortedData.reverse();
        }
        return sortedData;
    };

    const effectiveData = useMemo(() => {
        if (sort) {
            const descending = sort.sortDirection === 'DESC';
            const column = getColumn(sort.sortBy);
            if (column.comparator) {
                return sortWithComparator(data, column.comparator, descending);
            } else if (column.comparatorBy) {
                return sortByGetter(data, column.comparatorBy, descending);
            }
        }
        return data;
    }, [data, sort, columns]);

    return {
        data: effectiveData,
        sort,
        handleSort: setSort,
    };
};
