import { xor } from 'lodash-es';
import React, { FC } from 'react';

import { OrganizationRefDto } from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import { Color } from '@hofy/theme';
import { Paragraph3, SvgIcon } from '@hofy/ui';

import { SearchableBlockFilter } from '../../design/blockFilters/SearchableBlockFilter';

interface OrganizationBlockFilterProps {
    selected: UUID[];
    organizations: OrganizationRefDto[];
    onChange(ids: UUID[]): void;
    searchQuery: string;
    setSearchQuery(query: string): void;
}

export const OrganizationBlockFilter: FC<OrganizationBlockFilterProps> = ({
    selected,
    organizations,
    onChange,
    searchQuery,
    setSearchQuery,
}) => {
    const selectedOrgs = organizations.filter(org => selected.includes(org.id));

    const allOrgs = Array.from(new Set([...selectedOrgs, ...organizations]));

    const dropdownBottomSlot = (
        <Paragraph3 padding={5} paddingLeft={16} color={Color.ContentTertiary}>
            Search to find other entries
        </Paragraph3>
    );

    return (
        <SearchableBlockFilter
            title='Organization'
            icon={SvgIcon.Building}
            items={allOrgs}
            isSelected={org => selected.some(id => id === org.id)}
            onFilterClick={org => onChange(xor(selected, [org.id]))}
            renderItem={org => org.name}
            searchPlaceholder='Search organization'
            search={searchQuery}
            onSearch={setSearchQuery}
            dropdownBottomSlot={dropdownBottomSlot}
        />
    );
};
