import { xor } from 'lodash-es';
import React, { FC, useState } from 'react';

import { ExportField } from '@hofy/api-admin';
import { Box, Button, LabeledCheckbox, Modal, ModalContent, ModalFooter, ModalHeader } from '@hofy/ui';

import { CancelButton } from '../../../../components/design/button/CancelButton';
import { useTrExportField } from '../../../../store/exportImport/useTrExportField';

interface ImportDataModalProps {
    onClose(): void;
    onSubmit(fields: ExportField[]): void;
    fields: ExportField[];
}

export const ImportDataModal: FC<ImportDataModalProps> = ({ onClose, onSubmit, fields }) => {
    const [selectedFields, setSelectedFields] = useState(fields);
    const trExportField = useTrExportField();

    const handleSubmit = () => {
        onSubmit(selectedFields);
        onClose();
    };

    return (
        <Modal onClose={onClose} width={400}>
            <ModalHeader title='Select the fields to import' />
            <ModalContent>
                <Box column gap={8}>
                    {fields.map(field => (
                        <LabeledCheckbox
                            key={field}
                            label={trExportField(field)}
                            checked={selectedFields.includes(field)}
                            onChange={() => setSelectedFields(xor(selectedFields, [field]))}
                        />
                    ))}
                </Box>
            </ModalContent>
            <ModalFooter>
                <CancelButton onClick={onClose} />
                <Button label='Submit' onClick={handleSubmit} disabled={selectedFields.length === 0} />
            </ModalFooter>
        </Modal>
    );
};
