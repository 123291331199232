import React, { FC } from 'react';

import { Permission, SeatAssignmentStatus } from '@hofy/api-shared';
import { MoreMenu } from '@hofy/common';
import { usePermission } from '@hofy/permission';
import { SvgIcon } from '@hofy/ui';
import { UUID } from '@hofy/global';
import { SeatAssignmentDto } from '@hofy/api-admin';

interface SeatAssignmentActionsProps {
    seat: SeatAssignmentDto;
    handleDeactivateUser(userId: UUID): void;
}

export const SeatAssignmentActions: FC<SeatAssignmentActionsProps> = ({ seat, handleDeactivateUser }) => {
    const { hasPermission } = usePermission();

    return (
        <MoreMenu
            marginLeft={16}
            items={[
                {
                    label: 'Deactivate user',
                    action: () => handleDeactivateUser(seat.user.id),
                    visible:
                        hasPermission(Permission.AdminOrganizationUpdate) &&
                        seat.status === SeatAssignmentStatus.Active,
                    icon: SvgIcon.User,
                },
            ]}
        />
    );
};
