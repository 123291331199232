import { omit } from 'lodash-es';
import React, { FC, ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Color, ThemeFontSize } from '@hofy/theme';

import { isExternalLink } from '../../helpers/String';
import { TestKeyAware } from '../../types';
import { Font, MarginBoxProps, Span } from '../base';
import { Icon, SvgIcon } from '../icon';

export type LinkSize = 'small' | 'medium' | 'large';

interface BaseLinkProps extends MarginBoxProps, TestKeyAware {
    to: string;
    children?: ReactNode;
    invertColor?: boolean;
    onClick?(e: MouseEvent): void;
}

interface InlineLinkProps extends BaseLinkProps {
    inline: true;
}

interface BlockLinkProps extends BaseLinkProps {
    inline?: false;
    leftIcon?: Svg;
    rightIcon?: Svg;
    size?: LinkSize;
}

export type LinkProps = InlineLinkProps | BlockLinkProps;

export const Link: FC<LinkProps> = ({
    to,
    children = to,
    invertColor = false,
    onClick,
    testKey,
    ...rest
}) => {
    const color = invertColor ? Color.InteractionInvertedNormal : Color.InteractionDefaultNormal;

    const external = isExternalLink(to);
    const LinkComponent = external ? ExternalLink : RouterLink;
    const externalIcon = external ? SvgIcon.LinkExternal : undefined;
    const defaultOnClick = (e: MouseEvent) => {
        e.stopPropagation();
    };
    if (rest.inline) {
        const marginProps = omit(rest, ['inline']);
        return (
            <LinkSpan
                as={LinkComponent}
                to={to}
                underline
                color={color}
                invertColor={invertColor}
                onClick={onClick || defaultOnClick}
                data-test-key={testKey}
                {...marginProps}
            >
                {children}
            </LinkSpan>
        );
    }

    const { leftIcon, rightIcon = externalIcon, size = 'medium', ...marginProps } = rest;
    const fontSize = linkSizes[size];

    return (
        <LinkFont
            as={LinkComponent}
            size={fontSize}
            color={color}
            invertColor={invertColor}
            to={to}
            row
            inline
            alignItems='center'
            gap={8}
            onClick={onClick || defaultOnClick}
            data-test-key={testKey}
            {...marginProps}
        >
            {leftIcon && <Icon svg={leftIcon} color='currentColor' size={16} />}
            <Span overflow='hidden' ellipsis wordBreak>
                {children}
            </Span>
            {rightIcon && <Icon svg={rightIcon} color='currentColor' size={16} />}
        </LinkFont>
    );
};

const linkSizes: Record<LinkSize, ThemeFontSize> = {
    small: 'paragraph4',
    medium: 'paragraph3',
    large: 'paragraph2',
};

interface ExternalLinkProps {
    children: ReactNode;
    to: string;
    className?: string;
}

const ExternalLink: FC<ExternalLinkProps> = ({ children, to, ...rest }) => {
    return (
        <a target='_blank' rel='noopener noreferrer' href={to} title={to} {...rest}>
            {children}
        </a>
    );
};

interface FontProps {
    invertColor: boolean;
}

const styles = css<FontProps>`
    &:hover {
        color: ${p => (p.invertColor ? Color.InteractionInvertedHover : Color.InteractionDefaultHover)};
    }
    &:active {
        color: ${p => (p.invertColor ? Color.InteractionInvertedActive : Color.InteractionDefaultActive)};
    }
`;

const LinkFont = styled(Font)<FontProps>`
    ${styles};
    &:hover {
        text-decoration: underline;
    }
`;

const LinkSpan = styled(Span)<FontProps>`
    ${styles};
`;
