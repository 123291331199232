import { useEffect, useState } from 'react';

import { numberPrecision, parseNumber } from '../../../../../helpers/Number';
import { NumberRangeInputProps } from '../types/NumberRangeInputProps';

export const useNumberRangeInput = ({
    value,
    onChange,
    onBlur,
    nullable,
    precision = 0,
    fixedDecimal,
    formatter,
}: NumberRangeInputProps) => {
    const toString = (val: number | null) => {
        if (val === null) {
            return '';
        }
        const num = numberPrecision(val, precision);

        if (formatter) {
            return formatter(num);
        }
        if (fixedDecimal) {
            return num.toFixed(precision);
        }
        return num.toString();
    };

    const [stringFromValue, setStringFromValue] = useState(() => toString(value?.from ?? null));
    const [stringToValue, setStringToValue] = useState(() => toString(value?.to ?? null));

    useEffect(() => {
        if (parseNumber(stringFromValue) !== value?.from) {
            setStringFromValue(toString(value?.from ?? null));
        }
        if (parseNumber(stringToValue) !== value?.to) {
            setStringToValue(toString(value?.to ?? null));
        }
    }, [value]);

    const changeFrom = (newValue: string) => {
        setStringFromValue(newValue);

        const num = parseNumber(newValue);

        if (num === null && nullable) {
            onChange({ from: null, to: value?.to ?? null });
        } else if (num !== null) {
            onChange({ from: numberPrecision(num, precision), to: value?.to! });
        }
    };

    const blurFrom = () => {
        const num = parseNumber(stringFromValue);

        if (num === null && nullable) {
            setStringFromValue('');
            onChange({ from: null, to: value?.to ?? null });
        } else if (num !== null) {
            setStringFromValue(toString(num));
            onChange({ from: numberPrecision(num, precision), to: value?.to! });
        } else {
            setStringFromValue(toString(value?.from ?? null));
        }

        onBlur?.();
    };

    const changeTo = (newValue: string) => {
        setStringToValue(newValue);

        const num = parseNumber(newValue);

        if (num === null && nullable) {
            onChange({ from: value?.from ?? null, to: null });
        } else if (num !== null) {
            onChange({ from: value?.from!, to: numberPrecision(num, precision) });
        }
    };

    const blurTo = () => {
        const num = parseNumber(stringToValue);

        if (num === null && nullable) {
            setStringToValue('');
            onChange({ from: value?.from ?? null, to: null });
        } else if (num !== null) {
            setStringToValue(toString(num));
            onChange({ from: value?.from!, to: numberPrecision(num, precision) });
        } else {
            setStringToValue(toString(value?.to ?? null));
        }

        onBlur?.();
    };

    return {
        stringFromValue,
        setStringFromValue,
        stringToValue,
        setStringToValue,
        changeFrom,
        blurFrom,
        changeTo,
        blurTo,
    };
};
