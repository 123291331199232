import React, { FC, useState } from 'react';

import { useItemQuery } from '@hofy/api-admin';
import { ItemStatus } from '@hofy/api-shared';
import { BlockLoader, Slideout, SlideoutTabbedHeader } from '@hofy/common';
import { UUID } from '@hofy/global';
import { Box, Heading3, Tab, Tabs } from '@hofy/ui';

import { ItemStatusChip } from '../../components/domain/items/ItemStatusChip';
import { allItemSlideoutTabs, ItemSlideoutTab } from '../../store/items/types/ItemSlideoutTab';
import { useTrAdminOrderFilter } from '../../store/items/useTrItemSlideoutTabs';
import { AssignmentsTable } from './AssignmentsTable';
import { ClearanceRequestsTable } from './ClearanceRequestsTable';
import { FixedAssetsTable } from './FixedAssetsTable';
import { InspectionsTable } from './InspectionsTable';
import { ItemBillingTab } from './ItemBillingTab';
import { ItemDetailsMenu } from './ItemDetailsMenu';
import { ItemDetailsTab } from './ItemDetailsTab';
import { RepairsTable } from './RepairsTable';
import { StatusUpdateTable } from './StatusUpdateTable';

interface ItemDetailsSlideoutProps {
    itemId: UUID;
    onCreateRepairForItem(id: UUID): void;
    onOpenInspectionDetails(uuid: UUID): void;
    onClose(): void;
}

export const ItemDetailsSlideout: FC<ItemDetailsSlideoutProps> = ({
    onCreateRepairForItem,
    onOpenInspectionDetails,
    onClose,
    itemId,
}) => {
    const { data: item, isLoading } = useItemQuery(itemId);
    const [activeTab, setActiveTab] = useState(ItemSlideoutTab.Details);
    const trTab = useTrAdminOrderFilter();

    const content = () => {
        if (!item) {
            return null;
        }

        switch (activeTab) {
            case ItemSlideoutTab.Details: {
                return <ItemDetailsTab item={item} />;
            }
            case ItemSlideoutTab.Billing: {
                return <ItemBillingTab item={item} />;
            }
            case ItemSlideoutTab.Assignments: {
                return (
                    <Box overflow='auto' fullHeight borderBottom>
                        <AssignmentsTable
                            withProduct={false}
                            assignments={item.assignments}
                            maxWidth='100%'
                        />
                    </Box>
                );
            }
            case ItemSlideoutTab.Inspections: {
                return (
                    <Box overflow='auto' fullHeight borderBottom>
                        <InspectionsTable
                            inspections={item.inspections}
                            onOpenInspectionDetails={onOpenInspectionDetails}
                        />
                    </Box>
                );
            }
            case ItemSlideoutTab.Repairs: {
                return (
                    <Box overflow='auto' fullHeight borderBottom>
                        <RepairsTable repairs={item.repairs} />
                    </Box>
                );
            }
            case ItemSlideoutTab.ClearanceRequests: {
                return (
                    <Box overflow='auto' fullHeight borderBottom>
                        <ClearanceRequestsTable clearanceRequests={item.clearanceRequests} />
                    </Box>
                );
            }
            case ItemSlideoutTab.StatusHistory: {
                return (
                    <Box overflow='auto' fullHeight borderBottom>
                        <StatusUpdateTable itemStatusUpdate={item.itemStatusUpdates} />
                    </Box>
                );
            }
            case ItemSlideoutTab.FixedAssets: {
                return (
                    <Box overflow='auto' fullHeight borderBottom>
                        <FixedAssetsTable fixedAssets={item.fixedAssets} />
                    </Box>
                );
            }
        }
    };

    return (
        <Slideout width={1200} onClose={onClose}>
            {item && (
                <SlideoutTabbedHeader
                    title={
                        <Box row>
                            <Heading3>{item.product.name}</Heading3>
                            {item.status !== ItemStatus.Active && (
                                <Box marginLeft={12}>
                                    <ItemStatusChip status={item.status} />
                                </Box>
                            )}
                            <ItemDetailsMenu
                                item={item}
                                onCreateRepairForItem={() => onCreateRepairForItem(itemId)}
                            />
                        </Box>
                    }
                    tabsSlot={
                        <Tabs active={activeTab} onChange={tab => setActiveTab(tab)}>
                            {allItemSlideoutTabs.map(tab => (
                                <Tab key={tab} id={tab} label={trTab(tab)} />
                            ))}
                        </Tabs>
                    }
                />
            )}
            {isLoading && <BlockLoader fullHeight />}
            {content()}
        </Slideout>
    );
};
