export const assetsGradeI18n = {
    'asset-grade.new': 'New',
    'asset-grade.a': 'Grade A',
    'asset-grade.b': 'Grade B',
    'asset-grade.c': 'Grade C',
    'asset-grade.d': 'Grade D',
    'asset-grade.f': 'Grade F',

    'asset-grade-label.new': 'New',
    'asset-grade-label.a': 'Excellent condition',
    'asset-grade-label.b': 'Good condition',
    'asset-grade-label.c': 'Worn condition',
    'asset-grade-label.d': 'Damaged condition',
    'asset-grade-label.f': 'Very Damaged/Non-functional',

    'asset-grade-with-condition.new': 'Grade A: Excellent condition',
    'asset-grade-with-condition.a': 'Grade A: Excellent condition',
    'asset-grade-with-condition.b': 'Grade B: Good condition',
    'asset-grade-with-condition.c': 'Grade C: Worn condition',
    'asset-grade-with-condition.d': 'Grade D: Damaged condition',
    'asset-grade-with-condition.f': 'Grade F: Very Damaged/Non-functional',
};
