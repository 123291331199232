import React, { FC, useMemo, useState } from 'react';

import { Slideout, SlideoutFooter, SlideoutHeader } from '@hofy/common';
import { UUID } from '@hofy/global';
import {
    AsyncButton,
    Box,
    Button,
    FormSwitch,
    isRequired,
    RequiredKeys,
    SearchInput,
    SvgIcon,
    useForm,
    validator,
} from '@hofy/ui';

import { ProductAndVariant } from '../../../components/domain/products/productPicker/VariantPicker';
import { useConsumableCostQuery } from '../../../store/assignments/useConsumableCost';
import { useCreateConsumable } from '../../../store/assignments/useCreateConsumable';
import { ServiceCost } from '../components/ServiceCost';
import { VariantPickerSlideoutBody } from './VariantPickerSlideout';

interface CreateConsumableSlideoutFormData {
    productAndVariant: ProductAndVariant | null;
    isBillable: boolean;
}

interface CreateConsumableSlideoutProps {
    assignmentId: UUID;
    onClose(): void;
}

export const CreateConsumableSlideout: FC<CreateConsumableSlideoutProps> = ({ assignmentId, onClose }) => {
    const { createConsumable, isPending, isError } = useCreateConsumable(assignmentId, onClose);

    const [search, setSearch] = useState<string>('');
    const form = useForm<CreateConsumableSlideoutFormData, RequiredKeys<CreateConsumableSlideoutFormData>>({
        initial: {
            productAndVariant: null,
            isBillable: false,
        },
        validate: validator<CreateConsumableSlideoutFormData>({
            productAndVariant: isRequired('Product and variant must be selected'),
        }),
        onSubmit: ({ productAndVariant, isBillable }) => {
            createConsumable({ variantId: productAndVariant.variant.id, isBillable });
        },
    });

    const variantId = form.values.productAndVariant?.variant?.id as UUID;
    const shouldFetchConsumableCost = useMemo(() => {
        return Boolean(assignmentId && variantId && form.values.isBillable);
    }, [assignmentId, variantId, form.values.isBillable]);

    const {
        consumableCost,
        isLoading,
        isError: isConsumableCostError,
    } = useConsumableCostQuery(assignmentId, variantId, shouldFetchConsumableCost);

    return (
        <Slideout width={600} onClose={onClose} slideoutId='create-consumable'>
            <SlideoutHeader title='Send consumable item' justify='space-between' paddingRight={20}>
                <SearchInput value={search} onChange={setSearch} placeholder='Search product, SKU, …' />
            </SlideoutHeader>
            <VariantPickerSlideoutBody
                assignmentId={assignmentId}
                assignmentSectionTitle='Linked assignment'
                variantSectionTitle='Possible consumables'
                onPick={productAndVariant => {
                    form.setValues({ productAndVariant });
                }}
                selected={form.values.productAndVariant}
                productOptionsFromAssignment={assignment => ({
                    organizationIds: [assignment.organization.id],
                    isInternal: true,
                    internalVariantsOnly: true,
                    search,
                })}
            />
            <SlideoutFooter height='unset'>
                <Box column fullWidth marginTop={24} marginBottom={16} gap={24}>
                    <FormSwitch
                        api={form.fields.isBillable}
                        label='Bill on delivery'
                        helperText={
                            form.values.isBillable
                                ? 'An invoice will be automatically created on delivery'
                                : 'The organization will not be billed for this replacement.'
                        }
                    />
                    {form.values.isBillable && variantId && (
                        <ServiceCost
                            isLoading={isLoading}
                            isError={isConsumableCostError}
                            serviceCost={consumableCost}
                        />
                    )}
                    <Box row justify='space-between' fullWidth>
                        <Button
                            type='ghost'
                            negativeMargin
                            onClick={onClose}
                            label='Cancel'
                            leftIcon={SvgIcon.Cross}
                        />
                        <AsyncButton
                            label='Create new assignment'
                            isError={isError}
                            isLoading={isPending}
                            onClick={form.submit}
                            disabled={!form.values.productAndVariant}
                        />
                    </Box>
                </Box>
            </SlideoutFooter>
        </Slideout>
    );
};
