import { difference, some } from 'lodash-es';

import { Country } from './Country';
import { getEnumValues } from './Enum';

export enum RootRegion {
    Core = 'core',
    NonCore = 'non_core',
}

export enum SubRegion {
    UnitedKingdom = 'united_kingdom',
    UnitedStates = 'united_states',
    Eu = 'eu',

    NorthAmerica = 'north_america',
    SouthAmerica = 'south_america',
    CentralAmerica = 'central_america',
    Caribbean = 'caribbean',
    Europe = 'europe',
    Africa = 'africa',
    Asia = 'asia',
    Oceania = 'oceania',
}

export type AnyRegion = RootRegion | SubRegion;

export const allSubRegions = getEnumValues<SubRegion>(SubRegion);
export const allRootRegions = getEnumValues<RootRegion>(RootRegion);

export const allRegions = [
    RootRegion.Core,

    SubRegion.UnitedKingdom,
    SubRegion.UnitedStates,
    SubRegion.Eu,

    RootRegion.NonCore,

    SubRegion.NorthAmerica,
    SubRegion.SouthAmerica,
    SubRegion.CentralAmerica,
    SubRegion.Caribbean,
    SubRegion.Europe,
    SubRegion.Africa,
    SubRegion.Asia,
    SubRegion.Oceania,
];

export interface SubRegionConfig {
    region: SubRegion;
    countries: Country[];
}

export interface RootRegionConfig {
    region: RootRegion;
    subregions: SubRegionConfig[];
}

export const regionHierarchy: RootRegionConfig[] = [
    {
        region: RootRegion.Core,
        subregions: [
            {
                region: SubRegion.UnitedKingdom,
                countries: [Country.UnitedKingdom, Country.Jersey, Country.Guernsey, Country.IsleOfMan],
            },
            {
                region: SubRegion.UnitedStates,
                countries: [Country.UnitedStates],
            },
            {
                region: SubRegion.Eu,
                countries: [
                    Country.Andorra,
                    Country.Austria,
                    Country.Belgium,
                    Country.Bulgaria,
                    Country.Switzerland,
                    Country.CanaryIslands,
                    Country.Cyprus,
                    Country.CzechRepublic,
                    Country.Germany,
                    Country.Denmark,
                    Country.Estonia,
                    Country.Spain,
                    Country.Finland,
                    Country.France,
                    Country.Gibraltar,
                    Country.Greece,
                    Country.Croatia,
                    Country.Hungary,
                    Country.Ireland,
                    Country.Iceland,
                    Country.Italy,
                    Country.Liechtenstein,
                    Country.Lithuania,
                    Country.Luxembourg,
                    Country.Latvia,
                    Country.Monaco,
                    Country.Malta,
                    Country.Netherlands,
                    Country.Norway,
                    Country.Poland,
                    Country.Portugal,
                    Country.Romania,
                    Country.Sweden,
                    Country.Slovenia,
                    Country.Slovakia,
                    Country.SanMarino,
                    Country.HolySee,
                ],
            },
        ],
    },
    {
        region: RootRegion.NonCore,
        subregions: [
            {
                region: SubRegion.NorthAmerica,
                countries: [
                    Country.Canada,
                    Country.Greenland,
                    Country.Mexico,
                    Country.SaintPierreAndMiquelon,
                ],
            },
            {
                region: SubRegion.SouthAmerica,
                countries: [
                    Country.Argentina,
                    Country.Bolivia,
                    Country.Brazil,
                    Country.Chile,
                    Country.Colombia,
                    Country.Ecuador,
                    Country.FalklandIslands,
                    Country.FrenchGuiana,
                    Country.Guyana,
                    Country.Peru,
                    Country.Paraguay,
                    Country.Suriname,
                    Country.Uruguay,
                    Country.Venezuela,
                ],
            },
            {
                region: SubRegion.Europe,
                countries: [
                    Country.Albania,
                    Country.AlandIslands,
                    Country.BosniaAndHerzegovina,
                    Country.Belarus,
                    Country.FaroeIslands,
                    Country.Kosovo,
                    Country.Moldova,
                    Country.Montenegro,
                    Country.NorthMacedonia,
                    Country.Serbia,
                    Country.Reunion,
                    Country.Russia,
                    Country.Turkey,
                    Country.Ukraine,
                ],
            },
            {
                region: SubRegion.Africa,
                countries: [
                    Country.Angola,
                    Country.BurkinaFaso,
                    Country.Burundi,
                    Country.Benin,
                    Country.Botswana,
                    Country.DemocraticRepublicOfTheCongo,
                    Country.CentralAfricanRepublic,
                    Country.RepublicOfTheCongo,
                    Country.CoteDivoire,
                    Country.Cameroon,
                    Country.CaboVerde,
                    Country.Djibouti,
                    Country.Algeria,
                    Country.Egypt,
                    Country.WesternSahara,
                    Country.Eritrea,
                    Country.Ethiopia,
                    Country.Gabon,
                    Country.Ghana,
                    Country.Gambia,
                    Country.Guinea,
                    Country.EquatorialGuinea,
                    Country.GuineaBissau,
                    Country.Kenya,
                    Country.Comoros,
                    Country.Liberia,
                    Country.Lesotho,
                    Country.Libya,
                    Country.Morocco,
                    Country.Madagascar,
                    Country.Mali,
                    Country.Mauritania,
                    Country.Mauritius,
                    Country.Malawi,
                    Country.Mozambique,
                    Country.Namibia,
                    Country.Niger,
                    Country.Nigeria,
                    Country.Rwanda,
                    Country.Seychelles,
                    Country.Sudan,
                    Country.SaintHelenaAscensionAndTristanDaCunha,
                    Country.SierraLeone,
                    Country.Senegal,
                    Country.Somalia,
                    Country.SouthSudan,
                    Country.SaoTomeAndPrincipe,
                    Country.Swaziland,
                    Country.Chad,
                    Country.Togo,
                    Country.Tunisia,
                    Country.Tanzania,
                    Country.Uganda,
                    Country.Mayotte,
                    Country.SouthAfrica,
                    Country.Zambia,
                    Country.Zimbabwe,
                ],
            },
            {
                region: SubRegion.Asia,
                countries: [
                    Country.UnitedArabEmirates,
                    Country.Afghanistan,
                    Country.Armenia,
                    Country.Azerbaijan,
                    Country.Bangladesh,
                    Country.Bahrain,
                    Country.BruneiDarussalam,
                    Country.Bhutan,
                    Country.China,
                    Country.Georgia,
                    Country.HongKong,
                    Country.Indonesia,
                    Country.Israel,
                    Country.India,
                    Country.Iraq,
                    Country.Iran,
                    Country.Jordan,
                    Country.Japan,
                    Country.Kyrgyzstan,
                    Country.Cambodia,
                    Country.NorthKorea,
                    Country.SouthKorea,
                    Country.Kuwait,
                    Country.Kazakhstan,
                    Country.Laos,
                    Country.Lebanon,
                    Country.SriLanka,
                    Country.Myanmar,
                    Country.Mongolia,
                    Country.Macau,
                    Country.Maldives,
                    Country.Malaysia,
                    Country.Nepal,
                    Country.Oman,
                    Country.Philippines,
                    Country.Pakistan,
                    Country.StateOfPalestine,
                    Country.Qatar,
                    Country.SaudiArabia,
                    Country.Singapore,
                    Country.SyrianArabRepublic,
                    Country.Thailand,
                    Country.Tajikistan,
                    Country.TimorLeste,
                    Country.Turkmenistan,
                    Country.Taiwan,
                    Country.Uzbekistan,
                    Country.Vietnam,
                    Country.Yemen,
                ],
            },
            {
                region: SubRegion.Oceania,
                countries: [
                    Country.AmericanSamoa,
                    Country.Australia,
                    Country.CookIslands,
                    Country.Fiji,
                    Country.FederatedStatesOfMicronesia,
                    Country.Guam,
                    Country.Kiribati,
                    Country.MarshallIslands,
                    Country.NorthernMarianaIslands,
                    Country.NewCaledonia,
                    Country.NorfolkIsland,
                    Country.Nauru,
                    Country.Niue,
                    Country.NewZealand,
                    Country.FrenchPolynesia,
                    Country.PapuaNewGuinea,
                    Country.Palau,
                    Country.SolomonIslands,
                    Country.Tokelau,
                    Country.Tonga,
                    Country.Tuvalu,
                    Country.Vanuatu,
                    Country.WallisAndFutuna,
                    Country.Samoa,
                ],
            },
            {
                region: SubRegion.Caribbean,
                countries: [
                    Country.AntiguaAndBarbuda,
                    Country.Anguilla,
                    Country.Aruba,
                    Country.Barbados,
                    Country.SaintBarthelemy,
                    Country.Bermuda,
                    Country.Bonaire,
                    Country.Bahamas,
                    Country.Cuba,
                    Country.Curacao,
                    Country.Dominica,
                    Country.DominicanRepublic,
                    Country.Grenada,
                    Country.Guadeloupe,
                    Country.Haiti,
                    Country.Jamaica,
                    Country.SaintKittsAndNevis,
                    Country.CaymanIslands,
                    Country.SaintLucia,
                    Country.SaintMartin,
                    Country.Martinique,
                    Country.Montserrat,
                    Country.PuertoRico,
                    Country.SintMaarten,
                    Country.TurksAndCaicosIslands,
                    Country.TrinidadAndTobago,
                    Country.SaintVincentAndTheGrenadines,
                    Country.VirginIslandsBritish,
                    Country.VirginIslandsUs,
                ],
            },
            {
                region: SubRegion.CentralAmerica,
                countries: [
                    Country.Belize,
                    Country.CostaRica,
                    Country.Guatemala,
                    Country.Honduras,
                    Country.Nicaragua,
                    Country.Panama,
                    Country.ElSalvador,
                ],
            },
        ],
    },
];

export const regionCountries = ((): Record<SubRegion | RootRegion, Country[]> => {
    const result: Record<SubRegion | RootRegion, Country[]> = {
        [SubRegion.UnitedKingdom]: [],
        [SubRegion.UnitedStates]: [],
        [SubRegion.Eu]: [],
        [SubRegion.NorthAmerica]: [],
        [SubRegion.SouthAmerica]: [],
        [SubRegion.Caribbean]: [],
        [SubRegion.CentralAmerica]: [],
        [SubRegion.Europe]: [],
        [SubRegion.Africa]: [],
        [SubRegion.Asia]: [],
        [SubRegion.Oceania]: [],
        [RootRegion.Core]: [],
        [RootRegion.NonCore]: [],
    };
    regionHierarchy.forEach(topRegion => {
        topRegion.subregions.forEach(subRegion => {
            result[subRegion.region] = subRegion.countries;
            result[topRegion.region] = [...result[topRegion.region], ...subRegion.countries];
        });
    });
    return result;
})();

const rootRegionHierarchy = ((): Record<RootRegion, SubRegion[]> => {
    const result: Record<RootRegion, SubRegion[]> = {
        [RootRegion.Core]: [],
        [RootRegion.NonCore]: [],
    };
    regionHierarchy.forEach(topRegion => {
        topRegion.subregions.forEach(subRegion => {
            result[topRegion.region] = [...result[topRegion.region], subRegion.region];
        });
    });
    return result;
})();

export const countriesToFullRegions = (countries: Country[]) => {
    const subRegions = allSubRegions.filter(r => difference(regionCountries[r], countries).length === 0);
    const rootRegions = allRootRegions.filter(r => difference(regionCountries[r], countries).length === 0);
    const subRegionsWithoutRoot = difference(
        subRegions,
        rootRegions.flatMap(r => rootRegionHierarchy[r]),
    );
    return [...rootRegions, ...subRegionsWithoutRoot];
};

export const removeRegionsFromCountries = (countries: Country[], regions: AnyRegion[]) => {
    let currentCountries = [...countries];
    regions.forEach(r => {
        currentCountries = difference(currentCountries, regionCountries[r]);
    });
    return currentCountries;
};

export const regionToCountries = (region: AnyRegion): Country[] => {
    return allRootRegions.includes(region as RootRegion)
        ? regionCountries[region as RootRegion]
        : regionCountries[region as SubRegion];
};

export const someBelongToRegion = (region: AnyRegion, countries: Country[]) => {
    return some(regionToCountries(region), r => countries.includes(r));
};

export const redistributableTo = (
    countryOfOrigin?: Country | null,
    countryOfDestination?: Country | null,
): boolean => {
    if (!countryOfOrigin || !countryOfDestination) {
        return false;
    }
    if (
        someBelongToRegion(SubRegion.Eu, [countryOfOrigin]) &&
        someBelongToRegion(SubRegion.Eu, [countryOfDestination])
    ) {
        return true;
    }
    if (
        someBelongToRegion(SubRegion.UnitedKingdom, [countryOfOrigin]) &&
        someBelongToRegion(SubRegion.UnitedKingdom, [countryOfDestination])
    ) {
        return true;
    }
    return countryOfOrigin === countryOfDestination;
};

export const countryToStoreAndReuseRegion = (country: Country): SubRegion | null => {
    switch (true) {
        case someBelongToRegion(SubRegion.Eu, [country]):
            return SubRegion.Eu;
        case someBelongToRegion(SubRegion.UnitedKingdom, [country]):
            return SubRegion.UnitedKingdom;
        default:
            return null;
    }
};

export const isPurchaseTaxReclaimableCountry = (country: Country): boolean => {
    if (country === Country.Netherlands) {
        return true;
    }

    if (regionCountries[SubRegion.UnitedKingdom].includes(country)) {
        return true;
    }

    return false;
};
