export enum ActivityLogType {
    ItemCreatedWithUser = 'item_created_with_user',
    ActivityLogTypeItemCreatedWithUserAsReplacement = 'item_created_with_user_as_replacement',
    ActivityLogTypeItemCreatedWithUserAsLoaner = 'item_created_with_user_as_loaner',
    ItemCreatedWithUserFromDropshippedOrder = 'item_created_with_user_from_dropshipped_order',
    ItemCreatedAtHofyWarehouse = 'item_created_at_hofy_warehouse',
    ItemCreatedAtOrganizationWarehouse = 'item_created_at_organization_warehouse',
    ItemPendingDelivery = 'item_pending_delivery',
    ItemPendingDeliveryCancelled = 'item_pending_delivery_cancelled',
    ItemPendingCollection = 'item_pending_collection',
    ItemPendingCollectionCancelled = 'item_pending_collection_cancelled',
    ItemPendingTransfer = 'item_pending_transfer',
    ItemPendingTransferCancelled = 'item_pending_transfer_cancelled',
    ItemCollectedFromUser = 'item_collected_from_user',
    ItemCollectedFromUserCancelled = 'item_collected_from_user_cancelled',
    ItemTransferredBetweenUsers = 'item_transferred_between_users',
    ItemArchived = 'item_archived',
    ItemCollectedToHofyWarehouse = 'item_collected_to_hofy_warehouse',
    ItemCollectedToOrganizationWarehouse = 'item_collected_to_organization_warehouse',
    ItemDeliveredFromHofyWarehouse = 'item_delivered_from_hofy_warehouse',
    ItemDeliveredFromOrganizationWarehouse = 'item_delivered_from_organization_warehouse',
    ItemSelfServeDeliveredFromOrganizationWarehouse = 'item_self_serve_delivered_from_organization_warehouse',
    ItemSelfServeDeliveredToUser = 'item_self_serve_delivered_to_user',
    ItemSelfServeCollectedToOrganizationWarehouse = 'item_self_serve_collected_to_organization_warehouse',
    ItemPendingRepair = 'item_pending_repair',
    ItemRepaired = 'item_repaired',
    ItemReplaced = 'item_replaced',
    ItemRepairCancelled = 'item_repair_cancelled',
    ItemInspected = 'item_inspected',
    ItemCollectionRequested = 'item_collection_requested',
    ItemCollectionRequestEmailSent = 'item_collection_request_email_sent',
    ItemCollectionRequestEmailReminderSent = 'item_collection_request_email_reminder_sent',
    ItemCollectionRequestSmsReminderSent = 'item_collection_request_sms_reminder_sent',
    ItemCollectionRequestEscalated = 'item_collection_request_escalated',
    ItemCollectionRequestCancelled = 'item_collection_request_cancelled',
    ItemCollectionRequestCompleted = 'item_collection_request_completed',
    ItemCollectionBooked = 'item_collection_booked',
    ItemCollectionBookedCancelled = 'item_collection_booked_cancelled',
}
