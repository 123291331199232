import { omit } from 'lodash-es';

import { AdminItemEventsFilters } from '@hofy/api-admin';
import { ItemEventStatus } from '@hofy/api-shared';
import { countObjectValues } from '@hofy/helpers';
import { useStructMemo } from '@hofy/hooks';
import { useArrayQueryParam, useDateRangeQueryParam, useStringQueryParam } from '@hofy/router';

export const useItemEventsFilters = () => {
    const [search, setSearch] = useStringQueryParam<string>('search', '');

    const [statuses, setStatuses] = useArrayQueryParam<ItemEventStatus>('status', []);
    const [eventDate, setEventDate] = useDateRangeQueryParam('eventDate');

    const filters = useStructMemo<AdminItemEventsFilters>({
        statuses,
        search,
        eventDate,
    });

    const filtersToCount = omit(filters, 'search');
    const filterCount = countObjectValues(filtersToCount);

    return {
        search,
        filters,
        filterCount,
        setStatuses,
        setSearch,
        setEventDate,
    };
};
