import { keyBy } from 'lodash-es';
import React, { forwardRef, useMemo, useState } from 'react';

import { useUserRefsQuery } from '@hofy/api-admin';
import { formatUserName, UserWithCountryRefDto } from '@hofy/api-shared';
import { Country, UUID } from '@hofy/global';
import {
    LabeledSelectSearch,
    LabeledSelectSearchNullableStringProps,
    LabeledSelectSearchOnlyStringProps,
    Paragraph3,
} from '@hofy/ui';
import { UserSelectorItem } from '@hofy/ui-domain';

interface BaseProps {
    includeOffboarded?: boolean;
    organizationId?: UUID;
    placeholder?: string;
    redistributableIn?: Country;
}

type LabeledOrganizationNormalProps = Omit<
    LabeledSelectSearchOnlyStringProps<UUID>,
    'isError' | 'toText' | 'options'
> &
    BaseProps;
type LabeledOrganizationNullableProps = Omit<
    LabeledSelectSearchNullableStringProps<UUID>,
    'isError' | 'toText' | 'options'
> &
    BaseProps;

export type LabeledUserProps = LabeledOrganizationNormalProps | LabeledOrganizationNullableProps;

const optionsLengthToRender = 30;

export const LabeledUserSelect = forwardRef<HTMLDivElement, LabeledUserProps>(
    ({ includeOffboarded, organizationId, redistributableIn, value, onChange, ...rest }, ref) => {
        const [search, setSearch] = useState('');
        const {
            data: users,
            isLoading: isLoadingUsers,
            isPending,
        } = useUserRefsQuery({
            includeId: value ?? undefined,
            includeOffboarded,
            organizationId,
            redistributableIn,
            search,
        });
        const usersMap = useMemo(() => keyBy(users, v => v.id), [users]);

        const dropdownBottomSlot = (
            <Paragraph3 padding={5} paddingLeft={16}>
                Search to find other entries
            </Paragraph3>
        );

        return (
            <LabeledSelectSearch
                placeholder='Select user'
                toText={id => (displayUser(id, usersMap, isPending) ? formatUserName(usersMap[id]) : '')}
                toLabel={id =>
                    displayUser(id, usersMap, isPending) ? <UserSelectorItem user={usersMap[id]} /> : ''
                }
                isLoadingSearch={isLoadingUsers}
                options={users.map(({ id }) => id)}
                searchPlaceholder='Search to filter'
                onSearchChange={setSearch}
                optionsLengthToRender={optionsLengthToRender}
                dropdownBottomSlot={users.length >= optionsLengthToRender ? dropdownBottomSlot : null}
                ref={ref}
                value={value}
                onChange={(newId: UUID) => {
                    setSearch('');
                    onChange(newId);
                }}
                {...rest}
            />
        );
    },
);

const displayUser = (
    id: UUID,
    usersMap: Record<UUID, UserWithCountryRefDto>,
    isPending: boolean,
): boolean => {
    if (isPending) {
        return false;
    }
    return id in usersMap;
};
