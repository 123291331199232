import { findKey } from 'lodash-es';
import React, { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { usePermission } from '@hofy/permission';

import { AdminNavLink } from '../../components/routing/AdminNavLink';
import { InventoryTab, inventoryTabPermissions } from '../../store/inventory/types/InventoryTab';
import { BYODOrdersRouter } from './byodOrders/BYODOrdersRouter';
import { PurchaseOrdersRouter } from './purchaseOrders/PurchaseOrdersRouter';
import { StockLevelsRouter } from './stockLevels/StockLevelsRouter';
import { SuppliersRouter } from './suppliers/SuppliersRouter';

export const InventoryRouter: FC = () => {
    const { hasPermission } = usePermission();

    const firstTab = findKey(inventoryTabPermissions, permission => {
        return hasPermission(permission) ? permission[0] : InventoryTab.Suppliers;
    });

    return (
        <Switch>
            <Route
                path={`${AdminNavLink.Inventory}/${InventoryTab.Suppliers}`}
                render={() => <SuppliersRouter />}
            />
            <Route
                path={`${AdminNavLink.Inventory}/${InventoryTab.PurchaseOrders}`}
                render={() => <PurchaseOrdersRouter />}
            />
            <Route
                path={`${AdminNavLink.Inventory}/${InventoryTab.StockLevels}`}
                render={() => <StockLevelsRouter />}
            />
            <Route
                path={`${AdminNavLink.Inventory}/${InventoryTab.BYODOrders}`}
                render={() => <BYODOrdersRouter />}
            />
            <Redirect to={`${AdminNavLink.Inventory}/${firstTab}`} />
        </Switch>
    );
};
