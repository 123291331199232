import React, { FC } from 'react';

import { VariantDetailsDto } from '@hofy/api-admin';
import { ProductCategory, useTrOperatingSystem } from '@hofy/api-shared';
import { CopiableText } from '@hofy/common';
import { FormContainer, FormGridRow, LabeledText, useToast } from '@hofy/ui';

import { ProductOverview } from './ProductOverview';

interface ProductDetailsProps {
    variant: VariantDetailsDto;
    product: {
        name: string;
        brand: string;
        category: ProductCategory;
        isRefurbished: boolean;
    };
    uuid?: string;
}

export const ProductDetails: FC<ProductDetailsProps> = ({ product, variant, uuid }) => {
    const trOperatingSystem = useTrOperatingSystem();

    const { showToast } = useToast();

    const onCopyGUID: () => void = () => {
        showToast({
            type: 'positive',
            message: 'GUID copied successfully',
        });
    };
    return (
        <>
            <ProductOverview images={variant.image} product={product} showCategory />
            <FormContainer flex={1} marginTop={20}>
                <FormGridRow columns={variant.os ? 4 : 2}>
                    <LabeledText label='Style' content={variant.style} />
                    <LabeledText label='Size' content={variant.size} />
                    {variant.os && <LabeledText label='OS' content={trOperatingSystem(variant.os)} />}
                </FormGridRow>
                <FormGridRow columns={uuid ? 4 : 2}>
                    <LabeledText label='MPN' content={variant.manufacturerPartCode} />
                    <LabeledText label='SKU' content={variant.sku} />
                    {uuid && (
                        <LabeledText
                            label='UUID'
                            content={<CopiableText text={uuid} onCopy={onCopyGUID} />}
                        />
                    )}
                </FormGridRow>
            </FormContainer>
        </>
    );
};
