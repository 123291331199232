import { OrganizationContractTerm, OrganizationContractType } from '@hofy/api-shared';
import { DateString, UUID } from '@hofy/global';
import { nowISODate } from '@hofy/helpers';

export interface CreateOrganizationContractPayload {
    organizationId: UUID | null;
    paidSeats: number;
    startOn: DateString;
    term: OrganizationContractTerm;
    type: OrganizationContractType;
}

export const emptyCreateOrganizationContractPayload: CreateOrganizationContractPayload = {
    organizationId: null,
    startOn: nowISODate(),
    paidSeats: 0,
    term: OrganizationContractTerm.Months12,
    type: OrganizationContractType.ItSeat,
};
