import {
    first,
    isArray,
    isBoolean,
    isEqual,
    isNil,
    isObject,
    isString,
    isUndefined,
    mapValues,
    omitBy,
} from 'lodash-es';
import { createRef, ElementRef, RefObject, useEffect, useMemo, useReducer, useRef, useState } from 'react';

import { useObject } from '@hofy/hooks';

import { WriteableRefObject } from '../../types';
import {
    ErrorItem,
    FormErrors,
    FormFieldApi,
    FormFieldRecord,
    StateReducer,
    TouchedFields,
    UseForm,
    UseFormOptions,
} from './formTypes';
import { useFormDiscardConfirmation } from './useFormDiscardConfirmation';

/**
 * @example ```ts
   interface Initial { email: string | null; password: string; }
   interface Valid extends Initial { email: string; }

   const form = useForm<Initial, Valid>({
       initial: {
           email: null,
           password: '',
       },
       validate: ({ name, email }) => ({
           email: !email ? 'Email is required' : undefined,
       }),
       onSubmit: (values) => {
           console.log(values.name);
       },
   });
   ```
 */
export const useForm = <T extends object, V extends T = T, E extends {} = FormErrors<T>>({
    initial,
    initialDeps,
    validate,
    validateDeps = [],
    onSubmit,
    onInvalid,
    onDiscard,
    immediatelyShowErrors = false,
    mapper,
}: UseFormOptions<T, V, E>): UseForm<T, E> => {
    const keys = Object.keys(initial) as (keyof T)[];

    const getErrors = (values: T): E => {
        const newErrors = validate ? validate(values) : {};
        return omitBy(newErrors, isUndefined) as E;
    };

    const [{ values, errors }, setValues] = useReducer<StateReducer<T, E>>(
        (state, update) => {
            const newValues = { ...state.values, ...update };
            const newErrors = getErrors(newValues);
            return {
                values: mapper ? mapper(newValues) : newValues,
                errors: newErrors,
            };
        },
        {
            values: initial,
            errors: getErrors(initial),
        },
    );

    useEffect(() => {
        if (initialDeps) {
            setValues(initial);
        }
    }, initialDeps);

    useEffect(() => {
        setValues({}); // Force validation update when deps change
    }, validateDeps);

    const [showAllErrors, setShowAllErrors] = useState(immediatelyShowErrors);
    const [touched, setTouched] = useObject<TouchedFields<T>>({});

    const shouldShowErrorFor = (key: keyof T, subKey?: string): boolean => {
        if (showAllErrors) {
            return true;
        }

        const fieldTouched = touched[key];

        if (subKey && Array.isArray(fieldTouched)) {
            return fieldTouched.includes(subKey);
        }

        return !!fieldTouched;
    };

    const recursiveIsValid = (error: unknown): boolean => {
        if (isString(error)) {
            return false;
        }
        if (isArray(error) && !error.every(recursiveIsValid)) {
            return false;
        }
        if (isObject(error) && !Object.values(error).every(recursiveIsValid)) {
            return false;
        }
        if (isBoolean(error) && error) {
            return false;
        }
        return true;
    };

    const errorsToDisplay = omitBy(
        mapValues(errors, (values, key) => (shouldShowErrorFor(key as keyof T) ? values : undefined)),
        isUndefined,
    ) as E;

    const isValid = useMemo(() => {
        return Object.values(errors).every(recursiveIsValid);
    }, [errors]);

    const getFieldApiFor = <K extends keyof T>(key: K, ref: RefObject<any>): FormFieldApi<T[K]> => {
        const error = errors[key as unknown as keyof E] as ErrorItem;
        const fieldTouched = touched[key];

        return {
            value: values[key],
            setValue: (value: T[keyof T]) => {
                setValues({ [key]: value } as Partial<T>);
            },
            touched: fieldTouched ?? false,
            setTouched: (was: boolean, subKey?: string) => {
                if (subKey) {
                    const current = Array.isArray(fieldTouched) ? fieldTouched : [];
                    if (!current.includes(subKey)) {
                        setTouched({ [key]: [...current, subKey] } as TouchedFields<T>);
                    }
                } else {
                    setTouched({ [key]: was } as TouchedFields<T>);
                }
            },
            error,
            errorMessage: isString(error) && shouldShowErrorFor(key) ? error : undefined,
            shouldShowError: (subKey?: string) => shouldShowErrorFor(key, subKey),
            ref,
        };
    };

    type FieldRefs = Record<keyof T, WriteableRefObject<any>>;
    type ErrorRefs = Record<keyof E, WriteableRefObject<any>>;

    const refs = useRef<FieldRefs & ErrorRefs>(
        Object.fromEntries(keys.map(key => [key, createRef()])) as any,
    );

    const customRef = (name: keyof ErrorRefs) => (el: ElementRef<any>) => {
        if (!refs.current[name]?.current) {
            refs.current[name] = createRef() as any;
        }
        refs.current[name].current = el;
    };

    const fields = useMemo(
        () =>
            Object.fromEntries(
                keys.map(key => [key, getFieldApiFor(key, refs.current[key])]),
            ) as FormFieldRecord<T>,
        [values, errors, touched, showAllErrors],
    );

    const showErrors = (show = true) => {
        setShowAllErrors(show);
    };

    const focusIncorrectField = () => {
        const firstErrorKey = first(
            Object.entries(errors)
                .filter(([, errorMessage]) => !isNil(errorMessage))
                .map(([key]) => key),
        );

        if (!firstErrorKey) {
            return;
        }

        const fieldRef = refs.current[firstErrorKey as keyof T]?.current;

        fieldRef?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        });

        fieldRef?.focus({
            preventScroll: true,
        });
    };

    const submit = () => {
        if (isValid) {
            showErrors(false);
            onSubmit?.(values as V);
        } else {
            showErrors(true);
            onInvalid?.(errors);
            focusIncorrectField();
        }
    };

    const reset = () => {
        setValues(initial);
        showErrors(immediatelyShowErrors);
        setTouched({});
    };

    const discard = () => {
        onDiscard?.();
    };

    const confirmDiscard = useFormDiscardConfirmation(discard);

    const hasChanges = !isEqual(values, initial);

    const confirmDiscardIfChanged = () => {
        if (hasChanges) {
            confirmDiscard();
        } else {
            discard();
        }
    };

    return {
        values,
        setValues: update => {
            setValues(typeof update === 'function' ? update(values) : update);
        },
        setTouched,
        hasChanges,
        currentErrors: errors,
        errors: errorsToDisplay,
        isValid,
        fields,
        refs,
        customRef,
        submit,
        reset,
        discard: confirmDiscardIfChanged,
        showErrors,
        focusIncorrectField,
    };
};
