import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
    BYODOrderItemPayload,
    BYODOrderPayload,
    byodOrdersCacheKey,
    byodOrderService,
} from '@hofy/api-admin';
import {
    isBlank,
    isPricePositive,
    isRequired,
    isRequiredIf,
    isValidHttpsURL,
    useForm,
    useFormArrayField,
    useMultipartForm,
    useToast,
    validateArrayField,
    validator,
} from '@hofy/ui';

import {
    BYODMultipartForm,
    BYODMultipartValidForm,
    BYODOnlyItemsFormData,
    BYODOnlyItemsFormValidData,
    BYODOnlyOrderFormData,
    BYODOnlyOrderFormValidData,
    emptyBYODOrderFormData,
    emptyBYODOrderItemFormData,
    WizardSteps,
} from './types/BYODOrderFormData';

export const useCreateBYODOrder = (onClose: () => void) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: byodOrderService.createBYODOrder,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [byodOrdersCacheKey] });
            showToast({
                type: 'positive',
                message: 'BYOD order created',
            });
            onClose();
        },
    });

    const orderForm = useForm<BYODOnlyOrderFormData, BYODOnlyOrderFormValidData>({
        initial: emptyBYODOrderFormData,
        validate: validator<BYODOrderPayload>({
            orderReference: isRequired('Order reference is required'),
            courierReference: isRequiredIf(
                values => !isBlank(values.trackingLink),
                'Courier reference is required when tracking link is provided',
            ),
            trackingLink: [
                isValidHttpsURL('Invalid tracking link'),
                isRequiredIf(
                    values => !isBlank(values.courierReference),
                    'Tracking link is required when courier reference is provided',
                ),
            ],
            organizationId: isRequired('Organization is required'),
            fromCountry: isRequired('From country is required'),
            toWarehouseId: isRequired('Warehouse is required'),
            estimatedReceiveOn: isRequired('Estimated delivery date is required'),
        }),
    });
    const itemsForm = useForm<BYODOnlyItemsFormData, BYODOnlyItemsFormValidData>({
        initial: { items: [] },
        validate: validator<BYODOrderPayload>({
            items: validateArrayField<BYODOrderPayload, 'items'>({
                selfRules: isRequired('At least one product is required'),
                fieldsValidator: validator<BYODOrderItemPayload>({
                    variantId: isRequired('SKU is required'),
                    expectedQuantity: isRequired('Quantity is required'),
                    unitPurchasePrice: [
                        isRequired('Price is required'),
                        isPricePositive('Price must be greater than 0'),
                    ],
                }),
            }),
        }),
        validateDeps: [orderForm.values.organizationId],
    });

    const items = useFormArrayField(itemsForm.fields.items, emptyBYODOrderItemFormData());
    return {
        form: useMultipartForm<BYODMultipartForm, BYODMultipartValidForm>(
            { [WizardSteps.Order]: orderForm, [WizardSteps.Items]: itemsForm },
            {
                onSubmit: values => {
                    mutation.mutate({
                        ...values.order,
                        ...values.items,
                    });
                },
            },
        ),
        items,
        isSubmitting: mutation.isPending,
        onDiscard: onClose,
    };
};
