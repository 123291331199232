// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/denim-regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/denim-regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./fonts/denim-medium.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./fonts/denim-medium.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'Denim';
  font-style: normal;
  font-weight: normal;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('woff'), url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('woff2');
}

@font-face {
  font-family: 'Denim';
  font-style: normal;
  font-weight: bold;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('woff'), url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('woff2');
}
`, "",{"version":3,"sources":["webpack://./../packages/ui/src/theme/assets/font.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,kBAAkB;EAClB,mBAAmB;EACnB,oHAAqG;AACvG;;AAEA;EACE,oBAAoB;EACpB,kBAAkB;EAClB,iBAAiB;EACjB,oHAAmG;AACrG","sourcesContent":["@font-face {\n  font-family: 'Denim';\n  font-style: normal;\n  font-weight: normal;\n  src: url(./fonts/denim-regular.woff) format('woff'), url(./fonts/denim-regular.woff2) format('woff2');\n}\n\n@font-face {\n  font-family: 'Denim';\n  font-style: normal;\n  font-weight: bold;\n  src: url(./fonts/denim-medium.woff) format('woff'), url(./fonts/denim-medium.woff2) format('woff2');\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
