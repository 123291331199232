import { some } from 'lodash-es';

import { PurchaseOrderItemRefDto } from '@hofy/api-admin';
import {
    isShipmentFromSupplierToUser,
    ShipmentLocationAware,
    ShipmentStatus,
    VariantDetailsDto,
} from '@hofy/api-shared';

interface AssignmentVariantAware {
    variant: VariantDetailsDto;
}

interface PurchaseOrderApprovalAware {
    requiredApprovals: number;
    approvalsCount: number;
    purchaseOrderItems: PurchaseOrderItemRefDto[];
}

type ShipmentPurchaseOrderAware = ShipmentLocationAware & {
    status: ShipmentStatus;
    purchaseOrder: PurchaseOrderApprovalAware | null;
    assignments: AssignmentVariantAware[];
};

export const useValidateShipmentPurchaseOrder = (shipment: ShipmentPurchaseOrderAware) => {
    const [isValid, invalidReason] = isShipmentPurchaseOrderValid(shipment);
    return {
        isValid,
        invalidReason,
    };
};

const isShipmentPurchaseOrderValid = (shipment: ShipmentPurchaseOrderAware): [boolean, string] => {
    if (!isShipmentFromSupplierToUser(shipment)) {
        return [true, ''];
    }
    if (!shipment.purchaseOrder) {
        if (shipment.status !== ShipmentStatus.Booked) {
            return [true, ''];
        }
        return [false, 'Purchase order is required'];
    }
    if (shipmentPurchaseOrderUnmatchedItems(shipment)) {
        return [false, 'Shipment contains items that are not part of purchase order'];
    }
    if (shipment.purchaseOrder.requiredApprovals === 0) {
        return [true, ''];
    }
    return [
        shipment.purchaseOrder.approvalsCount === shipment.purchaseOrder.requiredApprovals,
        'Purchase order requires approval',
    ];
};

export const shipmentPurchaseOrderUnmatchedItems = (shipment: ShipmentPurchaseOrderAware) => {
    return some(shipment.assignments, assignment => {
        const match = shipment.purchaseOrder?.purchaseOrderItems.find(
            item => item.variantId === assignment.variant.id,
        );
        return !match;
    });
};
