import React, { FC, memo } from 'react';

import { SubscriptionDto, useTrSubscriptionTerm } from '@hofy/api-admin';
import { useTrPaymentSchema } from '@hofy/api-shared';
import { formatDate } from '@hofy/helpers';
import { usePrice } from '@hofy/hooks';
import { BaseTable, Placeholder, SvgIllustration } from '@hofy/ui';

interface SubscriptionTableComponentProps {
    subscriptions: SubscriptionDto[];
}

const SubscriptionTableComponent: FC<SubscriptionTableComponentProps> = ({ subscriptions }) => {
    const { formatPrice } = usePrice();
    const trPaymentSchema = useTrPaymentSchema();
    const trTerm = useTrSubscriptionTerm();

    return (
        <BaseTable
            data={subscriptions}
            toKey={v => v.id}
            hoverable
            emptyContent={<Placeholder illustration={SvgIllustration.List} title='No subscriptions' />}
            columns={[
                {
                    id: 'billingFrequency',
                    header: 'Billing frequency',
                    width: 300,
                    renderer: s => trPaymentSchema(s.billingFrequency),
                },
                {
                    id: 'term',
                    header: 'Term',
                    width: 200,
                    renderer: s => trTerm(s.term),
                },
                {
                    id: 'price',
                    header: 'Monthly price',
                    width: 200,
                    renderer: s => formatPrice(s.price),
                },
                {
                    id: 'startOn',
                    header: 'Start on',
                    width: 200,
                    renderer: s => formatDate(s.startOn),
                },
                {
                    id: 'endOn',
                    header: 'End on',
                    width: 200,
                    renderer: s => formatDate(s.endOn),
                },
            ]}
        />
    );
};

export const SubscriptionTable = memo(SubscriptionTableComponent);
