export const uploadUsersI18nErrorCodes = {
    'upload-users-errors.invalid-file-for-users-upload':
        'Filetype is not recognised. Please ensure the file is in .xlsx format',
    'upload-users-errors.invalid-sheets-count-for-users-upload':
        'Please ensure the file has at least one sheet',
    'upload-users-errors.invalid-header-for-users-upload':
        'Invalid headers. Please ensure the file matches the template.',

    'upload-users-errors.invalid-team': 'Error on row: {row}. Reason: Invalid team name',
    'upload-users-errors.invalid-login-email-type': 'Error on row: {row}. Reason: Invalid login email type',
    'upload-users-errors.invalid-country': 'Error on row: {row}. Reason: Invalid country',
    'upload-users-errors.invalid-new-joiner-start-date':
        'Error on row: {row}. Reason: Invalid new joiner start date',
    'upload-users-errors.email-exists': 'Error on row: {row}. Reason: Email already exists',
    'upload-users-errors.another-user-with-this-email-already-exists':
        'Error on row: {row}. Reason: Email already exists',
    'upload-users-errors.missing-team': 'Error on row: {row}. Reason: Team is missing',
    'upload-users-errors.missing-first-name': 'Error on row: {row}. Reason: First name is missing',
    'upload-users-errors.missing-last-name': 'Error on row: {row}. Reason: Last name is missing',
    'upload-users-errors.missing-emails': 'Error on row: {row}. Reason: Both email addresses are missing',
    'upload-users-errors.missing-country': 'Error on row: {row}. Reason: Country is missing',
};
