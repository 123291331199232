import { mapValues } from 'lodash-es';

import { getSpacingValue, Theme } from '@hofy/theme';

import { MarginBoxProps, PaddingBoxProps } from './types';

export const spacingValuesFromTheme = (styles: MarginBoxProps | PaddingBoxProps, theme: Theme) =>
    mapValues(styles, value => getSpacingValue(theme, value));

export const sizeToValue = (v: string | number) => {
    if (typeof v === 'number') {
        return `${v}px`;
    }
    return v;
};
