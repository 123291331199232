import { keyBy } from 'lodash-es';
import React, { forwardRef, useMemo } from 'react';

import { useAdminsRefsQuery } from '@hofy/api-admin';
import { formatUserName, Role } from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import {
    LabeledSelectSearch,
    LabeledSelectSearchNullableStringProps,
    LabeledSelectSearchOnlyStringProps,
} from '@hofy/ui';

interface BaseProps {
    includeOffboarded?: boolean;
    placeholder?: string;
    role?: Role;
}

type LabeledAdminNormalProps = Omit<
    LabeledSelectSearchOnlyStringProps<UUID>,
    'isError' | 'toText' | 'options'
> &
    BaseProps;
type LabeledAdminNullableProps = Omit<
    LabeledSelectSearchNullableStringProps<UUID>,
    'isError' | 'toText' | 'options'
> &
    BaseProps;

export type LabeledAdminProps = LabeledAdminNormalProps | LabeledAdminNullableProps;

const optionsLengthToRender = 30;

export const LabeledAdminSelect = forwardRef<HTMLDivElement, LabeledAdminProps>(
    ({ includeOffboarded, placeholder = 'Search admin', role, value, onChange, ...rest }, ref) => {
        const { data: users, isLoading: isLoadingUsers } = useAdminsRefsQuery(role, includeOffboarded);
        const usersMap = useMemo(() => keyBy(users, v => v.id), [users]);

        return (
            <LabeledSelectSearch
                placeholder={placeholder}
                toText={id => (usersMap[id] ? formatUserName(usersMap[id]) : '')}
                toLabel={id => (usersMap[id] ? formatUserName(usersMap[id]) : '')}
                isLoadingSearch={isLoadingUsers}
                options={users.map(({ id }) => id)}
                searchPlaceholder='Search to filter'
                optionsLengthToRender={optionsLengthToRender}
                ref={ref}
                value={value}
                onChange={onChange}
                {...rest}
            />
        );
    },
);
