import { range } from 'lodash-es';
import { DateTime } from 'luxon';

import { PageRequest, PageResponse, stringifyUrl, UUID } from '@hofy/global';
import { downloadFileFromResponse } from '@hofy/helpers';
import { restClient } from '@hofy/rest';

import { AdminInvoiceEntriesFilters } from './types/AdminInvoiceEntriesFilters';
import { InvoiceEntryDetailsDto } from './types/InvoiceEntryDetailsDto';
import { InvoiceEntryDto } from './types/InvoiceEntryDto';
import { InvoiceEntryFilterQuery } from './types/InvoiceEntryFilterQuery';
import { InvoiceEntryPayload } from './types/InvoiceEntryPayload';
import { InvoiceEntryTaxAddressPayload } from './types/InvoiceEntryTaxAddressPayload';

export const invoiceMonths = range(0, 48).map(r =>
    DateTime.local(2022, 5, 1)
        .startOf('month')
        .plus({
            months: r + 1,
        })
        .minus({
            day: 1,
        }),
);

class InvoiceEntryService {
    public downloadInvoiceReport = async (filters: AdminInvoiceEntriesFilters): Promise<void> => {
        await restClient
            .downloadFile(
                stringifyUrl({
                    url: '/api/admin/invoice-entries/report',
                    query: {
                        ...filters,
                    },
                }),
            )
            .then(downloadFileFromResponse);
    };

    public listInvoiceEntries = async (
        filters: AdminInvoiceEntriesFilters,
        page: PageRequest,
    ): Promise<PageResponse<InvoiceEntryDto>> => {
        return restClient.getJson<PageResponse<InvoiceEntryDto>>(
            stringifyUrl({
                url: '/api/admin/invoice-entries',
                query: {
                    ...filters,
                    ...page,
                },
            }),
        );
    };

    public getInvoiceEntriesForContract = async (
        contractId: UUID,
        includeDeleted: boolean,
    ): Promise<InvoiceEntryDto[]> => {
        return await restClient.getJson<InvoiceEntryDto[]>(
            stringifyUrl({
                url: `/api/admin/invoice-entries/contracts/${contractId}`,
                query: {
                    includeDeleted,
                },
            }),
        );
    };

    public getInvoiceEntry = async (invoiceEntryId: UUID): Promise<InvoiceEntryDetailsDto> => {
        return restClient.getJson<InvoiceEntryDetailsDto>(`/api/admin/invoice-entries/${invoiceEntryId}`);
    };

    public createInvoiceEntry = async (payload: InvoiceEntryPayload) => {
        return restClient.post(`/api/admin/invoice-entries`, payload);
    };

    public updateInvoiceEntry = async (id: UUID, payload: InvoiceEntryPayload) => {
        return restClient.put(`/api/admin/invoice-entries/${id}`, payload);
    };

    public updateInvoiceEntryTaxAddress = async (id: UUID, payload: InvoiceEntryTaxAddressPayload) => {
        return restClient.put(`/api/admin/invoice-entries/${id}/tax-address`, payload);
    };

    public deleteInvoiceEntry = async (id: UUID) => {
        return restClient.delete(`/api/admin/invoice-entries/${id}`);
    };
    public creditInvoiceEntry = async (
        id: UUID,
    ): Promise<{
        id: UUID;
    }> => {
        return restClient.postJson<{
            id: UUID;
        }>(`/api/admin/invoice-entries/${id}/credit`);
    };

    public computeTaxForInvoiceEntry = async (id: UUID) => {
        return restClient.post(`/api/admin/invoice-entries/${id}/compute-tax`);
    };

    public reportTaxForInvoiceEntry = async (id: UUID) => {
        return restClient.post(`/api/admin/invoice-entries/${id}/report-tax`);
    };
    public recreateInvoiceEntry = async (
        id: UUID,
    ): Promise<{
        id: UUID;
    }> => {
        return restClient.postJson<{
            id: UUID;
        }>(`/api/admin/invoice-entries/${id}/recreate`);
    };

    public getInvoiceEntriesCountWithTaxErrors = async (
        filter?: InvoiceEntryFilterQuery,
    ): Promise<number> => {
        return restClient.getJson<number>(
            stringifyUrl({
                url: `/api/admin/invoice-entries/tax-errors`,
                query: { ...filter },
            }),
        );
    };
}

export const invoiceEntryService = new InvoiceEntryService();
