import { useMutation, useQueryClient } from '@tanstack/react-query';
import { some } from 'lodash-es';
import { useEffect } from 'react';

import { itemsService, purchaseOrdersCacheKey, purchaseOrderService } from '@hofy/api-admin';
import { arrayRemove, arrayUpdateAt, errorMap } from '@hofy/helpers';
import { useForm, useToast } from '@hofy/ui';

import {
    emptyReceivePurchaseOrderItem,
    emptyReceivePurchaseOrderItemDetail,
    ReceivePurchaseOrderItemDetailFormData,
    ReceivePurchaseOrderItemFormData,
} from './types/ReceivePurchaseOrderItemFormData';

const getDuplicateItemCodeIndex = (items: ReceivePurchaseOrderItemDetailFormData[]) => {
    const itemCodes = items.map(item => item.itemCode);
    const duplicates = [...new Set(itemCodes.filter((e, i, a) => a.indexOf(e) !== i))];
    const index = items.findIndex(item => duplicates.includes(item.itemCode));
    return index === -1 ? null : index;
};

export const useReceivePurchaseOrderItems = (onSuccess: () => void) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: (p: ReceivePurchaseOrderItemFormData) =>
            purchaseOrderService.receivePurchaseOrderItem(p.purchaseOrderItemId, {
                receivedItems: p.receivedItems,
                notes: p.notes,
            }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [purchaseOrdersCacheKey] });
            showToast({
                type: 'positive',
                message: 'Purchase order items received',
            });
            form.setValues({
                receivedItems: [emptyReceivePurchaseOrderItemDetail],
            });
            onSuccess();
        },
        onError: async (err: { code: string }, payload) => {
            if (err.code === 'item_code_already_exists') {
                let duplicateItem = null;
                let duplicateIndex = 0;
                const { receivedItems } = payload;
                for (const item of receivedItems) {
                    try {
                        await itemsService.getItemCode(item.itemCode!);
                        duplicateItem = item;
                        break;
                    } catch {
                        duplicateIndex += 1;
                    }
                }
                if (duplicateItem) {
                    showToast({
                        type: 'negative',
                        message: `Item code "${duplicateItem.itemCode}" on scan Nº ${
                            duplicateIndex + 1
                        } already exists`,
                    });
                    focusUnit(duplicateIndex);
                }
            }
        },
    });

    const form = useForm<ReceivePurchaseOrderItemFormData>({
        initial: emptyReceivePurchaseOrderItem,
        onSubmit: data => {
            const validItems = data.receivedItems.filter(unit => !isUnitEmpty(unit));
            if (validItems.length === 0) {
                showToast({
                    type: 'negative',
                    message: 'Please scan at least one received item',
                });
                return;
            }

            const duplicateItemCodeIndex = getDuplicateItemCodeIndex(validItems);
            if (duplicateItemCodeIndex !== null) {
                showToast({
                    type: 'negative',
                    message: `Found duplicated item code: ${validItems[duplicateItemCodeIndex].itemCode}`,
                });
                focusUnit(duplicateItemCodeIndex);
                return;
            }

            const formattedData = {
                ...data,
                receivedItems: validItems,
            };
            mutation.mutate(formattedData);
        },
        validate: ({ receivedItems }) => ({
            receivedItems: errorMap(
                [receivedItems.length === 0, 'At least one item is required'],
                [
                    some(
                        receivedItems,
                        (item, index) => index !== receivedItems.length - 1 && !item.itemCode,
                    ),
                    'Item code must be provided for each scan',
                ],
                [
                    some(
                        receivedItems,
                        (item, index) => index !== receivedItems.length - 1 && !item.itemGrade,
                    ),
                    'Grade must be provided for each scan',
                ],
            ),
        }),
    });

    const isUnitEmpty = (unit: ReceivePurchaseOrderItemDetailFormData) => {
        return !unit.itemCode && !unit.serialNumber;
    };

    const updateUnit = (index: number, data: Partial<ReceivePurchaseOrderItemDetailFormData>) => {
        form.setValues({
            receivedItems: arrayUpdateAt(form.values.receivedItems, index, item => ({ ...item, ...data })),
        });
    };

    const focusUnit = (index: number) => {
        if (form.values.receivedItems[index].focused) {
            updateUnit(index, { focused: false });
        }
        setTimeout(() => {
            updateUnit(index, { focused: true });
        }, 0);
    };

    const addUnit = () => {
        form.setValues({
            receivedItems: [...form.values.receivedItems, emptyReceivePurchaseOrderItemDetail],
        });
    };

    const deleteUnit = (index: number) => {
        if (form.values.receivedItems.length > 1) {
            form.setValues({
                receivedItems: arrayRemove(form.values.receivedItems, index),
            });
        }
    };

    useEffect(() => {
        if (
            !isUnitEmpty(form.values.receivedItems[form.values.receivedItems.length - 1]) &&
            form.values.receivedItems.length < form.values.scansLeft
        ) {
            addUnit();
        }

        form.values.receivedItems.forEach((item, index) => {
            if (index + 1 === form.values.receivedItems.length) {
                return;
            }
            if (isUnitEmpty(item)) {
                deleteUnit(index);
            }
        });
    }, [form.values.receivedItems]);

    return {
        form,
        isUnitEmpty,
        updateUnit,
        deleteUnit,
        isLoadingMutation: mutation.isPending,
    };
};
