import { isEmpty } from 'lodash-es';
import React, { FC } from 'react';

import { getShipmentStatusDate, ShipmentDetailsDto } from '@hofy/api-admin';
import { ShipmentStatus, shipmentStatusNotificationType, useTrShipmentStatus } from '@hofy/api-shared';
import { formatDate, formatDateRange } from '@hofy/helpers';
import { Alert, Button, OuterBoxProps, SvgIcon } from '@hofy/ui';
import { DisabledWithTooltipWrapper } from '@hofy/ui-domain';

import { useValidateShipmentPurchaseOrder } from '../../../store/shipments/useValidateShipmentPurchaseOrder';

interface ShipmentStatusPaneProps extends OuterBoxProps {
    onBook(): void;
    onShip(): void;
    onComplete(): void;
    onBackorder(): void;
    shipment: ShipmentDetailsDto;
    disabled: boolean;
}

export const ShipmentStatusPane: FC<ShipmentStatusPaneProps> = ({
    onBook,
    onBackorder,
    onShip,
    onComplete,
    shipment,
    disabled,
    ...boxProps
}) => {
    const trShipmentStatus = useTrShipmentStatus();
    const { status } = shipment;
    const { isValid: isPurchaseOrderValid, invalidReason } = useValidateShipmentPurchaseOrder(shipment);

    const canTransitionShipment = !disabled && isPurchaseOrderValid;

    const getTitle = () => {
        const text = status === ShipmentStatus.Backorder ? 'Order(s) on' : 'Shipment';
        const scheduled =
            status === ShipmentStatus.Booked && !isEmpty(shipment.scheduledOn) ? ' (Scheduled)' : '';
        return `${text} ${trShipmentStatus(status)} ${scheduled}`;
    };

    const getDescription = () => {
        if (status === ShipmentStatus.Backorder) {
            return getEstimatedAvailabilityDate();
        } else if (status === ShipmentStatus.Booked && shipment.scheduledOn) {
            return `Scheduled for ${formatDate(shipment.scheduledOn)}`;
        }

        return formatDate(getShipmentStatusDate<ShipmentDetailsDto>(shipment));
    };

    const getEstimatedAvailabilityDate = () => {
        const date = formatDateRange(shipment.estimatedAvailability);
        return date && `Estimated availability from ${date}`;
    };

    return (
        <Alert
            icon={SvgIcon.Truck}
            type={shipmentStatusNotificationType[status]}
            title={getTitle()}
            description={getDescription()}
            horizontal
            {...boxProps}
        >
            <ShipmentActionButtons
                status={status}
                buttonType='secondary'
                onBook={onBook}
                onShip={onShip}
                onComplete={onComplete}
                onBackorder={onBackorder}
                canTransitionShipment={canTransitionShipment}
                invalidReason={invalidReason}
            />
        </Alert>
    );
};

interface ShipmentActionButtonsProps {
    status: ShipmentStatus;
    onBook(): void;
    onShip(): void;
    onComplete(): void;
    onBackorder(): void;
    canTransitionShipment: boolean;
    invalidReason?: string;
    disableBackorder?: boolean;
    disabled?: boolean;
    buttonType?: 'primary' | 'secondary';
}

export const ShipmentActionButtons: FC<ShipmentActionButtonsProps> = ({
    status,
    onBook,
    onBackorder,
    onShip,
    onComplete,
    canTransitionShipment,
    invalidReason,
    disableBackorder = false,
    buttonType = 'primary',
    disabled = false,
}) => {
    switch (status) {
        case ShipmentStatus.Created:
            return (
                <>
                    {!disableBackorder && (
                        <Button
                            type={buttonType}
                            label='Mark as back order'
                            onClick={onBackorder}
                            disabled={disabled}
                        />
                    )}
                    <DisabledWithTooltipWrapper disabled={!canTransitionShipment} tooltip={invalidReason}>
                        <Button
                            type={buttonType}
                            label='Mark as processed'
                            onClick={onBook}
                            disabled={disabled}
                        />
                    </DisabledWithTooltipWrapper>
                </>
            );
        case ShipmentStatus.Booked:
            return (
                <DisabledWithTooltipWrapper disabled={!canTransitionShipment} tooltip={invalidReason}>
                    <Button type={buttonType} onClick={onShip} label='Mark as shipped' disabled={disabled} />
                </DisabledWithTooltipWrapper>
            );
        case ShipmentStatus.WithCourier:
            return (
                <DisabledWithTooltipWrapper disabled={!canTransitionShipment} tooltip={invalidReason}>
                    <Button
                        type={buttonType}
                        onClick={onComplete}
                        label='Mark as complete'
                        disabled={disabled}
                    />
                </DisabledWithTooltipWrapper>
            );
        case ShipmentStatus.Backorder:
            return (
                <DisabledWithTooltipWrapper disabled={!canTransitionShipment} tooltip={invalidReason}>
                    <Button
                        type={buttonType}
                        label='Mark as processed'
                        onClick={onBook}
                        disabled={disabled}
                    />
                </DisabledWithTooltipWrapper>
            );
    }
    return null;
};
