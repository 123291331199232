import { difference, sortBy, xorBy } from 'lodash-es';
import React, { FC, useMemo, useState } from 'react';

import {
    countriesIncludes,
    Country,
    getEnumValues,
    regionCountries,
    regionHierarchy,
    RootRegion,
    RootRegionConfig,
    SubRegionConfig,
} from '@hofy/global';
import { useBaseI18n, useTrCountry, useTrRegion } from '@hofy/i18n';
import { Color } from '@hofy/theme';

import { ExpandHeight } from '../../../animations/ExpandHeight';
import { Box } from '../../../base/Box';
import { SeparatedBox } from '../../../base/index';
import { Overline1, Paragraph3 } from '../../../base/Typography';
import { SvgIcon } from '../../../icon/index';
import { ModalContent } from '../../../modal/base/ModalContent';
import { ModalFooter } from '../../../modal/base/ModalFooter';
import { ModalHeader } from '../../../modal/base/ModalHeader';
import { Modal } from '../../../modal/Modal';
import { Chevron } from '../../../shared/index';
import { Button } from '../../button/Button';
import { Checkbox } from '../../checkbox/Checkbox';
import { Select } from '../Select';
import { CountryLabel } from './CountryLabel';

interface CountryRegionModalProps {
    title: string;
    initialCountries: Country[];
    onSave(country: Country[]): void;
    onCancel(): void;
}

export const CountryRegionModal: FC<CountryRegionModalProps> = ({
    onCancel,
    title,
    onSave,
    initialCountries,
}) => {
    const [countries, setCountries] = useState<Country[]>(initialCountries);

    const toggleCountries = (country: Country[]) => {
        setCountries(xorBy(countries, country));
    };
    const { tr, trParts } = useBaseI18n();

    const onChange = (option: SelectOption) => {
        switch (option) {
            case SelectOption.DeselectAll:
                setCountries([]);
                break;
            case SelectOption.SelectAll:
                setCountries([...regionCountries[RootRegion.Core], ...regionCountries[RootRegion.NonCore]]);
                break;
            case SelectOption.CoreGeographies:
                setCountries([...regionCountries[RootRegion.Core]]);
                break;
            case SelectOption.NonCoreGeographies:
                setCountries([...regionCountries[RootRegion.NonCore]]);
                break;
            default:
                break;
        }
    };
    return (
        <Modal onClose={onCancel} width={600}>
            <ModalHeader
                title={title}
                titleSlot={
                    <Box flex={1} alignItems='flex-start'>
                        <Select
                            placeholder='Bulk select'
                            value={null}
                            toText={v => trParts('country-region-modal', v)}
                            options={allSelectOptions}
                            onChange={onChange}
                        />
                    </Box>
                }
                borderBottom
            />

            <ModalContent gap={30} column>
                {regionHierarchy.map(regionConfig => (
                    <RegionTopBlock
                        countries={countries}
                        onToggle={toggleCountries}
                        region={regionConfig}
                        key={regionConfig.region}
                    />
                ))}
            </ModalContent>

            <ModalFooter borderTop>
                <Button
                    type='secondary'
                    onClick={onCancel}
                    label={tr('country-region-modal.cancel')}
                    leftIcon={SvgIcon.Cross}
                    testKey='cancel'
                />
                <Button label={tr('country-region-modal.apply')} onClick={() => onSave(countries)} />
            </ModalFooter>
        </Modal>
    );
};

interface RegionTopBlockProps {
    countries: Country[];
    region: RootRegionConfig;
    onToggle(n: Country[]): void;
}

const RegionTopBlock: FC<RegionTopBlockProps> = ({ region, countries, onToggle }) => {
    const trRegion = useTrRegion();
    return (
        <Box>
            <Overline1 marginBottom={15} upper color={Color.ContentSecondary}>
                {trRegion(region.region)}
            </Overline1>
            <Box>
                {region.subregions.map((r, index) => (
                    <RegionBlock
                        isLast={index === region.subregions.length - 1}
                        countries={countries}
                        onToggle={onToggle}
                        region={r}
                        key={r.region}
                    />
                ))}
            </Box>
        </Box>
    );
};

interface RegionBlockProps {
    isLast: boolean;
    countries: Country[];
    region: SubRegionConfig;
    onToggle(n: Country[]): void;
}

const RegionBlock: FC<RegionBlockProps> = ({ region, onToggle, countries, isLast }) => {
    const trRegion = useTrRegion();
    const [isOpen, setIsOpen] = useState(false);
    const trCountry = useTrCountry();
    const regionCountries = useMemo(() => sortBy(region.countries, [c => trCountry(c)]), [region.countries]);
    if (regionCountries.length === 1) {
        return (
            <Box paddingVertical={15} row borderBottom={!isLast}>
                <Paragraph3 bold color={Color.ContentSecondary} flex={1}>
                    {trRegion(region.region)}
                </Paragraph3>
                <Checkbox
                    checked={countriesIncludes(countries, regionCountries)}
                    onChange={() => onToggle(regionCountries)}
                />
            </Box>
        );
    }
    const notSelectedFromCurrent = difference(regionCountries, countries);
    const isPartial = notSelectedFromCurrent.length > 0;
    const isChecked = notSelectedFromCurrent.length !== regionCountries.length;
    const handleGroupClick = () => {
        if (!isChecked) {
            onToggle(regionCountries);
        } else if (isPartial) {
            onToggle(notSelectedFromCurrent);
        } else {
            onToggle(regionCountries);
        }
    };
    return (
        <Box>
            <Box
                paddingVertical={15}
                row
                onClick={() => setIsOpen(!isOpen)}
                borderBottom={!isLast && !isOpen}
            >
                <Paragraph3 bold row color={Color.ContentPrimary} flex={1}>
                    {trRegion(region.region)}
                    <Box marginLeft={8}>
                        <Chevron isOpen={isOpen} />
                    </Box>
                </Paragraph3>
                <Checkbox
                    checked={isPartial && isChecked ? 'mixed' : isChecked}
                    onChange={handleGroupClick}
                />
            </Box>
            <Box>
                <ExpandHeight>
                    {isOpen && (
                        <SeparatedBox column>
                            {regionCountries.map(country => (
                                <Box
                                    key={country}
                                    paddingVertical={12}
                                    as='label'
                                    row
                                    justify='space-between'
                                >
                                    <CountryLabel country={country} />
                                    <Checkbox
                                        checked={countriesIncludes(countries, [country])}
                                        onChange={() => onToggle([country])}
                                    />
                                </Box>
                            ))}
                        </SeparatedBox>
                    )}
                </ExpandHeight>
            </Box>
        </Box>
    );
};

export enum SelectOption {
    DeselectAll = 'deselect-all',
    SelectAll = 'select-all',
    CoreGeographies = 'core-geographies',
    NonCoreGeographies = 'non-core-geographies',
}
export const allSelectOptions = getEnumValues<SelectOption>(SelectOption);
