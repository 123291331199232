import React, { FC } from 'react';

import {
    ArrayField,
    Box,
    FormContainer,
    FormCountrySelect,
    FormDateInput,
    FormInput,
    UseForm,
    UseMultipartFormState,
} from '@hofy/ui';

import { FormOrganizationSelect } from '../../../../components/domain/organizations/FormOrganizationSelect';
import { FormHofyWarehouseSelect } from '../../../../components/domain/warehouses/FormHofyWarehouseSelect';
import {
    BYODMultipartForm,
    BYODOnlyOrderFormData,
    BYODOrderFormItemData,
    WizardSteps,
} from '../../../../store/byodOrders/types/BYODOrderFormData';
import { BYODItemsForm } from './BYODItemsForm';

interface CreateUpdateBYODOrderFormProps {
    form: UseMultipartFormState<BYODMultipartForm>;
    items: ArrayField<BYODOrderFormItemData>;
    step: WizardSteps;
}

export const CreateUpdateBYODOrderForm: FC<CreateUpdateBYODOrderFormProps> = ({ form, items, step }) => {
    const content = () => {
        switch (step) {
            case WizardSteps.Order:
                return <BasicDataForm form={form.forms[WizardSteps.Order]} />;
            case WizardSteps.Items:
                return (
                    <BYODItemsForm
                        items={items}
                        orgId={form.forms[WizardSteps.Order].values.organizationId!}
                    />
                );
        }
    };

    return <Box paddingVertical={10}>{content()}</Box>;
};

interface BasicDataFormProps {
    form: UseForm<BYODOnlyOrderFormData>;
}

const BasicDataForm: FC<BasicDataFormProps> = ({ form }) => {
    return (
        <FormContainer marginVertical={10}>
            <FormOrganizationSelect
                label='Organization'
                api={form.fields.organizationId}
                placeholder='Select an organisation'
                isRequired
                nullable
            />
            <FormCountrySelect api={form.fields.fromCountry} isRequired nullable label='From country' />
            <FormHofyWarehouseSelect
                api={form.fields.toWarehouseId}
                warehouseFilter={{ byodSupported: true }}
                isRequired
                nullable
                label='Warehouse'
            />
            <FormInput
                label='Order reference'
                api={form.fields.orderReference}
                placeholder='Enter an order reference'
                nullable
            />
            <FormInput
                label='Courier reference'
                api={form.fields.courierReference}
                placeholder='Enter a courier reference'
                nullable
            />
            <FormInput
                label='Tracking link'
                api={form.fields.trackingLink}
                placeholder='Enter a shipment tracking link'
                nullable
            />
            <FormDateInput
                label='Estimated delivery date'
                api={form.fields.estimatedReceiveOn}
                isRequired
                nullable
            />
        </FormContainer>
    );
};
