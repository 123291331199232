import { xor } from 'lodash-es';
import React, { Key, ReactNode } from 'react';

import { TestKeyPrefixAware } from '../../../types';
import { Box, OuterBoxProps } from '../../base';
import { LabeledCheckbox } from './LabeledCheckbox';

export interface CheckboxGroupProps<T> extends OuterBoxProps, TestKeyPrefixAware {
    options: T[];
    value: T[];
    toLabel(v: T): ReactNode;
    toKey(v: T): Key;
    onChange(v: T[]): void;
    disabledOptions?: T[];
    disabled?: boolean;
    horizontal?: boolean;
}

export const CheckboxGroup = <T,>({
    value,
    options,
    toLabel,
    toKey,
    onChange,
    disabled,
    disabledOptions = [],
    horizontal,
    testKeyPrefix,
    ...rest
}: CheckboxGroupProps<T>) => {
    return (
        <Box direction={horizontal ? 'row' : 'column'} gap={horizontal ? 16 : 8} {...rest} wrap>
            {options.map(item => (
                <LabeledCheckbox
                    label={toLabel(item)}
                    disabled={disabled || disabledOptions.includes(item)}
                    checked={value.includes(item)}
                    key={toKey(item)}
                    onChange={() => onChange(xor(value, [item]))}
                    testKey={testKeyPrefix ? `${testKeyPrefix}-${toKey(item)}` : undefined}
                />
            ))}
        </Box>
    );
};
