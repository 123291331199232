import { FloatingFocusManager, FloatingPortal } from '@floating-ui/react';
import { AnimatePresence } from 'framer-motion';
import { kebabCase } from 'lodash-es';
import React, { ReactNode, useMemo } from 'react';

import { TestKeyAware } from '../../types';
import { ReactNodeFunction, renderChildren } from '../../types/React';
import { Box } from '../base';
import { useInteractiveMultiList } from './hooks/useInteractiveMultiList';
import { DropdownContentScrollable } from './shared/DropdownContentScrollable';
import { DropdownInteractiveListItem } from './shared/DropdownInteractiveListItem';
import { DropdownTrigger } from './shared/DropdownTrigger';
import { DropdownTriggerLabel } from './shared/DropdownTriggerLabel';
import { UnselectAllListItem } from './shared/UnselectAllListItem';
import { InteractiveListBase } from './types/InteractiveListTypes';

export interface DropdownCheckboxListProps<T> extends InteractiveListBase<T>, TestKeyAware {
    values: T[];
    onChange(values: T[]): void;

    trigger?: ReactNode | ReactNodeFunction<[isOpen: boolean]>;
    /** Only used when custom trigger is not provided */
    triggerPlaceholder?: string;
    asChild?: boolean;

    fixedOptions?: NonNullable<T>[];
    contentWidth?: string | number | 'auto';

    onFocus?(): void;
    onBlur?(): void;
}

export const DropdownCheckboxList = <T,>({
    trigger,
    triggerPlaceholder,
    asChild,

    fixedOptions,
    contentWidth,
    contentMaxHeight,
    placement,

    values,
    options,
    onChange,
    toText,
    toKey,
    toLabel,
    disabled: dropdownDisabled,
    testKey,
}: DropdownCheckboxListProps<T>) => {
    const allOptions = useMemo(() => {
        if (fixedOptions) {
            return [...fixedOptions, ...options];
        }
        return options;
    }, [fixedOptions, options]);

    const state = useInteractiveMultiList({
        options: allOptions,
        toText,
        toKey,
        toLabel,
        values,
        onChange,
        disabled: dropdownDisabled,
        contentWidth,
        contentMaxHeight,
        placement,
    });

    const {
        context,
        isOpen,
        activeIndex,
        refs,
        floatingStyles,
        floatingProps,
        referenceProps,
        itemProps,
        listRef,
        getKey,
        getLabel,
        resultantPlacement,
        disabled,
    } = state;

    const renderItem = (item: NonNullable<T>, index: number) => {
        const key = getKey(item);
        return (
            <DropdownInteractiveListItem
                key={key}
                ref={node => {
                    listRef.current[index] = node;
                }}
                label={getLabel(item)}
                isActive={index === activeIndex}
                isSelected={values.includes(item)}
                testKey={testKey && `${testKey}-${kebabCase(key)}`}
                checkbox
                {...itemProps(item)}
            />
        );
    };

    const indexOffset = fixedOptions?.length ?? 0;

    const renderTrigger = () => {
        if (trigger) {
            return renderChildren(trigger, isOpen);
        }
        return (
            <DropdownTriggerLabel
                label={values.map(getLabel).join(', ') || triggerPlaceholder}
                isOpen={isOpen}
                disabled={disabled}
            />
        );
    };

    return (
        <>
            <DropdownTrigger
                referenceProps={referenceProps}
                setReference={refs.setReference}
                asChild={trigger ? asChild : true}
                testKey={testKey}
            >
                {renderTrigger()}
            </DropdownTrigger>

            <AnimatePresence>
                {isOpen && (
                    <FloatingPortal>
                        <FloatingFocusManager context={context} modal={false}>
                            <DropdownContentScrollable
                                ref={refs.setFloating}
                                style={floatingStyles}
                                floatingProps={floatingProps()}
                                placement={resultantPlacement}
                                topSlot={
                                    fixedOptions && (
                                        <Box borderBottom padding={4} marginBottom={4} column gap={4}>
                                            {fixedOptions.map((item, index) => renderItem(item, index))}
                                        </Box>
                                    )
                                }
                                bottomSlot={
                                    <Box borderTop padding={4} marginTop={4} column gap={4}>
                                        <UnselectAllListItem
                                            onClick={() => onChange([])}
                                            disabled={values.length === 0}
                                        />
                                    </Box>
                                }
                            >
                                {options.map((item, index) => renderItem(item, index + indexOffset))}
                            </DropdownContentScrollable>
                        </FloatingFocusManager>
                    </FloatingPortal>
                )}
            </AnimatePresence>
        </>
    );
};
