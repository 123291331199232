import { stringifyUrl } from 'query-string';

import { PageRequest, PageResponse, UUID } from '@hofy/global';
import { restClient } from '@hofy/rest';

import { OrganizationContractDetailsDto } from './types/OrganizationContractDetailsDto';
import { OrganizationContractDto } from './types/OrganizationContractDto';
import { CreateOrganizationContractPayload } from './types/OrganizationContractPayload';
import { DeactivateUsersPayload } from './types/DeactivateUsersPayload';

class OrganizationContractService {
    public listOrganizationContracts = async (
        organizationId: UUID,
        page: PageRequest,
    ): Promise<PageResponse<OrganizationContractDto>> => {
        return restClient.getJson<PageResponse<OrganizationContractDto>>(
            stringifyUrl({
                url: `/api/admin/organizations/${organizationId}/organization-contracts`,
                query: {
                    ...page,
                },
            }),
        );
    };

    public createOrganizationContract = async (payload: CreateOrganizationContractPayload): Promise<UUID> => {
        return restClient
            .postJson<{
                id: UUID;
            }>(`/api/admin/organizations/${payload.organizationId}/organization-contracts`, payload)
            .then(r => r.id);
    };

    public getOrganizationContract = async (
        organizationId: UUID,
        organizationContractId: UUID,
    ): Promise<OrganizationContractDetailsDto> => {
        return restClient.getJson<OrganizationContractDetailsDto>(
            `/api/admin/organizations/${organizationId}/organization-contracts/${organizationContractId}`,
        );
    };

    public deactivateUsersForOrganizationContract = async (
        organizationId: UUID,
        organizationContractId: UUID,
        payload: DeactivateUsersPayload,
    ): Promise<void> => {
        return restClient.post(
            `/api/admin/organizations/${organizationId}/organization-contracts/${organizationContractId}/deactivate`,
            payload,
        );
    };
}

export const organizationContractService = new OrganizationContractService();
