import React, { FC } from 'react';

import { AdminClearanceRequestDto } from '@hofy/api-admin';
import { formatDateTime } from '@hofy/helpers';
import { BaseTable, Paragraph3, Placeholder, SvgIllustration } from '@hofy/ui';

import { OrganizationLink } from '../organizationsPage/OrganizationDetailsLink';

interface ClearanceRequestsTableProps {
    clearanceRequests: AdminClearanceRequestDto[];
}

export const ClearanceRequestsTable: FC<ClearanceRequestsTableProps> = ({ clearanceRequests }) => {
    return (
        <BaseTable
            data={clearanceRequests}
            toKey={repair => repair.id}
            emptyContent={<Placeholder illustration={SvgIllustration.List} title='No repairs' />}
            headerHorizontalPadding={40}
            rowHorizontalPadding={30}
            flex='auto'
            columns={[
                {
                    id: 'clearanceId',
                    header: 'Clearance ID',
                    width: 80,
                    renderer: ({ publicId }) => <Paragraph3>{publicId}</Paragraph3>,
                },
                {
                    id: 'organization',
                    header: 'Organization',
                    width: 300,
                    renderer: ({ organization }) => (
                        <Paragraph3>
                            <OrganizationLink id={organization.id}>{organization.name}</OrganizationLink>
                        </Paragraph3>
                    ),
                },
                {
                    id: 'createdOn',
                    header: 'Created on',
                    width: 180,
                    renderer: ({ createdOn }) => <Paragraph3>{formatDateTime(createdOn)}</Paragraph3>,
                },
            ]}
        />
    );
};
