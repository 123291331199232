import React, { FC } from 'react';

import {
    OrganizationContractDetailsDto,
    useTrOrganizationContractTerm,
    useTrOrganizationContractType,
} from '@hofy/api-admin';
import { formatDate } from '@hofy/helpers';
import { usePrice } from '@hofy/hooks';
import { Box, FormContainer, FormGridRow, FormSection, Labeled, LabeledText } from '@hofy/ui';

import { AuditSection } from '../../../../components/domain/auditingInline/AuditSection';
import { OrganizationContractStatusChip } from '../../../../components/domain/organizationContracts/OrganizationContractStatusChip';
import { LabeledOrganizationLink } from '../../../organizationsPage/OrganizationDetailsLink';

interface OrganizationContractDetailsProps {
    organizationContract: OrganizationContractDetailsDto;
}

export const OrganizationContractDetails: FC<OrganizationContractDetailsProps> = ({
    organizationContract,
}) => {
    const { formatPrice } = usePrice();
    const trOrganizationContractType = useTrOrganizationContractType();
    const trOrganizationContractTerm = useTrOrganizationContractTerm();

    return (
        <Box overflow='auto' fullHeight row alignItems='flex-start' paddingHorizontal={40}>
            <FormContainer flex={1} marginVertical={30} marginRight={20}>
                <FormSection label='Details'>
                    <FormGridRow columns={4}>
                        <LabeledOrganizationLink organization={organizationContract.organization} />

                        <LabeledText
                            label='Type'
                            content={trOrganizationContractType(organizationContract.type)}
                        />

                        <Labeled
                            label='Status'
                            content={<OrganizationContractStatusChip status={organizationContract.status} />}
                        />
                        <LabeledText
                            label='Term'
                            content={trOrganizationContractTerm(organizationContract.term)}
                        />
                        <LabeledText label='Start date' content={formatDate(organizationContract.startOn)} />
                        <LabeledText label='End date' content={formatDate(organizationContract.endOn)} />
                    </FormGridRow>
                </FormSection>
                <FormSection label='Pricing & Seats'>
                    <FormGridRow columns={4}>
                        <LabeledText label='Total paid seats' content={organizationContract.paidSeats} />
                        <LabeledText
                            label='Assigned seats'
                            content={organizationContract.assignedActiveSeats}
                        />
                        <LabeledText
                            label='Pending approval seats'
                            content={organizationContract.pendingSeats}
                        />
                        <LabeledText
                            label='Available seats (excluding pending)'
                            content={organizationContract.availableSeats}
                        />
                        <LabeledText
                            label='Monthly seat price'
                            content={formatPrice(organizationContract.monthlySeatPrice)}
                        />
                    </FormGridRow>
                </FormSection>
                <AuditSection model={organizationContract} />
            </FormContainer>
        </Box>
    );
};
