import { isEmpty } from 'lodash-es';
import React, { FC, ReactNode } from 'react';

import { Permission } from '@hofy/api-shared';
import { BoxProps, DisableContextProvider } from '@hofy/ui';

import { DisabledWithTooltipWrapper } from './DisabledWithTooltipWrapper';
import { useHasAnyPermission } from './hooks/useHasAnyPermission';
import { usePermissionMessage } from './hooks/usePermissionMessage';

interface PermissionWrapperProps extends BoxProps {
    permission: Permission | Permission[];
    /** true will use default message, string will use custom message, false will disable tooltip */
    tooltip?: string | boolean;
    children?: ReactNode;
}

export const PermissionWrapper: FC<PermissionWrapperProps> = ({
    permission,
    children,
    tooltip = false,
    ...boxProps
}) => {
    const hasPermission = useHasAnyPermission(permission);

    const trPermission = usePermissionMessage();
    const tooltipMessage = tooltip === true ? trPermission(permission) : tooltip;

    if (tooltip === false && isEmpty(boxProps)) {
        return <DisableContextProvider disabled={!hasPermission}>{children}</DisableContextProvider>;
    }

    return (
        <DisabledWithTooltipWrapper tooltip={tooltipMessage} disabled={!hasPermission} {...boxProps}>
            {children}
        </DisabledWithTooltipWrapper>
    );
};
