import { rgba } from 'polished';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { Color } from '@hofy/theme';
import { Box, Font, Icon, MarginBoxProps, SvgIcon, TestKeyAware } from '@hofy/ui';

interface FilterEntryProps extends MarginBoxProps, TestKeyAware {
    isActive?: boolean;
    isSelectable?: boolean;
    type?: 'primary' | 'secondary';
    onChange?(v: boolean): void;
    isGreyedOut?: boolean;
    children?: ReactNode;
}

export const FilterEntry: FC<FilterEntryProps> = ({
    isActive,
    isSelectable = true,
    type = 'primary',
    onChange,
    children,
    testKey,
    isGreyedOut = false,
    ...margins
}) => {
    const defaultColor = type === 'primary' ? Color.Neutral700 : Color.Neutral500;
    return (
        <FontWrapper
            isActive={isActive}
            isGreyedOut={isGreyedOut}
            row
            justify='space-between'
            onClick={() => onChange?.(!isActive)}
            isSelectable={isSelectable}
            pointer
            data-test-key={testKey}
            {...margins}
        >
            <Font
                size={14}
                weight={isActive || type === 'primary' ? 'bold' : 'normal'}
                color={isActive ? Color.Purple : defaultColor}
                textNoWrap
            >
                {children}
            </Font>
            {isActive && <Icon svg={SvgIcon.Cross} block size={15} color={Color.Purple} />}
        </FontWrapper>
    );
};

const FontWrapper = styled(Box)<{ isActive?: boolean; isSelectable?: boolean; isGreyedOut: boolean }>(({
    isActive,
    isSelectable,
    isGreyedOut,
}) => {
    return {
        'display': 'flex',
        'align-items': 'center',
        'justify-content': 'space-between',
        'padding': '3px 10px',
        'borderRadius': 5,
        'backgroundColor': isActive ? rgba(Color.Purple, 0.1) : undefined,
        '&:hover': {
            backgroundColor: isSelectable ? rgba(Color.Purple, isActive ? 0.15 : 0.05) : undefined,
        },
        'opacity': isGreyedOut ? '0.5' : undefined,
    };
});
