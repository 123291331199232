import { autoUpdate, ExtendedRefs, ReferenceType, useFloating } from '@floating-ui/react';
import { isNil, xor } from 'lodash-es';
import { useMemo, useState } from 'react';

import { useIsDisabled } from '../../../contexts';
import { InteractiveMultiList } from '../types/InteractiveListTypes';
import { useA11yListInteractions } from './a11y/useA11yListInteractions';
import { dropdownMiddleware } from './dropdownMiddleware';

export const useInteractiveMultiList = <T>({
    values,
    onChange,
    options,
    toText,
    toLabel,
    toKey,
    disabled: listDisabled,
    contentWidth,
    contentMaxHeight,
    placement = 'bottom',
}: InteractiveMultiList<T>) => {
    const disabled = useIsDisabled(listDisabled);

    const [isOpen, setIsOpen] = useState(false);

    const select = (selectedValue: T) => {
        onChange(xor(values, [selectedValue]));
    };

    const getLabel = (value: T) => {
        if (isNil(value)) {
            return null;
        }
        const text = toText(value!);
        if (toLabel) {
            return toLabel(value!);
        }
        return text;
    };

    const getKey = (value: T) => {
        if (toKey) {
            return toKey(value!);
        }
        return toText(value!);
    };

    const {
        refs,
        floatingStyles,
        context,
        placement: resultantPlacement,
    } = useFloating({
        placement,
        open: isOpen,
        onOpenChange: setIsOpen,
        whileElementsMounted: (reference, floating, update) =>
            autoUpdate(reference, floating, update, { elementResize: false }),
        middleware: dropdownMiddleware({ contentWidth, contentMaxHeight }),
    });

    const stringList = useMemo(() => options.map(toText), [options]);

    const { referenceProps, floatingProps, itemProps, activeIndex, listRef } = useA11yListInteractions({
        context,
        stringList,
        disabled,
        onChange: select,
    });

    return {
        refs: refs as ExtendedRefs<ReferenceType>,
        context,
        isOpen,
        setIsOpen,
        activeIndex,
        select,
        getLabel,
        getKey,
        referenceProps,
        floatingProps,
        itemProps,
        listRef,
        floatingStyles,
        disabled,
        resultantPlacement,
    };
};
