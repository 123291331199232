import React, { FC } from 'react';

import { useTrOrganizationContractTerm, useTrOrganizationContractType } from '@hofy/api-admin';
import { allOrganizationContractTerms, allOrganizationContractTypes } from '@hofy/api-shared';
import { FormContainer, FormDateInput, FormNumberInput, FormSelect } from '@hofy/ui';

import { UseOrganizationContractForm } from '../../../store/organizationContracts/useOrganizationContractForm';

interface OrganizationContractFormProps {
    organizationContractForm: UseOrganizationContractForm;
}

export const OrganizationContractForm: FC<OrganizationContractFormProps> = ({ organizationContractForm }) => {
    const { form } = organizationContractForm;
    const trOrganizationContractType = useTrOrganizationContractType();
    const trOrganizationContractTerm = useTrOrganizationContractTerm();

    return (
        <FormContainer marginVertical={40}>
            <FormSelect
                options={allOrganizationContractTypes}
                label='Type'
                api={form.fields.type}
                toText={trOrganizationContractType}
                isRequired
            />
            <FormSelect
                options={allOrganizationContractTerms}
                label='Term'
                api={form.fields.term}
                toText={trOrganizationContractTerm}
                isRequired
            />
            <FormNumberInput label='Paid seats' api={form.fields.paidSeats} isRequired />
            <FormDateInput api={form.fields.startOn} label='Start on' />
        </FormContainer>
    );
};
