import React, { FC } from 'react';

import { isRangeUnbound, PriceRange } from '@hofy/global';

import { FormFieldApi } from '../../../../hooks';
import { Optional } from '../../../../types';
import { LabeledPriceRangeInput, LabeledPriceRangeInputProps } from './LabeledPriceRangeInput';

type FormPriceRangeInputOptionalProps = Optional<LabeledPriceRangeInputProps, 'onChange'>;
type FormPriceRangeInputOmittedProps = Omit<FormPriceRangeInputOptionalProps, 'errorMessage' | 'value'>;

interface FormNormalPriceRangeInputProps extends FormPriceRangeInputOmittedProps {
    api: FormFieldApi<PriceRange>;
    nullable?: false;
}

interface FormNullablePriceRangeInputProps extends FormPriceRangeInputOmittedProps {
    api: FormFieldApi<PriceRange | null>;
    nullable: true;
}

type FormPriceRangeInputProps = FormNormalPriceRangeInputProps | FormNullablePriceRangeInputProps;

export const FormPriceRangeInput: FC<FormPriceRangeInputProps> = ({
    api,
    onChange,
    onBlur,
    ...inputProps
}) => {
    return (
        <LabeledPriceRangeInput
            {...inputProps}
            ref={api.ref}
            value={api.value!}
            onChange={value => {
                const v = value === null || isRangeUnbound(value) ? null! : value;
                api.setValue(v);
                onChange?.(v);
            }}
            errorMessage={api.errorMessage}
            onBlur={() => {
                api.setTouched(true);
                onBlur?.();
            }}
        />
    );
};
