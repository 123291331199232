import { omit } from 'lodash-es';

import { AccountKey, AdminJournalsFilters } from '@hofy/api-admin';
import { NetsuiteSyncStatus } from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import { countObjectValues } from '@hofy/helpers';
import { useStructMemo } from '@hofy/hooks';
import { useArrayQueryParam, useDateRangeQueryParam, useStringQueryParam } from '@hofy/router';

export const useJournalsFilters = () => {
    const [search, setSearch] = useStringQueryParam<string>('search', '');

    const [dateRange, setDateRange] = useDateRangeQueryParam('dateRange');

    const [debitAccounts, setDebitAccounts] = useArrayQueryParam<AccountKey>('debitAccounts', []);
    const [creditAccounts, setCreditAccounts] = useArrayQueryParam<AccountKey>('creditAccounts', []);
    const [netsuiteStatuses, setNetsuiteStatuses] = useArrayQueryParam<NetsuiteSyncStatus>(
        'netsuiteStatuses',
        [],
    );
    const [hofySubsidiaryIds, setHofySubsidiaryIds] = useArrayQueryParam<UUID>('hofySubsidiaryIds', []);

    const filters = useStructMemo<AdminJournalsFilters>({
        search,
        dateRange,
        debitAccounts,
        creditAccounts,
        netsuiteStatuses,
        hofySubsidiaryIds,
    });

    const filtersToCount = omit(filters, 'search');
    const filterCount = countObjectValues(filtersToCount);

    return {
        search,
        filters,
        filterCount,
        setSearch,
        setDateRange,
        setDebitAccounts,
        setCreditAccounts,
        setNetsuiteStatuses,
        setHofySubsidiaryIds,
    };
};
