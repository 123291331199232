import React, { FC } from 'react';

import { Slideout, SlideoutContent, SlideoutHeader } from '@hofy/common';
import { UUID } from '@hofy/global';
import { Form, SubmitButton } from '@hofy/ui';

import { CancelButton } from '../../../components/design/button/CancelButton';
import { Footer } from '../../../components/design/layout/Footer';
import { useCreateOrganizationContract } from '../../../store/organizationContracts/useCreateOrganizationContract';
import { OrganizationContractForm } from './OrganizationContractForm';

interface CreateOrganizationContractOverlayProps {
    onClose(): void;
    organizationId: UUID;
}

export const CreateOrganizationContractOverlay: FC<CreateOrganizationContractOverlayProps> = ({
    onClose,
    organizationId,
}) => {
    const title = 'Create organization contract';
    const organizationContractForm = useCreateOrganizationContract(onClose, organizationId);

    return (
        <Slideout width={600} onClose={onClose} slideoutId='add-organization-contract'>
            <SlideoutHeader title={title} />
            <SlideoutContent>
                <Form
                    onSubmit={organizationContractForm.form.submit}
                    isLoading={organizationContractForm.isLoading}
                    isError={organizationContractForm.isError}
                    flex='auto'
                    column
                >
                    <OrganizationContractForm organizationContractForm={organizationContractForm} />

                    <Footer>
                        <CancelButton onClick={onClose} label='Cancel' />
                        <SubmitButton label='Save' />
                    </Footer>
                </Form>
            </SlideoutContent>
        </Slideout>
    );
};
