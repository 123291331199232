import { fromPairs } from 'lodash-es';
import { useMemo } from 'react';

import { useProductsQuery, VariantDto } from '@hofy/api-admin';
import { UUID } from '@hofy/global';

interface UseVariants {
    variants: VariantDto[];
    variantProductMap: Record<UUID, string>;
    variantMap: Record<UUID, VariantDto>;
    isLoading: boolean;
}

export const useVariants = (): UseVariants => {
    const { data: products, isLoading } = useProductsQuery();
    const variants = useMemo(() => {
        return products.flatMap(product => product.variants);
    }, [products]);

    const variantProductMap = useMemo(() => {
        return fromPairs(
            products.flatMap(product => product.variants.map(variant => [variant.id, product.name])),
        );
    }, [products]);

    const variantMap = useMemo(() => {
        return fromPairs(products.flatMap(product => product.variants.map(variant => [variant.id, variant])));
    }, [products]);

    return { variants, variantProductMap, variantMap, isLoading };
};
