import { ColorDye, getEnumValues } from '@hofy/global';

export enum OrganizationContractStatus {
    Active = 'active',
    Expired = 'expired',
    DoNotRenew = 'do_not_renew',
}

export const allOrganizationContractStatuses =
    getEnumValues<OrganizationContractStatus>(OrganizationContractStatus);

export const organizationContractStatusColor: Record<OrganizationContractStatus, ColorDye> = {
    [OrganizationContractStatus.Active]: 'green',
    [OrganizationContractStatus.Expired]: 'orange',
    [OrganizationContractStatus.DoNotRenew]: 'grey',
};
