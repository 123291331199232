import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isNumber } from 'lodash-es';
import { useEffect } from 'react';

import { AddonDataDependenciesDto, AddonPayload, addonsCacheKey } from '@hofy/api-admin';
import { decimalToNumber, isDecimal } from '@hofy/global';
import { is, isRequired, minLength, useForm, validateArrayField, validator, Validators } from '@hofy/ui';

import { getAddonConstraintsByTarget, getSanitizedAddonConstraintValues } from './types/AddonConstraint';
import {
    getAddonDataDependenciesByTarget,
    getSanitizedAddonDataDependencyValues,
} from './types/AddonDataDependency';
import { AddonForm } from './types/AddonForm';

interface UseAddonFormOptions {
    initial: AddonForm;
    onSubmit(payload: AddonPayload): Promise<void>;
    onSuccess(): void;
}

export const useAddonForm = ({ initial, onSubmit, onSuccess }: UseAddonFormOptions) => {
    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: onSubmit,
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [addonsCacheKey] });
            onSuccess();
        },
    });

    const form = useForm<AddonForm>({
        initial,
        onSubmit: formValues => {
            const { pricingDependencies, discountDependencies, constraintDependencies, ...payload } =
                formValues;

            payload.constraints = payload.constraints.map(constraint => ({
                ...constraint,
                ...getSanitizedAddonConstraintValues(payload.target, constraint, constraintDependencies),
            }));
            payload.pricings = payload.pricings.map(pricing => ({
                ...pricing,
                ...getSanitizedAddonDataDependencyValues(payload.target, pricing, pricingDependencies),
            }));
            payload.discounts = payload.discounts.map(discount => ({
                ...discount,
                ...getSanitizedAddonDataDependencyValues(payload.target, discount, discountDependencies),
            }));
            mutation.mutate(payload);
        },
        validate: validator<AddonForm>({
            name: isRequired('Name cannot be empty'),
            pricings: validateArrayField<AddonForm, 'pricings'>({
                fieldsValidator: validator<AddonDataDependenciesDto>(addonDependenciesValidators),
            }),
            discounts: validateArrayField<AddonForm, 'discounts'>({
                fieldsValidator: validator<AddonDataDependenciesDto>(addonDependenciesValidators),
            }),
            pools: validateArrayField<AddonForm, 'pools'>({
                fieldsValidator: validator<AddonForm['pools'][0]>({
                    name: isRequired('Pool name cannot be empty'),
                    services: minLength('At least one service is required', 1),
                    quantities: validateArrayField<AddonForm['pools'][0], 'quantities'>({
                        selfRules: isRequired('At least one quantity is required'),
                        fieldsValidator: validator<AddonDataDependenciesDto>(addonDependenciesValidators),
                    }),
                    multipliers: validateArrayField<AddonForm['pools'][0], 'multipliers'>({
                        selfRules: isRequired('At least one multiplier is required'),
                        fieldsValidator: validator<AddonDataDependenciesDto>(addonDependenciesValidators),
                    }),
                }),
            }),
        }),
    });

    useEffect(() => {
        form.setValues({
            constraintDependencies: form.values.constraintDependencies.filter(constraint =>
                getAddonConstraintsByTarget(form.values.target).includes(constraint),
            ),
            pricingDependencies: form.values.pricingDependencies.filter(dependency =>
                getAddonDataDependenciesByTarget(form.values.target).includes(dependency),
            ),
            discountDependencies: form.values.discountDependencies.filter(dependency =>
                getAddonDataDependenciesByTarget(form.values.target).includes(dependency),
            ),
        });
    }, [form.values.target]);

    return {
        form,
        isLoading: mutation.isPending,
        isError: mutation.isError,
    };
};

const addonDependenciesValidators: Validators<AddonDataDependenciesDto> = {
    durations: [
        is(
            v => !v || ((!isNumber(v?.from) || v.from >= 0) && (!isNumber(v?.to) || v.to >= 0)),
            'Must be a positive number',
        ),
        is(v => !v || !isNumber(v?.from) || !isNumber(v?.to) || v.from <= v.to, 'Invalid range'),
    ],
    assetValue: [
        is(
            v =>
                !v ||
                ((!isDecimal(v?.from) || decimalToNumber(v.from) >= 0) &&
                    (!isDecimal(v?.to) || decimalToNumber(v.to) >= 0)),
            'Must be a positive price',
        ),
        is(
            v =>
                !v ||
                !isDecimal(v?.from) ||
                !isDecimal(v?.to) ||
                decimalToNumber(v.from) <= decimalToNumber(v.to),
            'Invalid range',
        ),
    ],
};
