import { intersection, toPairs } from 'lodash-es';

import { getEnumValues } from '@hofy/global';

import { Permission } from './Permission';

export enum Role {
    TeamMember = 'team_member',

    Manager = 'manager',
    AccessManager = 'access_manager',
    FinancialManager = 'financial_manager',
    PiiManager = 'pii_manager',
    ReadOnlyManager = 'read_only_manager',

    OrganizationTeleporterAdmin = 'organization_teleporter_admin',
    ReadOnlyOrganizationTeleporterAdmin = 'read_only_organization_teleporter_admin',
    SuperAdmin = 'super_admin',
    ReadOnlyAdmin = 'read_only_admin',
    PermissionAdmin = 'permission_admin',
    UserAdmin = 'user_admin',
    FinanceDirectorAdmin = 'finance_director_admin',
    FinancialAdmin = 'financial_admin',
    FulfillmentAdmin = 'fulfillment_admin',
    ProcurementAdmin = 'procurement_admin',
    ProcurementApproveAdmin = 'procurement_approve_admin',
    FinancialApproveAdmin = 'financial_approve_admin',
    CatalogueAdmin = 'catalogue_admin',
    OrganizationAdmin = 'organization_admin',
    SalesAdmin = 'sales_admin',
    SupportAdmin = 'support_admin',
    SupportPlusAdmin = 'support_plus_admin',
    SuccessAdmin = 'success_admin',
    ITAdmin = 'it_admin',
}

export const allRoles = getEnumValues<Role>(Role);
export const allManagerRoles = [
    Role.Manager,
    Role.PiiManager,
    Role.AccessManager,
    Role.FinancialManager,
    Role.ReadOnlyManager,
];
export const allStorefrontRoles = [Role.TeamMember, ...allManagerRoles];
export const allAdminRoles = allRoles.filter(role => !allStorefrontRoles.includes(role));

export const hasAnyManagerRole = (roles: Role[]): boolean => {
    return roles.some(role => allManagerRoles.includes(role));
};

export const isFinanceManagerOnly = (roles: Role[]): boolean => {
    return roles.length === 1 && roles[0] === Role.FinancialManager;
};

export const hasRole = (roles: Role[], r: Role): boolean => {
    return roles.includes(r);
};

export const roleRequiredForPermission = (
    roles: Partial<Record<Role, Permission[]>>,
    permission: Permission[],
    currentRoles: Role[],
): Role[] => {
    const hasCommonPermission = (p1: Permission[], p2: Permission[], currentRoles: Role[], r: Role) =>
        intersection(p1, p2).length > 0 && !currentRoles.includes(r);

    return toPairs(roles)
        .filter(v => hasCommonPermission(v[1], permission, currentRoles, v[0] as Role))
        .map(v => v[0] as Role);
};

export const hasPermission = (permissions: Permission[], r: Permission): boolean => {
    return permissions.includes(r);
};

export const hasAnyPermission = (
    sessionPermissions: Permission[],
    requiredPermissions: Permission[],
): boolean => {
    return requiredPermissions.some(p => hasPermission(sessionPermissions, p));
};

export const isManager = (roles: Role[]): boolean => {
    return allManagerRoles.some(role => hasRole(roles, role));
};
