import { isNil } from 'lodash-es';
import styled, { CSSObject } from 'styled-components';

import { OffsetValue } from '@hofy/theme';

import { InnerBox, InnerBoxProps } from './InnerBox';
import { AlignItems, JustifyContent } from './types';

const directions = {
    centered: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },

    rowReverse: {
        display: 'flex',
        flexDirection: 'row-reverse',
        alignItems: 'center',
    },

    column: {
        display: 'flex',
        flexDirection: 'column',
    },

    columnReverse: {
        display: 'flex',
        flexDirection: 'column-reverse',
    },
};

type Direction = keyof typeof directions;

interface DirectionProps extends Partial<Record<Direction, boolean>> {
    direction?: Direction;
}

export interface LayoutBoxProps extends DirectionProps, InnerBoxProps {
    justify?: JustifyContent;
    alignItems?: AlignItems;
    alignContent?: AlignItems;
    gap?: OffsetValue;
    rowGap?: OffsetValue;
    columnGap?: OffsetValue;
    inline?: boolean;

    wrap?: boolean;
}

export const LayoutBox = styled(InnerBox)<LayoutBoxProps>(({
    flex,
    shrink,

    justify,
    alignItems,
    alignContent,
    alignSelf,
    gap,
    rowGap,
    columnGap,
    inline,

    direction,

    wrap,
    ...rest
}) => {
    const styles: CSSObject = {};

    if (!isNil(flex)) {
        if (flex === 'auto') {
            styles.flex = 1;
            styles.minWidth = 0;
            styles.minHeight = 0;
        } else {
            styles.flex = flex.toString();
        }
    }

    if (!isNil(shrink)) {
        styles.flexShrink = shrink;
    }

    const directionAliases = findDirectionAliases(rest);

    if (direction && directionAliases.length > 0) {
        throw new Error(`You can't use both 'direction' and '${directionAliases}' props`);
    }

    if (direction) {
        Object.assign(styles, directions[direction]);
    }

    if (directionAliases) {
        directionAliases.forEach(alias => {
            Object.assign(styles, directions[alias]);
        });
    }

    if (justify) {
        styles.justifyContent = justify;
    }

    if (wrap) {
        styles.flexWrap = 'wrap';
    }

    if (alignItems) {
        styles.display = 'flex';
        styles.alignItems = alignItems;
    }

    if (alignContent) {
        styles.display = 'flex';
        styles.alignItems = alignContent;
    }

    if (!isNil(gap)) {
        styles.display = 'flex';
        styles.gap = gap;
    }

    if (alignSelf) {
        styles.display = 'flex';
        styles.alignSelf = alignSelf;
    }

    if (!isNil(rowGap)) {
        styles.display = 'flex';
        styles.rowGap = rowGap;
    }

    if (!isNil(columnGap)) {
        styles.display = 'flex';
        styles.columnGap = columnGap;
    }

    if (inline) {
        styles.display = 'inline-flex';
    }

    return { ...styles };
});

const findDirectionAliases = (props: Partial<LayoutBoxProps>) => {
    return Object.entries(props)
        .filter(([alias, value]) => alias in directions && value === true)
        .map(([alias]) => alias as Direction);
};
