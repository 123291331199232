import { omit } from 'lodash-es';

import { forcedNetsuiteSyncQuery, InvoiceStatus, NetsuiteSyncStatus } from '@hofy/api-shared';
import { DateRangeStrings, PageRequest, PageResponse, stringifyUrl, UUID } from '@hofy/global';
import { downloadFileFromResponse } from '@hofy/helpers';
import { restClient } from '@hofy/rest';

import { GenerateInvoiceQuery } from './types/GenerateInvoiceQuery';
import { InvoiceDetailsDto } from './types/InvoiceDetailsDto';
import { InvoiceDto } from './types/InvoiceDto';
import { InvoicingEntityFilter, invoicingEntityFilterToIsTwo } from './types/InvoicingEntityFilter';
import { MonthQuery } from './types/MonthQuery';
import { PaymentStatusFilter } from './types/PaymentStatusFilter';
import { SyncFileFromTwoQuery } from './types/SyncFileFromTwoQuery';
import { UploadInvoicePayload } from './types/UploadInvoicePayload';

export interface AdminInvoiceFilters {
    search: string;
    organization: UUID[];
    billingEntity: UUID[];
    netsuiteStatus: NetsuiteSyncStatus[];
    invoiceStatus: InvoiceStatus[];
    paymentStatus: PaymentStatusFilter[];
    invoicingEntity: InvoicingEntityFilter | null;
    invoiceDate: DateRangeStrings | null;
}

export const emptyAdminInvoiceFilters: AdminInvoiceFilters = {
    search: '',
    organization: [],
    billingEntity: [],
    netsuiteStatus: [],
    invoiceStatus: [],
    paymentStatus: [],
    invoicingEntity: null,
    invoiceDate: null,
};

class InvoiceService {
    public listInvoices = async (
        { invoiceDate, ...filters }: AdminInvoiceFilters,
        page: PageRequest,
    ): Promise<PageResponse<InvoiceDto>> => {
        return restClient.getJson<PageResponse<InvoiceDto>>(
            stringifyUrl({
                url: '/api/admin/invoices',
                query: {
                    ...omit(filters, 'invoicingEntity'),
                    isTwo: invoicingEntityFilterToIsTwo(filters.invoicingEntity),
                    invoiceDateFrom: invoiceDate?.from || null,
                    invoiceDateTo: invoiceDate?.to || null,
                    page: page.page,
                    pageSize: page.pageSize,
                },
            }),
        );
    };

    public getInvoice = async (id: UUID): Promise<InvoiceDetailsDto> => {
        return restClient.getJson<InvoiceDetailsDto>(`/api/admin/invoices/${id}`);
    };

    public downloadInvoiceFile = async (fileId: UUID): Promise<void> => {
        await restClient
            .downloadFile(`/api/admin/invoices/files/${fileId}/download`)
            .then(downloadFileFromResponse);
    };

    public deleteInvoiceFile = async (fileId: UUID): Promise<void> => {
        await restClient.delete(`/api/admin/invoices/files/${fileId}`).then();
    };

    public uploadInvoice = async (invoiceId: UUID, payload: UploadInvoicePayload): Promise<number> => {
        return await restClient
            .postJson<{
                id: number;
            }>(`/api/admin/invoices/${invoiceId}/upload`, payload)
            .then(r => r.id);
    };

    public syncToNetsuite = async (invoiceId: UUID) => {
        return restClient.post(`/api/admin/invoices/${invoiceId}/sync-to-netsuite`);
    };

    public syncAllToNetsuite = async () => {
        return restClient.post(`/api/admin/invoices/sync-to-netsuite`);
    };

    public syncTwoPayment = async (invoiceId: UUID) => {
        return restClient.post(`/api/admin/invoices/${invoiceId}/sync-two-payment`);
    };

    public syncBillingEntityTwoPayments = async (billingEntityId: UUID) => {
        return restClient.post(`/api/admin/invoices/billing-entities/${billingEntityId}/sync-two-payments`);
    };

    public sendAllInvoicesByEmail = async ({ ...query }: MonthQuery) => {
        return restClient.post(
            stringifyUrl({
                url: `/api/admin/invoices/send`,
                query,
            }),
        );
    };

    public sendInvoiceByEmail = async (invoiceId: UUID, contactIds: UUID[]) => {
        return restClient.post(`/api/admin/invoices/${invoiceId}/send`, { contactIds });
    };

    public excludeFromDunning = async (invoiceId: UUID, reason: string) => {
        return restClient.post(`/api/admin/invoices/${invoiceId}/dunning/exclude`, {
            reason,
        });
    };

    public includeInDunning = async (invoiceId: UUID) => {
        return restClient.post(`/api/admin/invoices/${invoiceId}/dunning/include`);
    };

    public generateInvoices = async (query?: GenerateInvoiceQuery) => {
        return restClient.post(
            stringifyUrl({
                url: `/api/admin/invoices/generate`,
                query: {
                    ...query,
                },
            }),
        );
    };

    public generateRevenueRecognition = async ({ ...query }: MonthQuery) => {
        return restClient.post(
            stringifyUrl({
                url: `/api/admin/invoices/generate-revenue-recognition`,
                query,
            }),
        );
    };

    public syncFilesFromTwo = async (query: SyncFileFromTwoQuery) => {
        return restClient.post(
            stringifyUrl({
                url: `/api/admin/invoices/sync-files-from-two`,
                query: {
                    ...query,
                },
            }),
        );
    };

    public syncFromNetsuite = () => {
        return restClient.post(
            stringifyUrl({
                url: `/api/admin/invoices/sync-from-netsuite`,
                query: {
                    ...forcedNetsuiteSyncQuery,
                },
            }),
        );
    };
}

export const invoicesService = new InvoiceService();
