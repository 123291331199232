import { isNil } from 'lodash-es';
import React, { FC } from 'react';

import { Color } from '@hofy/theme';

import { TestKeyAware } from '../../types';
import { Paragraph3 } from '../base';
import { Labeled, LabeledProps } from './Labeled';

export interface LabeledTextProps extends LabeledProps, TestKeyAware {
    errorMessage?: string;
}

export const LabeledText: FC<LabeledTextProps> = ({ content, invertedColor, ...rest }) => {
    const color = invertedColor ? Color.ContentInvertedPrimary : Color.ContentPrimary;

    return (
        <Labeled
            content={
                <Paragraph3 color={color} data-test-key='content'>
                    {isNil(content) || content === '' ? '--' : content}
                </Paragraph3>
            }
            invertedColor={invertedColor}
            {...rest}
        />
    );
};
