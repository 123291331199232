import { first, isEqual } from 'lodash-es';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';

import { DateRangeStrings, DateString, FromToDateStrings } from '@hofy/global';
import {
    allDateRangeTypes,
    DateRangeName,
    dateRangeNameToRange,
    dateRangeToRangeType,
    DateRangeType,
    dateStringToJsDate,
    DateType,
    jsDateToLuxonDate,
    luxonToJsDate,
    optionalDateStringToJsDate,
    parseDateTime,
    sortDateRange,
    toISODate,
} from '@hofy/helpers';
import { useTrDateRangeName, useTrDateRangeType } from '@hofy/i18n';
import { Color } from '@hofy/theme';

import { Box, Paragraph3, Pressable } from '../base';
import { PillTab, PillTabs } from '../tabs';
import { DatePickerContent, DatePickerContentRange } from './base/DatePickerContent';
import { DatepickerHeader } from './base/DatepickerHeader';
import { numberOfWeeksInMonth } from './lib/numberOfWeeksInMonth';

export interface InlineDatepickerRangeProps {
    value: DateRangeStrings | null;
    /** On every change, eg selecting range types */
    onChange(range: DateRangeStrings): void;
    /** On final selection, eg selecting second date */
    onEnd?(): void;
    type?: DateType;
    allowedRangeTypes?: DateRangeType[];

    predefinedRanges?: DateRangeName[];

    minDate?: DateString;
    maxDate?: DateString;
    highlightDates?: DateString[];
    openToDate?: DateString | null;
    disabled?: boolean;

    filterDate?(date: DateString): boolean;
}

interface OptionalDateRangeStrings {
    from: DateString | null;
    to: DateString | null;
}

const emptyDateRange: OptionalDateRangeStrings = {
    from: null,
    to: null,
};

export const InlineDatepickerRange: FC<InlineDatepickerRangeProps> = ({
    value,
    onChange,
    onEnd,
    type = 'day',
    allowedRangeTypes = allDateRangeTypes,
    predefinedRanges,
    minDate,
    maxDate,
    highlightDates,
    disabled,
    filterDate,
    openToDate,
}) => {
    const trRange = useTrDateRangeName();
    const trRangeType = useTrDateRangeType();

    if (!allowedRangeTypes.length) {
        throw new Error('[InlineDatepickerRange] No allowed range types');
    }

    const [rangeType, setRangeType] = useState<DateRangeType>(
        value ? dateRangeToRangeType(value) : first(allowedRangeTypes)!,
    );

    const lastLocalRef = useRef<OptionalDateRangeStrings>(value || emptyDateRange);
    const [local, setLocal] = useState<OptionalDateRangeStrings>({
        from: value?.from ?? null,
        to: value?.to ?? null,
    });

    const shouldPeekNextMonth = (jsDate: Date | null) => {
        const weeks = numberOfWeeksInMonth(jsDateToLuxonDate(jsDate ?? new Date()));
        return weeks < 6;
    };

    const selectedJsDate = optionalDateStringToJsDate(local.from ?? local.to ?? null);
    const [peekNextMonth, setPeekNextMonth] = useState(() => shouldPeekNextMonth(selectedJsDate));

    useEffect(() => {
        triggerChangeIfValid(local);
    }, [local, rangeType, value]);

    const triggerChangeIfValid = (range: OptionalDateRangeStrings) => {
        if (isEqual(value, range)) {
            return;
        }
        if (rangeType === DateRangeType.FromToDate && range.from && range.to) {
            onChange(range as FromToDateStrings);
        } else if (rangeType === DateRangeType.FromDate && range.from) {
            onChange({
                from: range.from,
                to: null,
            });
        } else if (rangeType === DateRangeType.ToDate && range.to) {
            onChange({
                from: null,
                to: range.to,
            });
        }
    };

    const setLocalAndSaveLast = (range: OptionalDateRangeStrings) => {
        setLocal(
            sortDateRange({
                from: range.from ?? null,
                to: range.to ?? null,
            } as DateRangeStrings),
        );
        lastLocalRef.current = sortDateRange({
            from: range.from ?? lastLocalRef.current.from,
            to: range.to ?? lastLocalRef.current.to,
        } as DateRangeStrings);
    };

    const changeRangeType = (type: DateRangeType): void => {
        setRangeType(type);
        let last = lastLocalRef.current;
        if (type === DateRangeType.FromToDate && last.from && last.to) {
            last = {
                from: last.from,
                to: last.to,
            };
        } else if (type === DateRangeType.FromDate && last.from) {
            last = {
                from: last.from,
                to: null,
            };
        } else if (type === DateRangeType.ToDate && last.to) {
            last = {
                from: null,
                to: last.to,
            };
        } else {
            last = {
                from: null,
                to: null,
            };
        }
        setLocal(last);
        triggerChangeIfValid(last);
    };

    const handleRangeChange = (range: [Date | null, Date | null]) => {
        const from = range[0] ? toISODate(jsDateToLuxonDate(range[0])) : null;
        const to = range[1] ? toISODate(jsDateToLuxonDate(range[1])) : null;
        const newRange = {
            from,
            to,
        };
        setLocalAndSaveLast(newRange);
        triggerChangeIfValid(newRange);
        if (from && to) {
            onEnd?.();
        }
    };

    const handleFromOrToChange = (jsDate: Date | null) => {
        const date = jsDate ? toISODate(jsDateToLuxonDate(jsDate)) : null;
        const newRange =
            rangeType === DateRangeType.FromDate
                ? {
                      from: date,
                      to: local.to,
                  }
                : {
                      from: local.from,
                      to: date,
                  };
        setLocalAndSaveLast(newRange);
        triggerChangeIfValid(newRange);
        onEnd?.();
    };

    const hasLocal = local.from !== null && local.to !== null;
    const currentDates = hasLocal && value ? value : local;
    const currFrom = currentDates.from ?? null;
    const currTo = currentDates.to ?? null;

    const getDatePicker = () => {
        switch (rangeType) {
            case DateRangeType.FromToDate:
                return (
                    <DatePickerContentRange
                        selectsRange
                        startDate={optionalDateStringToJsDate(currFrom)}
                        endDate={optionalDateStringToJsDate(currTo)}
                        minDate={optionalDateStringToJsDate(minDate)}
                        maxDate={optionalDateStringToJsDate(maxDate)}
                        highlightDates={highlightDates?.map(date => luxonToJsDate(parseDateTime(date)))}
                        onChange={handleRangeChange}
                        filterDate={
                            filterDate
                                ? jsDate => filterDate(toISODate(jsDateToLuxonDate(jsDate)))
                                : undefined
                        }
                        renderCustomHeader={props => <DatepickerHeader showMonths {...props} />}
                        showMonthYearPicker={type === 'month'}
                        disabled={disabled}
                        openToDate={optionalDateStringToJsDate(openToDate) || undefined}
                        onMonthChange={jsDate => setPeekNextMonth(shouldPeekNextMonth(jsDate))}
                        peekNextMonth={peekNextMonth}
                        inline
                    />
                );

            case DateRangeType.FromDate:
            case DateRangeType.ToDate: {
                const singeDate = rangeType === DateRangeType.FromDate ? currFrom : currTo;
                return (
                    <DatePickerContent
                        selected={optionalDateStringToJsDate(singeDate)}
                        minDate={optionalDateStringToJsDate(minDate)}
                        maxDate={optionalDateStringToJsDate(maxDate)}
                        highlightDates={highlightDates?.map(date => luxonToJsDate(parseDateTime(date)))}
                        onChange={handleFromOrToChange}
                        filterDate={
                            filterDate
                                ? jsDate => filterDate(toISODate(jsDateToLuxonDate(jsDate)))
                                : undefined
                        }
                        dayClassName={jsDate => {
                            if (rangeType === DateRangeType.FromDate && currFrom) {
                                const jsCurrDate = dateStringToJsDate(currFrom);
                                if (jsDate.getTime() === jsCurrDate.getTime()) {
                                    return 'custom--in-range custom--range-start';
                                }
                                return jsDate >= jsCurrDate ? 'custom--in-range' : null;
                            }
                            if (rangeType === DateRangeType.ToDate && currTo) {
                                const jsCurrDate = dateStringToJsDate(currTo);
                                if (jsDate.getTime() === jsCurrDate.getTime()) {
                                    return 'custom--in-range custom--range-end';
                                }
                                return jsDate <= jsCurrDate ? 'custom--in-range' : null;
                            }
                            return null;
                        }}
                        renderCustomHeader={props => (
                            <DatepickerHeader showMonths={type !== 'month'} {...props} />
                        )}
                        disabled={disabled}
                        showMonthYearPicker={type === 'month'}
                        onMonthChange={jsDate => setPeekNextMonth(shouldPeekNextMonth(jsDate))}
                        peekNextMonth={peekNextMonth}
                        inline
                    />
                );
            }
        }
    };

    const rangeTypeTabs =
        allowedRangeTypes.length > 1 ? (
            <PillTabs active={rangeType} onChange={changeRangeType}>
                {allowedRangeTypes.map(type => (
                    <PillTab key={type} id={type} label={trRangeType(type)} />
                ))}
            </PillTabs>
        ) : null;

    const predefinedRangesWithValue = useMemo(() => {
        return predefinedRanges?.map(range => {
            const value = dateRangeNameToRange(range);
            return [range, value] as const;
        });
    }, [predefinedRanges]);

    if (predefinedRangesWithValue) {
        return (
            <Box row alignItems='stretch'>
                <Box borderRight paddingRight={16} marginRight={24} column gap={2}>
                    {predefinedRangesWithValue.map(([range, { from, to }]) => (
                        <RangeButton
                            key={range}
                            label={trRange(range)}
                            onClick={() =>
                                setLocalAndSaveLast({
                                    from,
                                    to,
                                })
                            }
                            active={from === currFrom && to === currTo}
                        />
                    ))}
                </Box>
                <Box column gap={8}>
                    {rangeTypeTabs}
                    {getDatePicker()}
                </Box>
            </Box>
        );
    }

    return (
        <Box column gap={8}>
            {rangeTypeTabs}
            {getDatePicker()}
        </Box>
    );
};

interface RangeButtonProps {
    active: boolean;
    onClick(): void;
    label: string;
}

export const RangeButton: FC<RangeButtonProps> = ({ active, onClick, label }) => {
    return (
        <Pressable
            onClick={onClick}
            paddingVertical={8}
            paddingHorizontal={16}
            hoverBg={Color.InteractionNeutralSubtleHover}
            rounded
            minWidth={130}
        >
            <Paragraph3
                color={active ? Color.ContentBrand : Color.ContentPrimary}
                weight={active ? 'bold' : 'normal'}
                lineHeight='large'
            >
                {label}
            </Paragraph3>
        </Pressable>
    );
};
