import { difference, sortBy } from 'lodash-es';

import { countryI18n } from '../i18n/countryI18n';
import { getEnumValues } from './Enum';

export enum Country {
    Andorra = 'AD',
    UnitedArabEmirates = 'AE',
    Afghanistan = 'AF',
    AntiguaAndBarbuda = 'AG',
    Anguilla = 'AI',
    Albania = 'AL',
    Armenia = 'AM',
    NetherlandsAntilles = 'AN',
    Angola = 'AO',
    Antarctica = 'AQ',
    Argentina = 'AR',
    AmericanSamoa = 'AS',
    Austria = 'AT',
    Australia = 'AU',
    Aruba = 'AW',
    AlandIslands = 'AX',
    Azerbaijan = 'AZ',
    BosniaAndHerzegovina = 'BA',
    Barbados = 'BB',
    Bangladesh = 'BD',
    Belgium = 'BE',
    BurkinaFaso = 'BF',
    Bulgaria = 'BG',
    Bahrain = 'BH',
    Burundi = 'BI',
    Benin = 'BJ',
    SaintBarthelemy = 'BL',
    Bermuda = 'BM',
    BruneiDarussalam = 'BN',
    Bolivia = 'BO',
    Bonaire = 'BQ',
    Brazil = 'BR',
    Bahamas = 'BS',
    Bhutan = 'BT',
    BouvetIsland = 'BV',
    Botswana = 'BW',
    Belarus = 'BY',
    Belize = 'BZ',
    Canada = 'CA',
    CocosIslands = 'CC',
    DemocraticRepublicOfTheCongo = 'CD',
    CentralAfricanRepublic = 'CF',
    RepublicOfTheCongo = 'CG',
    Switzerland = 'CH',
    CoteDivoire = 'CI',
    CookIslands = 'CK',
    Chile = 'CL',
    Cameroon = 'CM',
    China = 'CN',
    Colombia = 'CO',
    CostaRica = 'CR',
    Cuba = 'CU',
    CaboVerde = 'CV',
    Curacao = 'CW',
    ChristmasIsland = 'CX',
    Cyprus = 'CY',
    CzechRepublic = 'CZ',
    Germany = 'DE',
    Djibouti = 'DJ',
    Denmark = 'DK',
    Dominica = 'DM',
    DominicanRepublic = 'DO',
    Algeria = 'DZ',
    Ecuador = 'EC',
    Estonia = 'EE',
    Egypt = 'EG',
    WesternSahara = 'EH',
    Eritrea = 'ER',
    Spain = 'ES',
    Ethiopia = 'ET',
    Finland = 'FI',
    Fiji = 'FJ',
    FalklandIslands = 'FK',
    FederatedStatesOfMicronesia = 'FM',
    FaroeIslands = 'FO',
    France = 'FR',
    Gabon = 'GA',
    UnitedKingdom = 'GB',
    Grenada = 'GD',
    Georgia = 'GE',
    FrenchGuiana = 'GF',
    Guernsey = 'GG',
    Ghana = 'GH',
    Gibraltar = 'GI',
    Greenland = 'GL',
    Gambia = 'GM',
    Guinea = 'GN',
    Guadeloupe = 'GP',
    EquatorialGuinea = 'GQ',
    Greece = 'GR',
    SouthGeorgiaAndSouthSandwichIslands = 'GS',
    Guatemala = 'GT',
    Guam = 'GU',
    GuineaBissau = 'GW',
    Guyana = 'GY',
    HongKong = 'HK',
    HeardAndMcDonaldIslands = 'HM',
    Honduras = 'HN',
    Croatia = 'HR',
    Haiti = 'HT',
    Hungary = 'HU',
    CanaryIslands = 'IC',
    Indonesia = 'ID',
    Ireland = 'IE',
    Israel = 'IL',
    IsleOfMan = 'IM',
    India = 'IN',
    BritishIndianOceanTerritory = 'IO',
    Iraq = 'IQ',
    Iran = 'IR',
    Iceland = 'IS',
    Italy = 'IT',
    Jersey = 'JE',
    Jamaica = 'JM',
    Jordan = 'JO',
    Japan = 'JP',
    Kenya = 'KE',
    Kyrgyzstan = 'KG',
    Cambodia = 'KH',
    Kiribati = 'KI',
    Comoros = 'KM',
    SaintKittsAndNevis = 'KN',
    NorthKorea = 'KP',
    SouthKorea = 'KR',
    Kosovo = 'XK',
    Kuwait = 'KW',
    CaymanIslands = 'KY',
    Kazakhstan = 'KZ',
    Laos = 'LA',
    Lebanon = 'LB',
    SaintLucia = 'LC',
    Liechtenstein = 'LI',
    SriLanka = 'LK',
    Liberia = 'LR',
    Lesotho = 'LS',
    Lithuania = 'LT',
    Luxembourg = 'LU',
    Latvia = 'LV',
    Libya = 'LY',
    Morocco = 'MA',
    Monaco = 'MC',
    Moldova = 'MD',
    Montenegro = 'ME',
    SaintMartin = 'MF',
    Madagascar = 'MG',
    MarshallIslands = 'MH',
    NorthMacedonia = 'MK',
    Mali = 'ML',
    Myanmar = 'MM',
    Mongolia = 'MN',
    Macau = 'MO',
    NorthernMarianaIslands = 'MP',
    Martinique = 'MQ',
    Mauritania = 'MR',
    Montserrat = 'MS',
    Malta = 'MT',
    Mauritius = 'MU',
    Maldives = 'MV',
    Malawi = 'MW',
    Mexico = 'MX',
    Malaysia = 'MY',
    Mozambique = 'MZ',
    Namibia = 'NA',
    NewCaledonia = 'NC',
    Niger = 'NE',
    NorfolkIsland = 'NF',
    Nigeria = 'NG',
    Nicaragua = 'NI',
    Netherlands = 'NL',
    Norway = 'NO',
    Nepal = 'NP',
    Nauru = 'NR',
    Niue = 'NU',
    NewZealand = 'NZ',
    Oman = 'OM',
    Panama = 'PA',
    Peru = 'PE',
    FrenchPolynesia = 'PF',
    PapuaNewGuinea = 'PG',
    Philippines = 'PH',
    Pakistan = 'PK',
    Poland = 'PL',
    SaintPierreAndMiquelon = 'PM',
    PitcairnIslands = 'PN',
    PuertoRico = 'PR',
    StateOfPalestine = 'PS',
    Portugal = 'PT',
    Palau = 'PW',
    Paraguay = 'PY',
    Qatar = 'QA',
    Reunion = 'RE',
    Romania = 'RO',
    Serbia = 'RS',
    Russia = 'RU',
    Rwanda = 'RW',
    SaudiArabia = 'SA',
    SolomonIslands = 'SB',
    Seychelles = 'SC',
    Sudan = 'SD',
    Sweden = 'SE',
    Singapore = 'SG',
    SaintHelenaAscensionAndTristanDaCunha = 'SH',
    Slovenia = 'SI',
    SvalbardAndJanMayen = 'SJ',
    Slovakia = 'SK',
    SierraLeone = 'SL',
    SanMarino = 'SM',
    Senegal = 'SN',
    Somalia = 'SO',
    Suriname = 'SR',
    SouthSudan = 'SS',
    SaoTomeAndPrincipe = 'ST',
    ElSalvador = 'SV',
    SintMaarten = 'SX',
    SyrianArabRepublic = 'SY',
    Swaziland = 'SZ',
    TurksAndCaicosIslands = 'TC',
    Chad = 'TD',
    FrenchSouthernTerritories = 'TF',
    Togo = 'TG',
    Thailand = 'TH',
    Tajikistan = 'TJ',
    Tokelau = 'TK',
    TimorLeste = 'TL',
    Turkmenistan = 'TM',
    Tunisia = 'TN',
    Tonga = 'TO',
    Turkey = 'TR',
    TrinidadAndTobago = 'TT',
    Tuvalu = 'TV',
    Taiwan = 'TW',
    Tanzania = 'TZ',
    Ukraine = 'UA',
    Uganda = 'UG',
    UnitedStates = 'US',
    Uruguay = 'UY',
    Uzbekistan = 'UZ',
    HolySee = 'VA',
    SaintVincentAndTheGrenadines = 'VC',
    Venezuela = 'VE',
    VirginIslandsBritish = 'VG',
    VirginIslandsUs = 'VI',
    Vietnam = 'VN',
    Vanuatu = 'VU',
    WallisAndFutuna = 'WF',
    Samoa = 'WS',
    Yemen = 'YE',
    Mayotte = 'YT',
    SouthAfrica = 'ZA',
    Zambia = 'ZM',
    Zimbabwe = 'ZW',
}

export enum CustomsRegion {
    CustomsRegionEU = 'EU',
    CustomsRegionUK = 'UK',
}

export const sortCountries = (p: Country[]) => sortBy(p, v => countryI18n[`country.${v}`]);
export const allCountries = getEnumValues<Country>(Country);
export const allCountriesSorted = sortCountries(allCountries);

export const validateCountry = (country: any): boolean => {
    return allCountries.includes(country);
};

const taxIdCountries = sortCountries([
    Country.Argentina,
    Country.Brazil,
    Country.Israel,
    Country.Peru,
    Country.Pakistan,
    Country.Turkey,
    Country.Colombia,
    Country.Chile,
    Country.Paraguay,
    Country.CostaRica,
    Country.ElSalvador,
    Country.DominicanRepublic,
    Country.Honduras,
    Country.Guyana,
    Country.Bolivia,
    Country.Ecuador,
    Country.Uruguay,
    Country.Venezuela,
    Country.SouthAfrica,
    Country.India,
    Country.Georgia,
    Country.Kazakhstan,
    Country.Suriname,
    Country.SriLanka,
    Country.China,
    Country.Panama,
    Country.Nicaragua,
    Country.Guatemala,
    Country.Indonesia,
    Country.Moldova,
]);

const passportAndDateOfBirthCountries = [Country.SouthKorea];

export const isCountry = (country?: string): country is Country => {
    return country ? allCountries.includes(country as Country) : false;
};

export const isTaxIdCountry = (country?: Country) => (country ? taxIdCountries.includes(country) : false);

export const isPassportAndDateOfBirthNeeded = (country?: Country) =>
    country ? passportAndDateOfBirthCountries.includes(country) : false;

export const isUSA = (country?: Country) => country === Country.UnitedStates;

export const isStateRequired = (country?: Country): boolean => {
    switch (country) {
        case Country.UnitedStates:
        case Country.India:
        case Country.Brazil:
            return true;
        default:
            return false;
    }
};

export const isValidRegCompanyNumber = (registeredCompanyNumber: string | null, country: Country) => {
    if (!registeredCompanyNumber) {
        return false;
    }

    if (registeredCompanyNumber && isUSA(country)) {
        return /^\d{2}-\d{7}$/.test(registeredCompanyNumber);
    }

    if (registeredCompanyNumber && country === Country.UnitedKingdom) {
        return /^\d{8}$/.test(registeredCompanyNumber);
    }

    return true;
};

export const countriesIncludes = (all: Country[], countries: Country[]) => {
    return difference(countries, all).length === 0;
};

export const expressDeliverySupportedCountries = sortCountries([
    Country.UnitedStates,
    Country.Austria,
    Country.Belgium,
    Country.Bulgaria,
    Country.CzechRepublic,
    Country.Germany,
    Country.Denmark,
    Country.Estonia,
    Country.Spain,
    Country.Finland,
    Country.France,
    Country.Greece,
    Country.Croatia,
    Country.Hungary,
    Country.Ireland,
    Country.Italy,
    Country.Lithuania,
    Country.Luxembourg,
    Country.Latvia,
    Country.Malta,
    Country.Netherlands,
    Country.Poland,
    Country.Portugal,
    Country.Romania,
    Country.Sweden,
    Country.Slovenia,
    Country.Slovakia,
    Country.UnitedKingdom,
    Country.Cyprus,
    Country.Monaco,
    Country.Norway,
    Country.Switzerland,
    Country.Reunion,
    Country.CanaryIslands,
    Country.IsleOfMan,
    Country.Andorra,
    Country.Gibraltar,
    Country.Guernsey,
    Country.Iceland,
    Country.Jersey,
    Country.Liechtenstein,
    Country.SanMarino,
]);

const organizationSignupRestrictedCountries = sortCountries([
    Country.Afghanistan,
    Country.Ukraine,
    Country.Cuba,
    Country.Iran,
    Country.Libya,
    Country.Myanmar,
    Country.NorthKorea,
    Country.Russia,
    Country.SyrianArabRepublic,
    Country.Venezuela,
]);

const orderRestrictedCountries = sortCountries([
    Country.Afghanistan,
    Country.Cuba,
    Country.Iran,
    Country.Libya,
    Country.Myanmar,
    Country.NorthKorea,
    Country.Russia,
    Country.SyrianArabRepublic,
    Country.Venezuela,
]);

export const organizationSignupSupportedCountries = sortCountries(
    allCountries.filter(country => !organizationSignupRestrictedCountries.includes(country)),
);

export const orderSupportedCountries = allCountries.filter(
    country => !orderRestrictedCountries.includes(country),
);
