import { keyBy } from 'lodash-es';
import React, { forwardRef, ReactElement, Ref, useMemo } from 'react';

import { useHofySubsidiaryRefs } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import {
    LabeledSelectSearch,
    LabeledSelectSearchNullableStringProps,
    LabeledSelectSearchOnlyStringProps,
} from '@hofy/ui';

interface BaseProps {
    placeholder?: string;
}

type LabeledHofySubsidiaryNormalProps = Omit<
    LabeledSelectSearchOnlyStringProps<UUID>,
    'isError' | 'toText' | 'options'
> &
    BaseProps;
type LabeledHofySubsidiaryNullableProps = Omit<
    LabeledSelectSearchNullableStringProps<UUID>,
    'isError' | 'toText' | 'options'
> &
    BaseProps;

export type LabeledHofySubsidiaryProps =
    | LabeledHofySubsidiaryNormalProps
    | LabeledHofySubsidiaryNullableProps;

export const LabeledHofySubsidiarySelect = forwardRef(
    ({ ...rest }: LabeledHofySubsidiaryProps, ref: Ref<HTMLDivElement>) => {
        const { hofySubsidiaries } = useHofySubsidiaryRefs();
        const nameMap = useMemo(() => keyBy(hofySubsidiaries, v => v.id), [hofySubsidiaries]);
        return (
            <LabeledSelectSearch<UUID>
                toText={org => nameMap[org]?.name}
                options={hofySubsidiaries.map(v => v.id)}
                ref={ref}
                {...rest}
            />
        );
    },
) as (props: LabeledHofySubsidiaryProps & { ref?: Ref<HTMLDivElement> }) => ReactElement;
