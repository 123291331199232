import React, { FC, memo } from 'react';

import { ListProductFilters, ProductDto, useListProducts } from '@hofy/api-admin';
import {
    allCategoriesWithOther,
    allParentProductCategories,
    ParentProductCategory,
    Permission,
    ProductCategory,
    useTrParentProductCategory,
    useTrProductCategory,
} from '@hofy/api-shared';
import { Page } from '@hofy/common';
import { allCountriesSorted, allRegions, AnyRegion, Country, UUID } from '@hofy/global';
import { useTrCountry, useTrRegion } from '@hofy/i18n';
import { usePermission } from '@hofy/permission';
import {
    ActiveFilters,
    ActiveFiltersLayout,
    Box,
    Button,
    InfinityScrollTable,
    PageHeader,
    Placeholder,
    PublicIdColumn,
    SearchInput,
    SvgIcon,
    SvgIllustration,
} from '@hofy/ui';

import { BlockFilterButton } from '../../components/design/blockFilters/BlockFilterButton';
import { BlockFilterContainer } from '../../components/design/blockFilters/BlockFilterContainer';
import { BooleanBlockFilter } from '../../components/design/blockFilters/BooleanBlockFilter';
import { EnumMultiBlockFilter } from '../../components/design/blockFilters/EnumMultiBlockFilter';
import { useBlockFilters } from '../../components/design/blockFilters/hooks/useBlockFilters';
import { SearchableEnumMultiBlockFilter } from '../../components/design/blockFilters/SearchableEnumMultiBlockFilter';
import { ProductOverview } from '../../components/domain/products/ProductOverview';
import { useProductFilters } from '../../store/products/useProductFilters';
import { ActiveProductChip } from '../inventoryPage/stockLevels/addItem/ActiveProductChip';
import { InternalProductChip } from '../inventoryPage/stockLevels/addItem/InternalProductChip';
import { ProductsPageMenu } from './components/ProductsPageMenu';

interface ProductsPageProps {
    onOpenProduct(id: UUID): void;
    onAddProduct(): void;
}

export const ProductsPage: FC<ProductsPageProps> = ({ onOpenProduct, onAddProduct }) => {
    const { hasPermission } = usePermission();
    const { filterValues, filters, filterCount, clearFilters } = useProductFilters();

    const trRegion = useTrRegion();
    const trCountry = useTrCountry();
    const trParentProductCategory = useTrParentProductCategory();
    const trProductCategory = useTrProductCategory();

    const canAddProduct = hasPermission(Permission.AdminProductsCreate);

    const { showFilters, toggleShowFilters, filterElRef } = useBlockFilters();

    return (
        <Page>
            <PageHeader
                title='Products'
                rightSlot={
                    <>
                        <SearchInput
                            value={filterValues.search}
                            onChange={filters.search.set}
                            placeholder='Search products…'
                            autoFocus
                        />
                        {canAddProduct && (
                            <Button leftIcon={SvgIcon.Add} label='Add product' onClick={onAddProduct} />
                        )}
                        <BlockFilterButton
                            onClick={toggleShowFilters}
                            isOpened={showFilters}
                            count={filterCount}
                        />
                        <ProductsPageMenu />
                    </>
                }
            />
            <BlockFilterContainer show={showFilters} ref={filterElRef}>
                <SearchableEnumMultiBlockFilter<ProductCategory>
                    title='Category'
                    selected={filterValues.categories}
                    onChange={filters.categories.set}
                    items={allCategoriesWithOther}
                    renderItem={trProductCategory}
                    searchPlaceholder='Search categories'
                    toText={key => trProductCategory(key) ?? ''}
                />
                <EnumMultiBlockFilter<ParentProductCategory>
                    title='Parent category'
                    selected={filterValues.parentCategories}
                    onChange={filters.parentCategories.set}
                    items={allParentProductCategories}
                    renderItem={trParentProductCategory}
                />
                <SearchableEnumMultiBlockFilter<Country>
                    title='Country'
                    selected={filterValues.countries}
                    onChange={filters.countries.set}
                    items={allCountriesSorted}
                    renderItem={trCountry}
                    searchPlaceholder='Search countries'
                    toText={key => trCountry(key) ?? ''}
                />
                <SearchableEnumMultiBlockFilter<AnyRegion>
                    title='Region'
                    selected={filterValues.regions}
                    onChange={filters.regions.set}
                    items={allRegions}
                    renderItem={trRegion}
                    searchPlaceholder='Search regions'
                    toText={key => trRegion(key) ?? ''}
                />
                <BooleanBlockFilter filter={filters.isActiveForNewOrders} title='Status for new orders' />
                <BooleanBlockFilter
                    filter={filters.isActiveForStoreAndReuse}
                    title='Status for store and reuse'
                />
                <BooleanBlockFilter
                    filter={filters.isRefurbished}
                    title='Stock condition'
                    labelForTrue='Refurbished'
                    labelForFalse='Non-refurbished'
                />
                <BooleanBlockFilter
                    filter={filters.isInternal}
                    title='Internal'
                    labelForTrue='Internal'
                    labelForFalse='Non-internal'
                />
            </BlockFilterContainer>
            <Box>
                <ActiveFiltersLayout show={filterCount > 0}>
                    <ActiveFilters filters={filters} onClearAll={clearFilters} />
                </ActiveFiltersLayout>
            </Box>
            <ProductTable listProductsOptions={filterValues} onOpenProduct={onOpenProduct} />
        </Page>
    );
};

interface ProductTableProps {
    listProductsOptions: Partial<ListProductFilters>;
    onOpenProduct(id: UUID): void;
}

const ProductTable = memo<ProductTableProps>(({ listProductsOptions, onOpenProduct }) => {
    const { products, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } =
        useListProducts(listProductsOptions);

    const trProductCategory = useTrProductCategory();
    return (
        <InfinityScrollTable
            data={products}
            toKey={product => product.id}
            onRowClick={v => onOpenProduct(v.id)}
            infinityScroll={{
                hasMore: hasNextPage,
                isLoadingMore: isFetchingNextPage,
                isLoading,
                loadMore: () => fetchNextPage(),
            }}
            emptyContent={
                <Placeholder
                    illustration={SvgIllustration.AssetsSearch}
                    title='No products'
                    message='No products for selected criteria'
                />
            }
            isLoading={isLoading}
            minWidth={900}
            columns={[
                PublicIdColumn<ProductDto>(),
                {
                    id: 'product',
                    header: 'Product',
                    flexGrow: 1,
                    renderer: product => (
                        <ProductOverview product={product} images={product.variants[0].image} />
                    ),
                },
                {
                    id: 'category',
                    header: 'Category',
                    width: 120,
                    flexGrow: 0,
                    renderer: product => trProductCategory(product.category),
                },
                {
                    id: 'variants',
                    header: 'Variants',
                    width: 120,
                    flexGrow: 0,
                    renderer: product => `${product.variants.length} variants`,
                },
                {
                    id: 'status',
                    header: 'Status',
                    width: 100,
                    flexGrow: 0,
                    renderer: product => (
                        <Box column gap={4}>
                            <ActiveProductChip value={product.isActive} />
                            <InternalProductChip value={product.isInternal} />
                        </Box>
                    ),
                },
            ]}
        />
    );
});
