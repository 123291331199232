import { isEmpty } from 'lodash-es';

import {
    AddressDto,
    AdminRefDto,
    AuditableDto,
    isShipmentFromOrganizationWarehouse,
    isShipmentFromUser,
    PublicRefDto,
    ShipmentLocationAware,
    ShipmentStatus,
    ShipmentStatusAware,
    ShipmentTrackingAware,
    ShipmentType,
} from '@hofy/api-shared';
import { DateRangeStrings, DateString, UUID } from '@hofy/global';

import { AssignmentWithProductDto } from '../../../assignment/service/types/AssignmentWithProductDto';
import { PurchaseOrderDto } from '../../../purchaseOrder/service/types/PurchaseOrderDto';
import { WarehouseDetailsUnionDto } from '../../../warehouse/service/types/WarehouseDetailsDto';

export type ShipmentDetailsDto = AuditableDto &
    PublicRefDto &
    ShipmentLocationAware<ShipmentDetailsUserDto, WarehouseDetailsUnionDto, null> &
    ShipmentStatusAware &
    ShipmentTrackingAware & {
        deliveryNotes: string | null;
        withCourierOn: DateString | null;
        orderCompletionDate: string | null;
        assignedUser: AdminRefDto | null;
        bookedOn: DateString | null;
        canceledOn: DateString | null;
        completedOn: DateString | null;
        scheduledOn: DateString | null;
        assignments: AssignmentWithProductDto[];
        estimatedAvailability: DateRangeStrings | null;
        latestWithCourierOn: DateString | null;
        backorderOn: DateString | null;
        sendNotification: boolean;
        purchaseOrder: PurchaseOrderDto | null;
        numberOfBoxes: number | null;
        adminType: ShipmentType;
    };

export interface ShipmentDetailsUserDto {
    id: UUID;
    workEmail: string | null;
    personalEmail: string | null;
    firstName: string;
    lastName: string;
    organizationId: UUID;
    address: AddressDto | null;
}

export const originalCollectionAddressSentInSurvey = (shipment: ShipmentDetailsDto) => {
    if (isShipmentFromUser(shipment)) {
        return shipment.fromUser.address;
    }
    if (isShipmentFromOrganizationWarehouse(shipment)) {
        return shipment.fromWarehouse.address;
    }
    return null;
};

export const isScheduled = (shipment: Pick<ShipmentDetailsDto, 'status' | 'scheduledOn'>): boolean =>
    shipment.status === ShipmentStatus.Booked && !isEmpty(shipment.scheduledOn);
