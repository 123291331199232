import React, { ElementRef, forwardRef } from 'react';

import { Currency, currencySymbols, PriceRange } from '@hofy/global';

import { formatNumberOptional } from '../../../../helpers/Number';
import { Box, Paragraph3 } from '../../../base';
import { InputProps } from '../Input';
import { NumberInput } from '../number/NumberInput';

interface BasePriceRangeInputProps extends Omit<InputProps, 'onChange' | 'value' | 'nullable' | 'leftSlot'> {
    defaultCurrency?: Currency;
    withoutDecimals?: boolean;
}

export interface PriceRangeInputOnlyStringProps extends BasePriceRangeInputProps {
    nullable?: false;
    value: PriceRange;
    onChange(value: PriceRange): void;
}

export interface PriceRangeInputNullableStringProps extends BasePriceRangeInputProps {
    nullable: true;
    value: PriceRange | null;
    onChange(v: PriceRange | null): void;
}

type PriceRangeInputProps = PriceRangeInputOnlyStringProps | PriceRangeInputNullableStringProps;

export const PriceRangeInput = forwardRef<ElementRef<'input'>, PriceRangeInputProps>(
    ({ value, onChange, defaultCurrency = Currency.GBP, withoutDecimals, ...props }, ref) => {
        const fromValue = !value?.from ? null : parseFloat(value.from);
        const toValue = !value?.to ? null : parseFloat(value.to);

        const precision = withoutDecimals ? 0 : 2;

        return (
            <Box row gap={4} ref={ref}>
                <NumberInput
                    value={fromValue!}
                    onChange={number => {
                        if (number === null) {
                            if (!props.nullable) {
                                throw new Error('Cannot set null value on non-nullable PriceRangeInput');
                            }
                            onChange({
                                from: null,
                                to: value?.to ?? null,
                                currency: value?.currency ?? defaultCurrency,
                            });
                        } else {
                            onChange({
                                from: number.toFixed(precision),
                                to: value?.to ?? null,
                                currency: value?.currency ?? defaultCurrency,
                            });
                        }
                    }}
                    leftSlot={currencySymbols[value?.currency || defaultCurrency]}
                    formatter={number => formatNumberOptional(number, precision)}
                    precision={precision}
                    fixedDecimal
                    {...props}
                />
                <Paragraph3>-</Paragraph3>
                <NumberInput
                    ref={ref}
                    value={toValue!}
                    onChange={number => {
                        if (number === null) {
                            if (!props.nullable) {
                                throw new Error('Cannot set null value on non-nullable PriceRangeInput');
                            }
                            onChange({
                                from: value?.from ?? null,
                                to: null,
                                currency: value?.currency ?? defaultCurrency,
                            });
                        } else {
                            onChange({
                                from: value?.from ?? null,
                                to: number.toFixed(precision),
                                currency: value?.currency ?? defaultCurrency,
                            });
                        }
                    }}
                    leftSlot={currencySymbols[value?.currency || defaultCurrency]}
                    formatter={number => formatNumberOptional(number, precision)}
                    precision={precision}
                    fixedDecimal
                    {...props}
                />
            </Box>
        );
    },
);
