export const itemSlideoutTabI18n = {
    'item-slideout-tab.details': 'Details',
    'item-slideout-tab.assignments': 'Assignments',
    'item-slideout-tab.inspections': 'Inspections',
    'item-slideout-tab.repairs': 'Repairs',
    'item-slideout-tab.clearance-requests': 'Clearance requests',
    'item-slideout-tab.billing': 'Billing',
    'item-slideout-tab.fixed-assets': 'Fixed assets',
    'item-slideout-tab.status-history': 'Status history',
};
