import { isEmpty } from 'lodash-es';
import React, { FC } from 'react';

import {
    allAssignmentCollectionReasons,
    AssignmentCollectionReason,
    useTrAssignmentCollectionReason,
} from '@hofy/api-shared';
import { FormDropdown, FormTextarea } from '@hofy/common';
import { UUID } from '@hofy/global';
import { errorMap } from '@hofy/helpers';
import { ConfirmModal, useForm } from '@hofy/ui';

import { useUpdateCollectionReason } from '../../../store/assignments/useUpdateCollectionReason';

interface UpdateCollectAssignmentModalProps {
    assignmentId: UUID;
    collectionReason: AssignmentCollectionReason | null;
    onSuccess(): void;
}

interface UpdateCollectOrderFormData {
    collectionReason: AssignmentCollectionReason | null;
    collectionNotes: string;
}

interface ValidatedUpdateCollectOrderFormData extends UpdateCollectOrderFormData {
    collectionReason: AssignmentCollectionReason;
}

export const UpdateCollectAssignmentModal: FC<UpdateCollectAssignmentModalProps> = ({
    assignmentId,
    collectionReason,
    onSuccess,
}) => {
    const { collect } = useUpdateCollectionReason(assignmentId, onSuccess);
    const trCollectionReason = useTrAssignmentCollectionReason();

    const form = useForm<UpdateCollectOrderFormData, ValidatedUpdateCollectOrderFormData>({
        initial: {
            collectionReason,
            collectionNotes: '',
        },
        onSubmit: ({ collectionReason, collectionNotes }) => {
            collect(collectionReason, collectionNotes);
        },
        validate: ({ collectionReason, collectionNotes }) => ({
            collectionReason: errorMap([
                !collectionReason,
                'Collection reason is required when reason is Other',
            ]),
            collectionNotes: errorMap(
                [
                    collectionReason === AssignmentCollectionReason.Other && isEmpty(collectionNotes),
                    'Collection notes are required',
                ],
                [collectionNotes.length > 500, 'Collection notes exceed max 500 chars'],
            ),
        }),
    });

    return (
        <ConfirmModal
            keyPrefix='update-collect-order-modal'
            onClose={onSuccess}
            onConfirm={form.submit}
            closeOnConfirm={false}
        >
            <FormDropdown
                items={allAssignmentCollectionReasons}
                label='Collection Reason'
                labelFormatter={trCollectionReason}
                value={form.values.collectionReason}
                onChange={collectionReason =>
                    form.setValues({
                        collectionReason,
                    })
                }
                marginTop={20}
                marginBottom={10}
                isRequired
                clearable
                emptyContent='reason'
            />
            {form.values.collectionReason === AssignmentCollectionReason.Other && (
                <FormTextarea
                    marginBottom={10}
                    label='Reason:'
                    value={form.values.collectionNotes}
                    onChangeText={collectionNotes =>
                        form.setValues({
                            collectionNotes,
                        })
                    }
                    note='Max 500 characters'
                    rows={4}
                    isError={form.errors.collectionNotes}
                />
            )}
        </ConfirmModal>
    );
};
