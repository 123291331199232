import { xorWith } from 'lodash-es';
import React, { FC } from 'react';

import { ItemDto } from '@hofy/api-admin';
import { ItemLocation, ItemStatus, Ownership, ProductCategory } from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import {
    Badge,
    Box,
    Checkbox,
    FilterHeaderCell,
    FilterHeaderCellSingle,
    InfiniteScrollConfig,
    InfinityScrollTable,
    Placeholder,
    PublicIdColumn,
    SvgIllustration,
    UseFiltersResult,
} from '@hofy/ui';
import { ItemLocationCell, ItemOwnershipBadge, ProductCell } from '@hofy/ui-domain';

import { ItemStatusChip } from '../../../components/domain/items/ItemStatusChip';

interface InvoicesTabProps {
    infinityScroll: InfiniteScrollConfig;
    items: ItemDto[];
    selectedItemsIds: UUID[];
    setSelectedItemsIds(ids: UUID[]): void;
    filters: UseFiltersResult<
        {
            category: ProductCategory[];
            location: ItemLocation[];
            status: ItemStatus[];
            ownership: Ownership | null;
            withAddon: UUID[];
            withoutAddon: UUID[];
        },
        {}
    >;
    onEntryClick(id: UUID): void;
}

export const DevicesTable: FC<InvoicesTabProps> = ({
    items,
    selectedItemsIds,
    setSelectedItemsIds,
    filters,
    infinityScroll,
    onEntryClick,
}) => {
    const areItemsAllSelected = items.length === selectedItemsIds.length;
    const areItemsPartiallySelected = selectedItemsIds.length > 0 && !areItemsAllSelected;

    return (
        <InfinityScrollTable
            data={items}
            toKey={item => item.id}
            onRowClick={item => onEntryClick(item.id)}
            infinityScroll={infinityScroll}
            emptyContent={
                <Placeholder
                    illustration={SvgIllustration.AssetsSearch}
                    title='No devices were found for the current search'
                />
            }
            flex='auto'
            columns={[
                {
                    id: 'selector',
                    flexGrow: 0,
                    width: 60,
                    header: (
                        <Checkbox
                            checked={areItemsPartiallySelected ? 'mixed' : selectedItemsIds.length > 0}
                            onChange={() =>
                                setSelectedItemsIds(areItemsAllSelected ? [] : items.map(({ id }) => id))
                            }
                        />
                    ),
                    renderer: item => (
                        <Box onClick={e => e.stopPropagation()}>
                            <Checkbox
                                checked={selectedItemsIds.includes(item.id)}
                                onChange={() =>
                                    setSelectedItemsIds(xorWith([...selectedItemsIds], [item.id]))
                                }
                            />
                        </Box>
                    ),
                },
                PublicIdColumn<ItemDto>(),
                {
                    id: 'product',
                    flexGrow: 3,
                    header: <FilterHeaderCell label='Product' filter={filters.filters.category} />,
                    renderer: item => <ProductCell product={item.product} />,
                },
                {
                    id: 'location',
                    width: 150,
                    flexGrow: 1,
                    header: <FilterHeaderCell label='Location' filter={filters.filters.location} />,
                    renderer: item => <ItemLocationCell location={item.location} />,
                },
                {
                    id: 'owner',
                    flexGrow: 1,
                    width: 80,
                    header: <FilterHeaderCellSingle label='Owner' filter={filters.filters.ownership} />,
                    renderer: item => <ItemOwnershipBadge ownership={item.ownership} />,
                },
                {
                    id: 'status',
                    flexGrow: 1,
                    width: 80,
                    header: <FilterHeaderCell label='Status' filter={filters.filters.status} />,
                    renderer: item => <ItemStatusChip status={item.status} />,
                },
                {
                    id: 'service_packages',
                    flexGrow: 1,
                    width: 150,
                    header: (
                        <Box gap={10}>
                            <FilterHeaderCell label='Service package' filter={filters.filters.withAddon} />
                            {filters.filters.withoutAddon.value.length > 0 && (
                                <FilterHeaderCell
                                    label='Without service package'
                                    filter={filters.filters.withoutAddon}
                                />
                            )}
                        </Box>
                    ),

                    renderer: item => (
                        <Box gap={5} wrap>
                            {item.activeAddons?.map(addon => (
                                <Badge key={addon.id} color='green' label={addon.name} />
                            ))}
                            {item.pendingAddons?.map(addon => (
                                <Badge key={addon.id} color='yellow' label={addon.name} />
                            ))}
                        </Box>
                    ),
                },
            ]}
        />
    );
};
