import { includes, xor } from 'lodash-es';
import React, { FC, useState } from 'react';

import { VariantDto } from '@hofy/api-admin';
import { useSearch } from '@hofy/common';
import { UUID } from '@hofy/global';
import { SvgIcon } from '@hofy/ui';

import { SearchableBlockFilter } from '../../../../../components/design/blockFilters/SearchableBlockFilter';

interface VariantBlockFilterProps {
    variants: VariantDto[];
    selected: UUID[];
    setSelected(ids: UUID[]): void;
}

export const SkuBlockFilter: FC<VariantBlockFilterProps> = ({ variants, selected, setSelected }) => {
    const [variantSearchQuery, setVariantSearchQuery] = useState('');

    const { results } = useSearch(variants, variant => [variant.sku], variantSearchQuery);

    return (
        <SearchableBlockFilter<VariantDto>
            title='SKU'
            icon={SvgIcon.Laptop}
            onFilterClick={variant => setSelected(xor(selected, [variant.id]))}
            isSelected={variant => includes(selected, variant.id)}
            items={results}
            renderItem={variant => variant.sku}
            searchPlaceholder='Search'
            search={variantSearchQuery}
            onSearch={setVariantSearchQuery}
        />
    );
};
