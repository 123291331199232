import { includes, xor } from 'lodash-es';
import React, { FC, useState } from 'react';

import { AdminRefDto, formatAdminName } from '@hofy/api-shared';
import { useSearch } from '@hofy/common';
import { UUID } from '@hofy/global';
import { SvgIcon } from '@hofy/ui';

import { SearchableBlockFilter } from '../../../../../components/design/blockFilters/SearchableBlockFilter';

interface AdminBlockFilterProps {
    admins: AdminRefDto[];
    selected: UUID[];
    setSelected(ids?: UUID[]): void;
}

export const AdminBlockFilter: FC<AdminBlockFilterProps> = ({ admins, selected, setSelected }) => {
    const [userSearchQuery, setUserSearchQuery] = useState('');

    const { results } = useSearch(admins, admin => [formatAdminName(admin)], userSearchQuery);

    return (
        <SearchableBlockFilter<AdminRefDto>
            title='Created by'
            icon={SvgIcon.User}
            onFilterClick={admin => setSelected(xor(selected, [admin.id]))}
            isSelected={admin => includes(selected, admin.id)}
            items={results}
            renderItem={formatAdminName}
            searchPlaceholder='Search'
            search={userSearchQuery}
            onSearch={setUserSearchQuery}
        />
    );
};
