import React, { FC } from 'react';

import { useTrSeatAssignmentStatus } from '@hofy/api-admin';
import { SeatAssignmentStatus, seatAssignmentStatusColor } from '@hofy/api-shared';
import { Badge } from '@hofy/ui';

interface SeatAssignmentStatusChipProps {
    status: SeatAssignmentStatus;
}

export const SeatAssignmentStatusChip: FC<SeatAssignmentStatusChipProps> = ({ status }) => {
    const tr = useTrSeatAssignmentStatus();
    return <Badge label={tr(status)} color={seatAssignmentStatusColor[status]} />;
};
