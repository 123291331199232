import { useQueryClient } from '@tanstack/react-query';
import { noop } from 'lodash-es';
import { createContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
    AuthError,
    authProvider,
    isAuthError,
    isTwoFactorAuthError,
    PublicSSO,
    SignInPayload,
    TokenPair,
    toTokenPair,
} from '@hofy/api-auth';
import { hasAnyManagerRole } from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import { useLocalStorage } from '@hofy/hooks';

interface AuthWrapperContextProps {
    signIn(p: SignInPayload, onTwoFaNeeded: () => void): void;
    signOut(): void;
    signUpWithPublicSSO(sso: PublicSSO): void;
    switchIdentity(uuid: UUID, onSuccess: (isManager: boolean) => void): void;
    signInWithPublicSSO(sso: PublicSSO, signUpToken: string | null): void;
    signInWithConnection(connection: string, signUpToken: string | null): void;
    isAuthenticated: boolean;

    authError?: AuthError;
    signInError?: AuthError;

    signInIsLoading: boolean;
    storageToken: TokenPair | null;
}

const emptyAuthWrapperContext: AuthWrapperContextProps = {
    signIn: noop,
    signOut: noop,
    signUpWithPublicSSO: noop,
    switchIdentity: noop,
    signInWithPublicSSO: noop,
    signInWithConnection: noop,
    isAuthenticated: false,
    signInIsLoading: false,
    storageToken: null,
};

export const AuthWrapperContext = createContext(emptyAuthWrapperContext);

export const useAuthWrapper = () => {
    const history = useHistory<{ redirect: string }>();

    const [isInitialized, setIsInitialized] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [signInIsLoading, setSignInIsLoading] = useState(false);
    const [authError, setAuthError] = useState<AuthError>();
    const [signInError, setSignInError] = useState<AuthError>();
    const [storageToken] = useLocalStorage<TokenPair | null>(authProvider.STORAGE_KEY, null);
    const queryClient = useQueryClient();

    const clearErrors = () => {
        setSignInError(undefined);
        setAuthError(undefined);
    };

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        if (isInitialized) {
            setIsAuthenticated(!!storageToken);
        }
    }, [storageToken, isInitialized]);

    const init = async () => {
        try {
            const result = await authProvider.init({ historyReplaceHandler: history.replace });
            if (isAuthError(result)) {
                setAuthError(result);
                setIsAuthenticated(false);
            } else {
                setIsAuthenticated(result);
                clearErrors();
            }
        } finally {
            setIsInitialized(true);
        }
    };

    const signIn = async (payload: SignInPayload, onTwoFaNeeded: () => void) => {
        try {
            setSignInIsLoading(true);
            const tokens = await authProvider.signIn(payload);
            if (isTwoFactorAuthError(tokens)) {
                onTwoFaNeeded();
                clearErrors();
            } else if (isAuthError(tokens)) {
                setSignInError(tokens);
            } else {
                clearErrors();
                setIsAuthenticated(true);
                history.replace(history.location.state?.redirect);
            }
        } catch (e) {
            setIsAuthenticated(false);
        } finally {
            setSignInIsLoading(false);
        }
    };

    const signUpWithPublicSSO = async (sso: PublicSSO) => {
        await authProvider.signUpWithPublicSSO(sso);
    };
    const switchIdentity = async (uuid: UUID, onSuccess: (isManager: boolean) => void) => {
        setIsInitialized(false);
        const tokens = await authProvider.switchIdentity({
            userId: uuid,
        });
        if (!isAuthError(tokens)) {
            queryClient.removeQueries();
            toTokenPair(tokens);
            onSuccess(hasAnyManagerRole(toTokenPair(tokens).roles));
        }
        setIsInitialized(true);
    };

    const signInWithPublicSSO = async (sso: PublicSSO) => {
        await authProvider.signInWithPublicSSO(sso, {
            redirect: history.location.state?.redirect,
            signUpToken: null,
        });
    };
    const signInWithConnection = async (c: string, token: string | null) => {
        await authProvider.signInWithConnection(c, {
            signUpToken: token,
            redirect: history.location.state?.redirect,
        });
    };

    const signOut = async () => {
        clearErrors();
        setIsAuthenticated(false);
        authProvider.signOut();
    };

    return {
        signIn,
        signOut,
        signUpWithPublicSSO,
        signInWithPublicSSO,
        signInWithConnection,
        switchIdentity,
        isInitialized,
        isAuthenticated,
        authError,
        signInError,
        signInIsLoading,
        storageToken,
    };
};
