import {
    AuthError,
    isAuthError,
    RefreshTokenPayload,
    TokenPair,
    TokenPairDto,
    toTokenPair,
} from '@hofy/api-auth';
import { EmailType } from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import { isResponseError, publicRestClient, restClient } from '@hofy/rest';

import { GenerateSignupLinkDto } from './types/GenerateSignupLinkDto';
import { RevokeTokenPayload } from './types/RevokeTokenPayload';
import { SessionDto } from './types/SessionDto';

class AuthService {
    public getSession = async (): Promise<SessionDto> => {
        return restClient.getJson<SessionDto>('/api/admin/auth/session');
    };
    public generateSignupLink = async (emailType: EmailType, userId: UUID): Promise<GenerateSignupLinkDto> =>
        await restClient.postJson(`/api/admin/auth/users/${userId}/signup-link`, {
            emailType,
        });

    public signInToOrganization = (organizationId: UUID): Promise<TokenPairDto> => {
        return restClient.postJson<TokenPairDto>(`/api/admin/auth/sign-in-to-organization/${organizationId}`);
    };
    public signInAsUser = (userId: UUID): Promise<TokenPairDto> => {
        return restClient.postJson<TokenPairDto>(`/api/admin/auth/sign-in-as-user/${userId}`);
    };
    public revoke = async (payload: RevokeTokenPayload): Promise<void> => {
        return await publicRestClient.post('/api/admin/auth/revoke', payload).then();
    };
    public exchangeTemporary = async (payload: { token: string }): Promise<TokenPair | AuthError> => {
        try {
            const token = await publicRestClient.postJson<TokenPairDto>('/api/admin/auth/exchange', payload);
            return toTokenPair(token);
        } catch (r) {
            if (isResponseError(r) && isAuthError(r.response?.code)) {
                return r.response?.code as AuthError;
            }
            return Promise.reject(r);
        }
    };
    public refresh = async (payload: RefreshTokenPayload): Promise<TokenPair | AuthError> => {
        try {
            const token = await publicRestClient.postJson<TokenPairDto>('/api/admin/auth/refresh', payload);
            return toTokenPair(token);
        } catch (r) {
            if (isResponseError(r) && isAuthError(r.response?.code)) {
                return r.response?.code as AuthError;
            }
            return Promise.reject(r);
        }
    };
}

export const authService = new AuthService();
