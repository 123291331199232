import { range } from 'lodash-es';
import React, { CSSProperties, ForwardedRef, forwardRef, Key, ReactNode } from 'react';

import { NumberValues } from '@hofy/theme';

import { Box, BoxProps } from '../base';
import { BaseTableHeader } from './BaseTableHeader';
import { BaseTableRow, BaseTableRowSkeleton } from './BaseTableRow';
import { BaseTableColumnConfig } from './types/BaseTableColumnConfig';
import { TableRowRendererProps } from './types/TableRowRendererProps';

export interface BaseTableProps<T> extends BoxProps {
    data: T[];
    toKey(v: T, index: number): Key;
    hoverable?: boolean;
    onRowClick?(v: T, index: number): void;
    columns: BaseTableColumnConfig<T>[];
    rowStyle?(v: T, index: number): CSSProperties;
    isLoading?: boolean;
    emptyContent?: ReactNode | JSX.Element;
    headerRenderer?(columns: BaseTableColumnConfig<T>[]): ReactNode;
    bodyRenderer?(content: ReactNode): ReactNode;
    rowRenderer?(item: TableRowRendererProps<T>): ReactNode;
    bodyPaddingBottom?: NumberValues;
    bodyPaddingTop?: NumberValues;
    inlineHeader?: boolean;
    rowVerticalPadding?: NumberValues;
    rowHorizontalPadding?: NumberValues;
    headerHorizontalPadding?: NumberValues;
    forwardedRef?: ForwardedRef<HTMLDivElement>;
}
export const defaultRowRenderer = <T extends any>(p: TableRowRendererProps<T>) => {
    return (
        <BaseTableRow
            key={p.key}
            item={p.item}
            rowIndex={p.index}
            columns={p.columns}
            onRowClick={v => p?.onRowClick?.(v, p.index)}
            hoverable={p.hoverable}
            rowVerticalPadding={p.rowVerticalPadding}
            rowHorizontalPadding={p.rowHorizontalPadding}
            styles={p.styles}
        />
    );
};

export const BaseTable = <T extends any>({
    data,
    columns,
    isLoading = false,
    emptyContent,
    onRowClick,
    toKey,
    headerRenderer,
    bodyRenderer,
    hoverable = !!onRowClick,
    rowVerticalPadding = 12,
    rowHorizontalPadding = 20,
    headerHorizontalPadding = 40,
    minWidth,
    forwardedRef,
    bodyPaddingBottom = 10,
    bodyPaddingTop = 10,
    inlineHeader,
    rowRenderer,
    rowStyle,
    ...boxProps
}: BaseTableProps<T>) => {
    const defaultBodyRenderer = (content: ReactNode) => (
        <Box
            data-test-key='table-body'
            minWidth={minWidth}
            paddingHorizontal={(headerHorizontalPadding - rowHorizontalPadding) as NumberValues}
            paddingTop={bodyPaddingTop}
            paddingBottom={bodyPaddingBottom}
            fullWidth
            flex={1}
            column
            alignItems='stretch'
            zIndex={1}
        >
            {content}
        </Box>
    );

    const renderRow = (item: T, index: number) => {
        const props: TableRowRendererProps<T> = {
            key: toKey(item, index).toString(),
            item,
            index,
            columns,
            onRowClick,
            hoverable,
            rowVerticalPadding,
            rowHorizontalPadding,
            styles: rowStyle ? rowStyle(item, index) : undefined,
        };
        return (rowRenderer || defaultRowRenderer)(props);
    };

    const defaultHeaderRenderer = (columns: BaseTableColumnConfig<T>[]) => (
        <BaseTableHeader
            paddingHorizontal={headerHorizontalPadding}
            inline={inlineHeader}
            minWidth={minWidth}
            columns={columns}
        />
    );

    const renderContent = () => {
        if (isLoading) {
            return (
                <Box>
                    {range(10).map(i => (
                        <BaseTableRowSkeleton
                            data-test-key='table-row-skeleton'
                            key={i}
                            rowVerticalPadding={rowVerticalPadding}
                            rowHorizontalPadding={rowHorizontalPadding}
                            columns={columns}
                            isFirst={i === 0}
                        />
                    ))}
                </Box>
            );
        }
        if (data.length === 0) {
            return (
                <Box data-test-key='table-empty-content' flex={1} row justify='center'>
                    {emptyContent}
                </Box>
            );
        }

        return <>{data.map((v, index) => renderRow(v, index))}</>;
    };

    const table = (
        <>
            {(headerRenderer || defaultHeaderRenderer)(columns)}
            {(bodyRenderer || defaultBodyRenderer)(renderContent())}
        </>
    );

    return (
        <Box
            ref={forwardedRef}
            column
            flex='auto'
            overflow='auto'
            fullHeight
            data-test-key='table-root'
            {...boxProps}
        >
            {table}
        </Box>
    );
};

export const BaseTableWithRef = forwardRef(
    <T extends any>(props: BaseTableProps<T>, ref: ForwardedRef<HTMLDivElement>) => {
        return <BaseTable {...props} forwardedRef={ref} />;
    },
);
