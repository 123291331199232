import { compact } from 'lodash-es';
import React, { forwardRef, MouseEvent, ReactNode } from 'react';
import styled from 'styled-components';

import { MarginValues } from '@hofy/theme';

import { useIsDisabled } from '../../../contexts';
import { TestKeyAware, TestKeyPrefixAware } from '../../../types';
import { Box, MarginBoxProps } from '../../base';
import { Icon } from '../../icon';
import { BareButton } from './BareButton';
import { ButtonAction, buttonColors, ButtonSize, buttonSizes, ButtonType } from './buttonConfig';

export interface ButtonProps extends MarginBoxProps, TestKeyAware, TestKeyPrefixAware {
    id?: string;
    label?: string;
    type?: ButtonType;
    action?: ButtonAction;
    size?: ButtonSize;
    fullWidth?: boolean;
    disabled?: boolean;
    children?: ReactNode;

    onClick?(e: MouseEvent<HTMLButtonElement>): void;
    to?: string;

    leftIcon?: Svg;
    rightIcon?: Svg;

    overlay?: ReactNode;
    hideLabel?: boolean;

    /** @deprecated use fullWidth */
    width?: number;
    negativeMargin?: boolean;
}

export const defaultType: ButtonType = 'primary';
export const defaultAction: ButtonAction = 'default';
export const defaultSize = 'medium';

export const Button = forwardRef<any, ButtonProps>(
    (
        {
            label,
            type = defaultType,
            action = defaultAction,
            size = defaultSize,
            fullWidth,
            disabled: buttonDisabled,
            onClick,
            to,
            leftIcon,
            rightIcon,
            overlay,
            hideLabel = !!overlay,
            testKeyPrefix,
            testKey,
            children,
            width: deprecatedWidth,
            negativeMargin,
            ...marginProps
        },
        ref,
    ) => {
        const disabled = useIsDisabled(buttonDisabled);

        const { text, bg, border } = buttonColors[type][action];
        let { px, py, height, width, fontSize } = buttonSizes[size];

        const isPlainType = type === 'plain';

        if (isPlainType) {
            px = 0;
            py = 0;
            height = undefined;
        }

        const hasOneIcon = compact([leftIcon, rightIcon]).length === 1;

        const dataTestKey = testKeyPrefix ? `${testKeyPrefix}--${type}-button` : testKey;

        if (!label && hasOneIcon && !fullWidth) {
            px = 0;
            py = 0;
            width = height;
        }

        const radius = !isPlainType && 8;

        return (
            <BareButton
                ref={ref}
                onClick={onClick}
                to={to}
                label={label}
                labelSize={fontSize}
                labelColors={text}
                bgColors={bg}
                borderColors={border}
                paddingHorizontal={px}
                paddingVertical={py}
                marginHorizontal={negativeMargin ? (-px as MarginValues) : undefined}
                height={height}
                width={deprecatedWidth || width}
                fullWidth={fullWidth}
                disabled={disabled}
                rounded={radius}
                centered
                leftSlot={leftIcon && <Icon svg={leftIcon} size={16} color='currentColor' />}
                rightSlot={rightIcon && <Icon svg={rightIcon} size={16} color='currentColor' />}
                overlaySlot={
                    overlay && (
                        <OverlayBox rounded={radius} centered>
                            {overlay}
                        </OverlayBox>
                    )
                }
                hideLabel={hideLabel}
                testKey={dataTestKey}
                {...marginProps}
            >
                {children}
            </BareButton>
        );
    },
);

const OverlayBox = styled(Box)`
    position: absolute;
    inset: 0;
`;
