import { noop } from 'lodash-es';
import React, { FC, useEffect } from 'react';

import { SeparatedContainer } from '@hofy/common';
import { UUID } from '@hofy/global';
import { ProductImage } from '@hofy/product';
import {
    ArrayField,
    Box,
    Button,
    ErrorStatePlaceholder,
    FormFieldRecord,
    FormGridRow,
    FormNumberInput,
    FormPriceInput,
    FormSection,
    IconButton,
    LabeledInput,
    Skeleton,
    SvgIcon,
} from '@hofy/ui';

import { FormVariantSelect } from '../../../../components/domain/products/FormVariantSelect';
import {
    BYODOrderFormItemData,
    emptyBYODOrderItemFormData,
} from '../../../../store/byodOrders/types/BYODOrderFormData';
import { useOrganizationDetailsQuery } from '../../../../store/organizations/useOrganizationDetailsQuery';
import { useVariantDetailsMap, VariantDetailsMap } from '../hooks/useVariantDetailsMap';

interface ItemsFormProps {
    items: ArrayField<BYODOrderFormItemData>;
    orgId: UUID;
}

export const BYODItemsForm: FC<ItemsFormProps> = ({ items, orgId }) => {
    const { variantDetailsMap, isLoading } = useVariantDetailsMap();
    const { data: organization, isLoading: isOrganizationLoading } = useOrganizationDetailsQuery(orgId);

    useEffect(() => {
        if (items.fields.length === 0 && organization) {
            items.add(emptyBYODOrderItemFormData(organization.currency));
        }
    }, [isOrganizationLoading]);

    if (isLoading || isOrganizationLoading) {
        return <Skeleton fullWidth height={200} />;
    }

    if (!organization || !variantDetailsMap) {
        return <ErrorStatePlaceholder />;
    }

    return (
        <FormSection label='Products' column>
            {!!items.fields.length && (
                <SeparatedContainer lined orientation='vertical' spacing={20}>
                    {items.fields.map(field => (
                        <ProductRowForm
                            key={field.key}
                            item={field.api}
                            variantsById={variantDetailsMap}
                            onDeleteItem={() => items.remove(field.key)}
                            canBeDeleted={items.fields.length > 1}
                        />
                    ))}
                </SeparatedContainer>
            )}
            <Button
                label='Add new item'
                type='secondary'
                leftIcon={SvgIcon.Add}
                onClick={() => items.add(emptyBYODOrderItemFormData(organization.currency))}
            />
        </FormSection>
    );
};

interface ProductRowFormProps {
    item: FormFieldRecord<BYODOrderFormItemData>;
    variantsById: VariantDetailsMap;
    canBeDeleted: boolean;
    onDeleteItem(): void;
}

const ProductRowForm: FC<ProductRowFormProps> = ({ item, variantsById, canBeDeleted, onDeleteItem }) => {
    return (
        <Box gap={20} row flex='auto'>
            <ProductImage
                image={item.variantId.value ? variantsById[item.variantId.value].imageUrl : null}
                size={172}
            />
            <Box flex={1}>
                <FormGridRow columns={2}>
                    <FormVariantSelect
                        label='Variant'
                        api={item.variantId}
                        placeholder='Select a variant'
                        isRequired
                        nullable
                    />
                    <LabeledInput
                        value={item.variantId.value ? variantsById[item.variantId.value].productName : null}
                        label='Product'
                        disabled
                        nullable
                        onChange={noop}
                    />

                    <FormNumberInput api={item.expectedQuantity} label='Expected quantity' />
                    <FormPriceInput api={item.unitPurchasePrice} label='Unit purchase price' />
                </FormGridRow>
            </Box>

            <IconButton icon={SvgIcon.Trash} onClick={onDeleteItem} disabled={!canBeDeleted} />
        </Box>
    );
};
