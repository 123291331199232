import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { UUID } from '@hofy/global';

import { organizationContractService } from '../service/organizationContractService';
import { OrganizationContractDto } from '../service/types/OrganizationContractDto';
import { organizationContractCacheKey } from './cacheKey';

export const useOrganizationContracts = (organizationId: UUID, pageSize = 50) => {
    const { data, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfiniteQuery({
        queryKey: [organizationContractCacheKey, organizationId],
        queryFn: param =>
            organizationContractService.listOrganizationContracts(organizationId, {
                page: param.pageParam,
                pageSize,
            }),
        initialPageParam: 0,
        getNextPageParam: lastPage => (lastPage.hasNext ? lastPage.page + 1 : undefined),
    });

    const organizationContracts: OrganizationContractDto[] = useMemo(() => {
        return data?.pages.flatMap(v => v.content) || [];
    }, [data]);

    return {
        organizationContracts,
        isLoading,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage,
    };
};
