import { isArray, isNil } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { DateRangeStrings, Sort } from '@hofy/global';
import { asNullable, sortDateRange } from '@hofy/helpers';

export type AnyQueryParams = Record<string, any>;

export interface SerializableQueryDef<T> {
    serialize(value: T): string;
    deserialize(value: string): T;
    emptyValue?: T;
}
export type QueryDefinitionsObject<T> = { [K in keyof T]: SerializableQueryDef<T[K]> };
interface UseQueryParamsResult<T> {
    query: T;
    setQuery(newParams: Partial<T>): void;
}

export const useQueryParams = <T extends object>(
    paramsDefs: QueryDefinitionsObject<T>,
    defaultValues: Partial<T> = {},
): UseQueryParamsResult<T> => {
    const history = useHistory();
    const location = useLocation();

    type QueryKey = keyof T;

    const query = useMemo(() => {
        const params = new URLSearchParams(location.search);
        const parsedParams: AnyQueryParams = {};

        Object.keys(paramsDefs).forEach(key => {
            const initialValue = defaultValues[key as QueryKey];
            const { deserialize, emptyValue } = paramsDefs[key as QueryKey];
            const paramValue = params.get(key);
            if (paramValue) {
                parsedParams[key] = deserialize(paramValue);
            } else {
                parsedParams[key] = initialValue ?? emptyValue;
            }
        });

        return parsedParams as T;
    }, [location.search, paramsDefs]);

    const setQuery = useCallback(
        (newParams: Partial<T>) => {
            const params = new URLSearchParams(location.search);
            Object.keys(newParams).forEach(key => {
                const initialValue = defaultValues[key as QueryKey];
                const { serialize } = paramsDefs[key as QueryKey];
                const value = newParams[key as QueryKey];

                if (isNil(value) || value === initialValue || (isArray(value) && value.length === 0)) {
                    params.delete(key);
                } else {
                    params.set(key, serialize(value as any));
                }
            });
            history.push({
                search: params.toString(),
            });
        },
        [location.search],
    );
    return {
        query,
        setQuery,
    };
};

const ARR_SEP = ' '; // Will be converted to '+' in URL by the URLSearchParams
const emptyArray: any[] = [];

export const queryStringParser = <T extends string>(): SerializableQueryDef<T> => ({
    serialize: value => value,
    deserialize: value => value as T,
});

export const queryOptionalStringParser = <T extends string>(): SerializableQueryDef<T | null> => ({
    serialize: value => value ?? '',
    deserialize: value => asNullable(value) as T | null,
});

export const queryStringArrayParser = <T extends string>(): SerializableQueryDef<T[]> => ({
    serialize: value => value.join(ARR_SEP),
    deserialize: value => value.split(ARR_SEP) as T[],
    emptyValue: emptyArray,
});

export const queryNumberParser = <T extends number>(): SerializableQueryDef<T> => ({
    serialize: value => value.toString(),
    deserialize: value => parseInt(value, 10) as T,
});

export const queryNumberArrayParser = <T extends number>(): SerializableQueryDef<T[]> => ({
    serialize: value => value.join(ARR_SEP),
    deserialize: value => value.split(ARR_SEP).map(v => parseInt(v, 10)) as T[],
    emptyValue: emptyArray,
});

export const queryArrayNumberParser = (): SerializableQueryDef<number[]> => ({
    serialize: value => value.join(ARR_SEP),
    deserialize: value => value.split(ARR_SEP).map(v => parseInt(v, 10)),
    emptyValue: emptyArray,
});

export const queryBooleanParser = (): SerializableQueryDef<boolean> => ({
    serialize: value => value.toString(),
    deserialize: value => value === 'true',
});

export const querySortParser = <T extends string>(): SerializableQueryDef<Sort<T>> => ({
    serialize: value => [value.sortBy, value.sortDirection].join(ARR_SEP),
    deserialize: value => {
        const [sortBy, sortDirection] = value.split(ARR_SEP);
        return {
            sortBy,
            sortDirection,
        } as Sort<T>;
    },
});

export const queryDateRangeParser = (): SerializableQueryDef<DateRangeStrings | null> => ({
    serialize: value => (value ? [value.from, value.to].join(ARR_SEP) : ''),
    deserialize: value => {
        const [from, to] = value.split(ARR_SEP);
        if (!from && !to) {
            return null;
        }
        return sortDateRange({
            from: from || null,
            to: to || null,
        } as DateRangeStrings);
    },
});
