import { omit } from 'lodash-es';
import { useMemo } from 'react';

import { FixedAssetType, FixedAssetValuationFilters } from '@hofy/api-admin';
import { DateString, UUID } from '@hofy/global';
import { countObjectValues, endOfPreviousMonth, toISODate } from '@hofy/helpers';
import { useStructMemo } from '@hofy/hooks';
import { useArrayQueryParam, useStringQueryParam } from '@hofy/router';

export const useFixedAssetValuationFilters = () => {
    const initialDate = useMemo(() => toISODate(endOfPreviousMonth()), []);

    const [search, setSearch] = useStringQueryParam<string>('search', '');
    const [hofySubsidiaries, setHofySubsidiaries] = useArrayQueryParam<UUID>('hofySubsidiaries', []);
    const [upToMonth, setUpToMonth] = useStringQueryParam<DateString>('upToMonth', initialDate);
    const [fixedAssetType, setFixedAssetType] = useStringQueryParam<FixedAssetType>('fixedAssetType');

    const filters = useStructMemo<FixedAssetValuationFilters>({
        search,
        hofySubsidiaries,
        upToMonth,
        fixedAssetType,
    });

    const filtersToCount = omit(filters, 'search', 'upToMonth');
    const filterCount = countObjectValues(filtersToCount);

    return {
        search,
        filters,
        filterCount,
        setSearch,
        setHofySubsidiaries,
        setUpToMonth,
        setFixedAssetType,
    };
};
