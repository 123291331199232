import { sortBy } from 'lodash-es';
import React, { FC, useMemo, useState } from 'react';

import { useSearch } from '@hofy/common';
import { allCountries, Country } from '@hofy/global';
import { useTrCountry } from '@hofy/i18n';
import { SvgIcon } from '@hofy/ui';

import { SearchableBlockFilter } from '../../../../../../components/design/blockFilters/SearchableBlockFilter';

interface DeliversToBlockFilterProps {
    selected: Country | null;
    items?: Country[];
    onChange(country: Country | null): void;
}

export const DeliversToBlockFilter: FC<DeliversToBlockFilterProps> = ({
    selected,
    items = allCountries,
    onChange,
}) => {
    const trCountry = useTrCountry();
    const [searchQuery, setSearchQuery] = useState('');
    const sortedItems = useMemo(() => sortBy(items, trCountry), [items]);
    const { results } = useSearch(sortedItems, item => `${item} ${trCountry(item)}`, searchQuery);

    return (
        <SearchableBlockFilter
            title='Delivers to'
            icon={SvgIcon.Globe}
            items={results}
            isSelected={country => country === selected}
            onFilterClick={country => (country === selected ? onChange(null) : onChange(country))}
            renderItem={country => trCountry(country)}
            searchPlaceholder='Search country'
            search={searchQuery}
            onSearch={setSearchQuery}
        />
    );
};
