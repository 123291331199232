import { xor } from 'lodash-es';
import React, { FC, useMemo } from 'react';

import { AdminAssignmentFilters } from '@hofy/api-admin';
import { unassignedUser } from '@hofy/api-shared';
import { FilterApiRecord, SvgIcon } from '@hofy/ui';

import { ListMultiBlockFilter } from '../../../../../components/design/blockFilters/ListMultiBlockFilter';

interface AssignmentFiltersBlockProps {
    filterApi: FilterApiRecord<AdminAssignmentFilters>;
    filterValues: AdminAssignmentFilters;
}

export const AssignmentFiltersBlock: FC<AssignmentFiltersBlockProps> = ({ filterApi, filterValues }) => {
    const list = useMemo(
        () => [
            {
                selected: filterValues.allShipments,
                action: filterApi.allShipments.set,
                label: 'All shipments',
            },
            {
                selected: filterValues.configRequired,
                action: filterApi.configRequired.set,
                label: 'Config required',
            },
            {
                selected: filterValues.isConsumable,
                action: filterApi.isConsumable.set,
                label: 'Consumables',
            },
            {
                selected: filterValues.containingLaptopOnly,
                action: filterApi.containingLaptopOnly.set,
                label: 'Containing laptop',
            },
            {
                selected: filterValues.isDisposal,
                action: filterApi.isDisposal.set,
                label: 'Disposals',
            },
            {
                selected: filterValues.isLoaner,
                action: filterApi.isLoaner.set,
                label: 'Loaners',
            },
            {
                selected: filterValues.newJoiners,
                action: filterApi.newJoiners.set,
                label: 'New joiners',
            },
            {
                selected: filterValues.isOnHold,
                action: filterApi.isOnHold.set,
                label: 'On hold',
            },
            {
                selected: filterValues.isPartner,
                action: filterApi.isPartner.set,
                label: 'Partner',
            },
            {
                selected: filterValues.scanned,
                action: filterApi.scanned.set,
                label: 'Scanned',
            },
            {
                selected: filterValues.scheduledOnly,
                action: filterApi.scheduledOnly.set,
                label: 'Scheduled',
            },
            {
                selected: filterValues.isStoreAndReuse,
                action: filterApi.isStoreAndReuse.set,
                label: 'Store and reuse',
            },
            {
                selected: filterValues.isStorageLocation,
                action: filterApi.isStorageLocation.set,
                label: 'Storage Location',
            },
            {
                selected: filterValues.assignedUsers.includes(unassignedUser),
                action: () => filterApi.assignedUsers.set(xor(filterValues.assignedUsers, [unassignedUser])),
                label: 'Unassigned',
            },
            {
                selected: filterValues.unscanned,
                action: filterApi.unscanned.set,
                label: 'Unscanned',
            },
        ],
        [filterValues],
    );

    return (
        <ListMultiBlockFilter
            title='Filter'
            icon={SvgIcon.Filter}
            items={list}
            renderItem={item => item.label}
            selected={list.filter(item => item.selected)}
            onChange={(_items, changedItem) => {
                changedItem.action(changedItem.selected ? null : true);
            }}
        />
    );
};
