import { compact } from 'lodash-es';

import { UUID } from '@hofy/global';

import {
    isParentCategory,
    isParentContainsCategory,
    ParentProductCategory,
    ProductCategory,
} from './ProductCategory';

export interface ProductDto {
    id: UUID;
    name: string;
    brand: string;
    category: ProductCategory;
    isRefurbished: boolean;
    isControlledByOrganization: boolean;
}

export interface SizeAndStyleAware {
    style: string | null;
    size: string | null;
}

export const isProductOfCategory = (
    product: { category: ProductCategory },
    category: ParentProductCategory | ProductCategory | null,
) => {
    if (!category || product.category === category) {
        return true;
    }
    return isParentCategory(category) && isParentContainsCategory(category, product.category);
};
export const formatProductName = (p: { name: string }, v: SizeAndStyleAware) => {
    const styles = compact([v.style, v.size]);
    if (styles.length) {
        return `${p.name}, ${styles.join(' ')}`;
    }
    return p.name;
};
export const formatVariant = (v: SizeAndStyleAware) => {
    const styles = compact([v.style, v.size]);
    return styles.join(', ');
};
