import { xor } from 'lodash-es';
import React, { FC, useState } from 'react';

import { SalesOrderDto, useSalesOrdersQuery } from '@hofy/api-admin';
import {
    allSalesOrderStatuses,
    formatOptionalUserName,
    SalesOrderStatus,
    useTrSalesOrderStatus,
} from '@hofy/api-shared';
import { Page } from '@hofy/common';
import { UUID } from '@hofy/global';
import { formatDateTime } from '@hofy/helpers';
import { usePrice } from '@hofy/hooks';
import {
    ErrorStatePlaceholder,
    FilterChipList,
    InfinityScrollTable,
    PageHeader,
    Placeholder,
    PublicIdColumn,
    SearchInput,
    SvgIllustration,
} from '@hofy/ui';

import { BlockFilterButton } from '../../../components/design/blockFilters/BlockFilterButton';
import { BlockFilterChipContainer } from '../../../components/design/blockFilters/BlockFilterChipContainer';
import { BlockFilterContainer } from '../../../components/design/blockFilters/BlockFilterContainer';
import { EnumMultiBlockFilter } from '../../../components/design/blockFilters/EnumMultiBlockFilter';
import { useBlockFilters } from '../../../components/design/blockFilters/hooks/useBlockFilters';
import { OrganizationBlockFilter } from '../../../components/domain/filters/OrganizationBlockFilter';
import { OrganizationFilterChip } from '../../../components/domain/filters/OrganizationFilterChip';
import { useOrganizationsRefsQuery } from '../../../store/organizations/useOrganizationsQuery';
import { useSalesOrdersFilter } from '../../../store/salesOrders/useSalesOrdersFilter';
import { InvoicingTabs } from '../InvoicingTabs';
import { InvoicingTabRouterProps } from '../types/InvoicingTabRouterProps';

interface SalesOrdersPageProps extends InvoicingTabRouterProps {
    onOpenSalesOrder(id: UUID): void;
}

export const SalesOrdersPage: FC<SalesOrdersPageProps> = ({ tab, tabs, onChangeTab, onOpenSalesOrder }) => {
    const [orgSearchQuery, setOrgSearchQuery] = useState('');

    const { filters, filterCount, setSearch, setOrganizations, setStatuses } = useSalesOrdersFilter();
    const { data: organizations, isLoading: organizationsIsLoading } = useOrganizationsRefsQuery(
        filters.organizations,
        orgSearchQuery,
    );
    const {
        data: salesOrders,
        isLoading,
        isError,
        isFetchingNextPage,
        fetchNextPage,
        hasNextPage,
    } = useSalesOrdersQuery(filters);
    const { formatPrice } = usePrice();
    const trSalesOrderStatus = useTrSalesOrderStatus();
    const { showFilters, toggleShowFilters, filterElRef } = useBlockFilters();

    if (isError) {
        return <ErrorStatePlaceholder />;
    }

    return (
        <Page>
            <PageHeader
                title='Sales orders'
                rightSlot={
                    <>
                        <SearchInput
                            placeholder='Search orders'
                            value={filters.search}
                            onChange={setSearch}
                            autoFocus
                        />
                        <BlockFilterButton
                            onClick={toggleShowFilters}
                            isOpened={showFilters}
                            count={filterCount}
                        />
                    </>
                }
                tabsSlot={<InvoicingTabs tabs={tabs} tab={tab} onChangeTab={onChangeTab} />}
            />
            <BlockFilterContainer ref={filterElRef} show={showFilters}>
                <OrganizationBlockFilter
                    selected={filters.organizations || []}
                    onChange={setOrganizations}
                    organizations={organizations}
                    searchQuery={orgSearchQuery}
                    setSearchQuery={setOrgSearchQuery}
                />
                <EnumMultiBlockFilter<SalesOrderStatus>
                    title='Status'
                    selected={filters.statuses || []}
                    onChange={setStatuses}
                    items={allSalesOrderStatuses}
                    renderItem={trSalesOrderStatus}
                />
            </BlockFilterContainer>
            <BlockFilterChipContainer show={filterCount > 0}>
                <OrganizationFilterChip
                    value={filters.organizations || []}
                    organizations={organizations}
                    onChange={setOrganizations}
                />
                <FilterChipList<SalesOrderStatus>
                    color='grape'
                    toKey={id => id}
                    selected={filters.statuses || []}
                    toLabel={trSalesOrderStatus}
                    onClear={cleared => setStatuses(xor(filters.statuses, [cleared]))}
                />
            </BlockFilterChipContainer>
            <InfinityScrollTable
                data={salesOrders}
                infinityScroll={{
                    isLoading: isLoading || organizationsIsLoading,
                    hasMore: hasNextPage,
                    isLoadingMore: isFetchingNextPage,
                    loadMore: fetchNextPage,
                }}
                emptyContent={
                    <Placeholder
                        illustration={SvgIllustration.FinanceSearch}
                        title='No sales orders'
                        message='There are no sales orders'
                    />
                }
                onRowClick={({ id }) => onOpenSalesOrder(id)}
                columns={[
                    PublicIdColumn<SalesOrderDto>(),
                    {
                        id: 'createdAt',
                        header: 'Created at',
                        flexGrow: 1,
                        renderer: ({ createdAt }) => formatDateTime(createdAt),
                    },
                    {
                        id: 'organization',
                        header: 'Organization',
                        flexGrow: 1,
                        renderer: ({ organization }) => organization.name,
                    },
                    {
                        id: 'user',
                        header: 'User',
                        flexGrow: 1,
                        renderer: ({ user }) => formatOptionalUserName(user),
                    },
                    {
                        id: 'status',
                        header: 'Status',
                        flexGrow: 1,
                        renderer: ({ status }) => trSalesOrderStatus(status),
                    },
                    {
                        id: 'totalGross',
                        header: 'Total gross price',
                        flexGrow: 1,
                        renderer: ({ totalGross }) => formatPrice(totalGross),
                    },
                ]}
                toKey={({ id }) => id}
            />
        </Page>
    );
};
