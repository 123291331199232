import React, { FC } from 'react';

import { useOrganizationContracts } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { useStructMemo } from '@hofy/hooks';

import { OrganizationContractsTable } from './components/OrganizationContractsTable';

interface OrganizationContractsTabProps {
    organizationId: UUID;
    onClick(id: UUID): void;
}

export const OrganizationContractsTab: FC<OrganizationContractsTabProps> = ({ organizationId, onClick }) => {
    const { organizationContracts, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } =
        useOrganizationContracts(organizationId);
    const organizationContractsScrollDetails = useStructMemo({
        hasMore: hasNextPage,
        isLoading: isLoading,
        isLoadingMore: isFetchingNextPage,
        loadMore: fetchNextPage,
    });

    return (
        <OrganizationContractsTable
            //organizationId={organizationId}
            organizationContracts={organizationContracts}
            infinityScroll={organizationContractsScrollDetails}
            onClick={onClick}
        />
    );
};
