import React, { FC } from 'react';

import { useTrOrganizationContractStatus } from '@hofy/api-admin';
import { OrganizationContractStatus, organizationContractStatusColor } from '@hofy/api-shared';
import { Badge } from '@hofy/ui';

interface OrganizationContractStatusChipProps {
    status: OrganizationContractStatus;
}

export const OrganizationContractStatusChip: FC<OrganizationContractStatusChipProps> = ({ status }) => {
    const tr = useTrOrganizationContractStatus();
    return <Badge label={tr(status)} color={organizationContractStatusColor[status]} />;
};
