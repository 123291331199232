import { ParentProductCategory, ProductCategory } from '@hofy/api-shared';
import { AnyRegion, Country, UUID } from '@hofy/global';

export interface ListProductFilters {
    excludedProductIds: UUID[];
    organizationIds: UUID[];
    isActiveForNewOrders: boolean | null;
    isActiveForStoreAndReuse: boolean | null;
    isInternal: boolean | null;
    internalVariantsOnly: boolean | null;
    isRefurbished: boolean | null;
    categories: ProductCategory[];
    parentCategories: ParentProductCategory[];
    countries: Country[];
    regions: AnyRegion[];
    search: string;
}

export const emptyListProductsFilters: ListProductFilters = {
    excludedProductIds: [],
    organizationIds: [],
    isActiveForNewOrders: null,
    isActiveForStoreAndReuse: null,
    isInternal: null,
    internalVariantsOnly: null,
    isRefurbished: null,
    categories: [],
    parentCategories: [],
    countries: [],
    regions: [],
    search: '',
};
