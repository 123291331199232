import { some } from 'lodash-es';

export enum AuthNavLink {
    SignIn = '/sign-in',
    SignUp = '/sign-up',
    ForgotPassword = '/forgot-password',
    SetPassword = '/set-password',
    AddEmail = '/add-email',
    MergeIdentity = '/merge-identity',
    SetToken = '/set-token',
}

const authLinks = [
    AuthNavLink.SignIn,
    AuthNavLink.ForgotPassword,
    AuthNavLink.SignUp,
    AuthNavLink.SetPassword,
];

export const isAuthLink = (v: string) => {
    return some(authLinks, a => v.startsWith(a));
};
