import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
    DeactivateUsersPayload,
    organizationContractCacheKey,
    organizationContractService,
} from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { useToast } from '@hofy/ui';

export const useDeactivateUsersOrganizationContract = (
    organizationId: UUID,
    organizationContractId: UUID,
) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (payload: DeactivateUsersPayload) =>
            organizationContractService.deactivateUsersForOrganizationContract(
                organizationId,
                organizationContractId,
                payload,
            ),
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: [organizationContractCacheKey, organizationId, organizationContractId],
            });
            showToast({
                type: 'positive',
                message: 'User deactivated successfully',
            });
        },
    });

    const deactivateUser = (userId: UUID) => {
        mutation.mutate({ userIds: [userId] });
    };

    return {
        deactivateUser,
        isLoading: mutation.isPending,
    };
};
