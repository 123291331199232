import { intersection, sortBy } from 'lodash-es';
import React, { FC, useEffect, useMemo, useState } from 'react';

import { CountryLabel } from '@hofy/core';
import { allRootRegions, Country, regionHierarchy, someBelongToRegion, SubRegionConfig } from '@hofy/global';
import { useStateEffect } from '@hofy/hooks';
import { useTrCountry, useTrRegion } from '@hofy/i18n';
import { Color } from '@hofy/theme';
import { Box, Chevron, ExpandHeight, FormGridRow, Paragraph2, Paragraph3, SearchInput } from '@hofy/ui';

interface DropShipAvailableListProps {
    countries: Country[];
}

export const DropShipAvailableList: FC<DropShipAvailableListProps> = ({ countries }) => {
    const [filteredCountries, setFilteredCountries] = useState<Country[]>(countries);
    const [forceOpen, setForceOpen] = useState(false);
    const [search, setSearch] = useState<string>('');
    const trCountry = useTrCountry();

    const subRegionConfigs = useMemo(() => {
        let subRegions: SubRegionConfig[] = [];
        allRootRegions.forEach(rootRegionKey => {
            const region = regionHierarchy.find(rootRegion => rootRegion.region === rootRegionKey);
            if (region) {
                subRegions.push(...region.subregions);
            }
        });

        return subRegions.filter(subRegion => someBelongToRegion(subRegion.region, filteredCountries));
    }, [filteredCountries]);

    useEffect(() => {
        if (countries.length > 0) {
            setFilteredCountries(
                countries.filter(c => trCountry(c).toLowerCase().startsWith(search.toLowerCase())),
            );

            if (search.length > 0) {
                setForceOpen(true);
            } else {
                setForceOpen(false);
            }
        }
    }, [search, countries]);

    if (countries.length === 0) {
        return (
            <Box flex='auto' overflow='auto' paddingBottom={10} relative>
                <Paragraph2>No countries selected</Paragraph2>
            </Box>
        );
    }

    return (
        <Box flex='auto' overflow='auto' paddingBottom={10} relative>
            <SearchInput value={search} onChange={setSearch} placeholder='Search country…' />
            <Box column marginTop={30}>
                {subRegionConfigs.map((subRegion, index) => (
                    <RegionBlock
                        countries={intersection(filteredCountries, subRegion.countries)}
                        region={subRegion}
                        key={subRegion.region}
                        isLast={index === subRegionConfigs.length - 1}
                        alwaysOpen={forceOpen}
                    />
                ))}
            </Box>
        </Box>
    );
};

interface RegionBlockProps {
    countries: Country[];
    region: SubRegionConfig;
    isLast: boolean;
    alwaysOpen: boolean;
}

const RegionBlock: FC<RegionBlockProps> = ({ region, countries, isLast, alwaysOpen }) => {
    const trRegion = useTrRegion();
    const trCountry = useTrCountry();

    const [isOpen, setIsOpen] = useStateEffect(alwaysOpen, [alwaysOpen]);
    const regionCountries = useMemo(() => sortBy(countries, [c => trCountry(c)]), [countries, trCountry]);

    return (
        <Box>
            <Box
                paddingVertical={15}
                row
                onClick={() => setIsOpen(!isOpen)}
                borderBottom={!isLast && !isOpen}
            >
                <Paragraph3 bold row color={Color.ContentPrimary} flex={1}>
                    {trRegion(region.region)}
                    <Box marginLeft={8}>
                        <Chevron isOpen={isOpen} />
                    </Box>
                </Paragraph3>
            </Box>
            <Box flex={1}>
                <ExpandHeight>
                    {isOpen && (
                        <FormGridRow columns='auto-fill' maxColumnWidth={180}>
                            {regionCountries.map(c => (
                                <Box key={c} row paddingVertical={5}>
                                    <CountryLabel country={c} flex={1} textNoWrap />
                                </Box>
                            ))}
                        </FormGridRow>
                    )}
                </ExpandHeight>
            </Box>
        </Box>
    );
};
