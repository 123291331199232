import { xor } from 'lodash-es';
import React, { FC, useState } from 'react';

import { useOrdersQuery } from '@hofy/api-admin';
import { allOrderStatuses, OrderStatus, useTrOrderRequestStatus } from '@hofy/api-shared';
import { Page } from '@hofy/common';
import { UUID } from '@hofy/global';
import { ErrorStatePlaceholder, FilterChipList, PageHeader, SearchInput } from '@hofy/ui';

import { BlockFilterButton } from '../../components/design/blockFilters/BlockFilterButton';
import { BlockFilterChipContainer } from '../../components/design/blockFilters/BlockFilterChipContainer';
import { BlockFilterContainer } from '../../components/design/blockFilters/BlockFilterContainer';
import { EnumMultiBlockFilter } from '../../components/design/blockFilters/EnumMultiBlockFilter';
import { useBlockFilters } from '../../components/design/blockFilters/hooks/useBlockFilters';
import { OrganizationBlockFilter } from '../../components/domain/filters/OrganizationBlockFilter';
import { OrganizationFilterChip } from '../../components/domain/filters/OrganizationFilterChip';
import { OrdersTable } from '../../components/domain/requests/OrdersTable';
import { useOrganizationsRefsQuery } from '../../store/organizations/useOrganizationsQuery';
import { useOrdersFilter } from '../../store/request/useOrdersFilter';

interface OrdersPageProps {
    onOpenOrder(id: UUID): void;
}

export const OrdersPage: FC<OrdersPageProps> = ({ onOpenOrder }) => {
    const [orgSearchQuery, setOrgSearchQuery] = useState('');

    const { filters, setSearch, setOrganizations, setStatuses, filterCount } = useOrdersFilter();
    const { data: organizations, isLoading: organizationsIsLoading } = useOrganizationsRefsQuery(
        filters.organizations ?? [],
        orgSearchQuery,
    );
    const trRequestStatus = useTrOrderRequestStatus();

    const {
        data: orders,
        isLoading,
        isError,
        isFetchingNextPage,
        fetchNextPage,
        hasNextPage,
    } = useOrdersQuery(filters);
    const { showFilters, toggleShowFilters, filterElRef } = useBlockFilters();

    if (isError) {
        return <ErrorStatePlaceholder />;
    }

    return (
        <Page>
            <PageHeader
                title='Orders'
                rightSlot={
                    <>
                        <SearchInput
                            placeholder='Search orders'
                            value={filters.search || ''}
                            onChange={setSearch}
                            autoFocus
                        />
                        <BlockFilterButton
                            onClick={toggleShowFilters}
                            isOpened={showFilters}
                            count={filterCount}
                        />
                    </>
                }
            />
            <BlockFilterContainer ref={filterElRef} show={showFilters}>
                <OrganizationBlockFilter
                    selected={filters.organizations || []}
                    onChange={setOrganizations}
                    organizations={organizations}
                    searchQuery={orgSearchQuery}
                    setSearchQuery={setOrgSearchQuery}
                />
                <EnumMultiBlockFilter<OrderStatus>
                    title='Status'
                    selected={filters.statuses || []}
                    onChange={setStatuses}
                    items={allOrderStatuses}
                    renderItem={trRequestStatus}
                />
            </BlockFilterContainer>
            <BlockFilterChipContainer show={filterCount > 0}>
                <OrganizationFilterChip
                    value={filters.organizations || []}
                    organizations={organizations}
                    onChange={setOrganizations}
                />
                <FilterChipList<OrderStatus>
                    color='grape'
                    toKey={id => id}
                    selected={filters.statuses || []}
                    toLabel={trRequestStatus}
                    onClear={cleared => setStatuses(xor(filters.statuses, [cleared]))}
                />
            </BlockFilterChipContainer>
            <OrdersTable
                orders={orders}
                infinityScroll={{
                    isLoading: isLoading || organizationsIsLoading,
                    hasMore: hasNextPage,
                    isLoadingMore: isFetchingNextPage,
                    loadMore: fetchNextPage,
                }}
                onOpenOrder={onOpenOrder}
            />
        </Page>
    );
};
