import React, { FC } from 'react';

import { Color } from '@hofy/theme';
import { SvgIcon, TooltipIcon } from '@hofy/ui';

import { Indicator } from '../../../design/indicator/Indicator';

interface StoreAndReuseAvailabilityIndicatorProps {
    isProductStoreAndReuseAvailable: boolean;
    isCategoryStoreAndReuseAvailable: boolean;
}

export const StoreAndReuseAvailabilityIndicator: FC<StoreAndReuseAvailabilityIndicatorProps> = ({
    isProductStoreAndReuseAvailable,
    isCategoryStoreAndReuseAvailable,
}) => {
    const color = isProductStoreAndReuseAvailable
        ? Color.AccentTeal
        : isCategoryStoreAndReuseAvailable
          ? Color.AccentYellowPastel
          : Color.Neutral300;

    const label = isProductStoreAndReuseAvailable
        ? 'Contract reuse available'
        : isCategoryStoreAndReuseAvailable
          ? 'Same category contract reuse available'
          : 'No store and reuse contract available';

    return (
        <Indicator color={color}>
            <TooltipIcon icon={SvgIcon.Globe} iconColor={color} body={label} iconSize={16} />
        </Indicator>
    );
};
