import { useQuery } from '@tanstack/react-query';

import { UUID } from '@hofy/global';

import { organizationContractService } from '../service/organizationContractService';
import { organizationContractCacheKey } from './cacheKey';

export const useOrganizationContract = (organizationId: UUID, organizationContractId: UUID) => {
    const { data: organizationContract, isLoading } = useQuery({
        queryKey: [organizationContractCacheKey, organizationId, organizationContractId],
        queryFn: () =>
            organizationContractService.getOrganizationContract(organizationId, organizationContractId),
    });

    return {
        organizationContract,
        isLoading,
    };
};
