/* eslint-disable import/no-cycle */
import { authService } from './authService';
import { BaseAuthProvider } from './baseAuthProvider';
import { AuthError, isAuthError } from './types/AuthError';
import { OrganizationSignupPayload } from './types/OrganizationSignupPayload';
import { PublicSSO } from './types/PublicSSO';
import { ResetPasswordPayload } from './types/ResetPasswordPayload';
import { SendEmailVerificationPayload } from './types/SendEmailVerificationPayload';
import { SetPasswordPayload } from './types/SetPasswordPayload';
import { SignInPayload } from './types/SignInPayload';
import { SignUpSignInPayload } from './types/SignUpPayload';
import { SwitchIdentityPayload } from './types/SwitchIdentityPayload';
import { TokenPair } from './types/TokenPairDto';

const baseUrl = `${window.location.protocol}//${window.location.host}`;

interface SignInWithConnectionPayload {
    signUpToken: string | null;
    redirect: string | null;
}

class AuthProvider extends BaseAuthProvider {
    protected refresh(args: { refresh_token: string }): Promise<TokenPair | AuthError> {
        return authService.refresh(args);
    }

    protected revoke(args: { refresh_token: string }): Promise<void> {
        return authService.revoke(args);
    }

    protected exchangeTemporary(args: { token: string }): Promise<TokenPair | AuthError> {
        return authService.exchangeTemporary(args);
    }

    public signIn = async (payload: SignInPayload): Promise<TokenPair | AuthError> => {
        try {
            const tokens = await authService.signIn(payload);
            if (!isAuthError(tokens)) {
                this.setValueInStorage(tokens);
            }
            return tokens;
        } catch (e) {
            this.clearToken();
            return Promise.reject(e);
        }
    };
    public switchIdentity = async (payload: SwitchIdentityPayload): Promise<TokenPair | AuthError> => {
        const tokens = await authService.switchIdentity(payload);
        if (!isAuthError(tokens)) {
            this.setValueInStorage(tokens);
        }
        return tokens;
    };

    public signInWithPublicSSO = async (sso: PublicSSO, payload: SignInWithConnectionPayload) => {
        return this.signInWithConnection(sso, payload);
    };
    public signInWithConnection = async (
        connection: string,
        { signUpToken, redirect }: SignInWithConnectionPayload,
    ) => {
        if (signUpToken) {
            await authService.signUp({ token: signUpToken });
        }
        const params = new URLSearchParams();
        params.append('redirect', redirect || '');
        const [connectionType, uuid] = connection.split('.');
        switch (connectionType) {
            case 'saml':
                window.location.href = `${baseUrl}/api/auth/saml/${uuid}/init?${params.toString()}`;
                return;
            default:
                window.location.href = `${baseUrl}/api/auth/${connection}/init?${params.toString()}`;
                return;
        }
    };

    public signUpWithPublicSSO = async (connection: string) => {
        window.location.href = `${baseUrl}/api/auth/self-sign-up/${connection}/init`;
    };

    public forgotPassword = (p: ResetPasswordPayload): Promise<void> => {
        return authService.resetPassword(p);
    };

    public setPassword = async (p: SetPasswordPayload): Promise<TokenPair | AuthError> => {
        const newTokenPair = await authService.setPassword(p);
        this.consumeTokenPair(newTokenPair);
        return newTokenPair;
    };

    public signUp = async (p: SignUpSignInPayload): Promise<void> => {
        await authService.signUp(p);
        if (p.password) {
            const tokens = await authService.signIn({ email: p.email, password: p.password, code: '' });
            this.consumeTokenPair(tokens);
        }
    };

    public sendEmailVerification = async (p: SendEmailVerificationPayload): Promise<void> =>
        authService.verifyEmail({ email: p.email, recaptchaToken: p.recaptchaToken });

    public organizationSignUp = async (p: OrganizationSignupPayload): Promise<void> => {
        const tokens = await authService.organizationSignUp(p);
        this.consumeTokenPair(tokens);
    };
}

export const authProvider = new AuthProvider();
