import React, { FC, ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

import { Color } from '@hofy/theme';

interface LinkProps {
    to: string;
    children?: ReactNode;
    color?: Color;
    underline?: boolean;
    flex?: boolean;
}

export const Link: FC<LinkProps> = ({ to, underline = true, color, flex, children }) => {
    return (
        <LinkWrapper
            $flex={flex}
            $underline={underline}
            $color={color}
            to={to}
            onClick={e => e.stopPropagation()}
        >
            {children}
        </LinkWrapper>
    );
};

const LinkWrapper = styled(RouterLink)<{ $color?: Color; $flex?: boolean; $underline: boolean }>`
    :hover {
        text-decoration: ${p => (p.$underline ? 'underline' : 'initial')};
    }
    color: ${p => p.$color};
    display: ${p => (p.$flex ? 'flex' : 'initial')};
    align-items: center;
`;
