import { isEmpty } from 'lodash-es';
import React, { FC, ReactNode } from 'react';

import { useBaseI18n } from '@hofy/i18n';

import { Box } from '../base';
import { Button } from '../form';
import { SvgIcon } from '../icon';

export interface FilterGroupsProps {
    onClearAll?(): void;
    children?: ReactNode;
}

export const FilterGroups: FC<FilterGroupsProps> = ({ onClearAll, children }) => {
    const { tr } = useBaseI18n();

    return (
        <Box row wrap gap={16}>
            {children}
            {!isEmpty(children) && onClearAll && (
                <Button
                    label={tr('ui.filters.reset-filters')}
                    leftIcon={SvgIcon.Cross}
                    type='plain'
                    onClick={onClearAll}
                />
            )}
        </Box>
    );
};
