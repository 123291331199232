export const repaymentPlanErrorI18n = {
    'api-error.rejected.title': 'Repayment plan rejected',
    'api-error.rejected.message': 'Cannot perform this operation',
    'api-error.invalid-plan-status.title': 'Invalid plan status',
    'api-error.invalid-plan-status.message': 'Cannot perform this operation',
    'api-error.billing-account-not-created.title': 'Billing account not created',
    'api-error.billing-account-not-created.message': 'Cannot perform this operation',
    'api-error.idempotency-id-not-set.title': 'Idempotency id not set',
    'api-error.idempotency-id-not-set.message': 'Cannot perform this operation',
};
