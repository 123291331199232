import { isEmpty } from 'lodash-es';
import React, { FC, useState } from 'react';

import { useAssignmentQuery } from '@hofy/api-admin';
import { AssignmentStatus, isDeliveryPending, Permission } from '@hofy/api-shared';
import { BlockLoader, Slideout, SlideoutFooter, SlideoutHeader } from '@hofy/common';
import { UUID } from '@hofy/global';
import { usePermission } from '@hofy/permission';
import { AsyncButton, Box, ConfirmModal, Modals } from '@hofy/ui';

import { CancelButton } from '../../../components/design/button/CancelButton';
import { useUpdateAssignmentConfiguration } from '../../../store/assignments/useUpdateAssignmentConfiguration';
import { ConfigurationDetails } from './ConfigDetails';

interface ConfigureProductSlideoutProps {
    assignmentId: UUID;
    basePath: string;
    onClose(): void;
}

export const ConfigureProductSlideout: FC<ConfigureProductSlideoutProps> = ({
    assignmentId,
    basePath,
    onClose,
}) => {
    const { data: order, isLoading: orderIsLoading } = useAssignmentQuery(assignmentId);
    const { assignmentConfiguration, assignmentConfigurationIsLoading, isLoadingMutation, form } =
        useUpdateAssignmentConfiguration(assignmentId, basePath);

    const { hasPermission } = usePermission();
    const canUpdateConfiguration = hasPermission(Permission.AdminAssignmentsConfigurationUpdate);

    const [confirmationModal, setConfirmationModal] = useState(false);

    const updateAssignmentConfiguration = () => {
        const { userName, password, recoveryKey, checklistCompleted } = form.values;
        if (checklistCompleted && [userName, password, recoveryKey].some(isEmpty)) {
            setConfirmationModal(true);
            return;
        }

        form.submit();
    };

    const isEditable =
        canUpdateConfiguration &&
        assignmentConfiguration?.status &&
        order?.status &&
        (isDeliveryPending(order.status) || order.status === AssignmentStatus.WithUser);

    return (
        <Slideout width={600} onClose={onClose}>
            <SlideoutHeader title='Configure product' justify='space-between' paddingRight={20} />
            {assignmentConfigurationIsLoading ||
            orderIsLoading ||
            !assignmentConfiguration ||
            !order?.product ? (
                <BlockLoader fullHeight flex={1} />
            ) : (
                <Box relative flex='auto' column>
                    <ConfigurationDetails
                        assignmentConfiguration={assignmentConfiguration}
                        operatingSystem={order.product.os}
                        form={form}
                        isReadOnly={!isEditable}
                    />
                </Box>
            )}
            <SlideoutFooter>
                <CancelButton label='Close' onClick={onClose} />
                <AsyncButton
                    disabled={!isEditable}
                    isLoading={isLoadingMutation}
                    label='Save'
                    onClick={updateAssignmentConfiguration}
                    disableCheck
                />
            </SlideoutFooter>
            <Modals>
                {confirmationModal && (
                    <ConfirmModal
                        keyPrefix='assignment-configuration-confirmation-modal'
                        onClose={() => setConfirmationModal(false)}
                        onConfirm={form.submit}
                    />
                )}
            </Modals>
        </Slideout>
    );
};
