import { xor } from 'lodash-es';
import React, { FC, useState } from 'react';

import { allExportFields, ExportField } from '@hofy/api-admin';
import { AsyncButton, Box, LabeledCheckbox, SvgIcon } from '@hofy/ui';

import { useExport } from '../../../../store/exportImport/useExport';
import { useTrExportField } from '../../../../store/exportImport/useTrExportField';

export const ExportSection: FC = () => {
    const { exportData, isExportInProgress } = useExport();
    const [selectedExports, setSelectedExports] = useState<ExportField[]>([]);
    const trExportField = useTrExportField();
    return (
        <Box>
            <Box column gap={8}>
                {allExportFields.map(field => (
                    <Box key={field} row>
                        <LabeledCheckbox
                            label={trExportField(field)}
                            checked={selectedExports.includes(field)}
                            onChange={() => setSelectedExports(xor(selectedExports, [field]))}
                        />
                    </Box>
                ))}
            </Box>
            <AsyncButton
                label='Export'
                leftIcon={SvgIcon.Download}
                onClick={() => exportData(selectedExports)}
                isLoading={isExportInProgress}
                disabled={selectedExports.length === 0}
                marginTop={16}
            />
        </Box>
    );
};
