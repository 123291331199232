import { sortBy } from 'lodash-es';
import React, { FC } from 'react';

import { UUID } from '@hofy/global';
import { BoxProps, FormFieldApi, FormSelectSearch, Skeleton } from '@hofy/ui';

import { useVariants } from '../../hooks/useVariants';

interface VariantSelectProps extends BoxProps {
    api: FormFieldApi<UUID | null>;
    disabled?: boolean;
}

export const VariantSelect: FC<VariantSelectProps> = ({ api, disabled, ...boxProps }) => {
    const { variants, isLoading, variantMap } = useVariants();

    if (isLoading) {
        return <Skeleton />;
    }

    return (
        <FormSelectSearch
            api={api}
            label='Variant'
            disabled={disabled}
            options={sortBy(variants, [v => v.sku]).map(v => v.id)}
            toLabel={v => variantMap[v].sku}
            toText={v => variantMap[v].sku}
            placeholder='Select SKU'
            {...boxProps}
        />
    );
};
