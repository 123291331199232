import { regionI18n } from '@hofy/global';

import { acquisitionTypeI18n } from './acquisitionTypeI18n';
import { addonItemI18n } from './addonItemI18n';
import { addonTargetI18n } from './addonTargetI18n';
import { addonTypeI18n } from './addonTypeI18n';
import { apiError18n } from './apiErrori18n';
import { assetActionDisabledReasonI18n } from './assetActionDisabledReasonl18n';
import { assetsGradeI18n } from './assetsGradeI18n';
import { assetStorageStatusI18n } from './assetStorageStatusI18n';
import { assignmentCollectionReasonI18n } from './assignmentCollectionReasonI18n';
import { assignmentExceptionI18n } from './assignmentExceptionI18n';
import { assignmentShipmentIssueI18n } from './assignmentShipmentIssueI18n';
import { assignmentStatusI18n } from './assignmentStatusI18n';
import { basketSizeI18n } from './basketSizeI18n';
import { betaFeatureI18n } from './betaFeatureI18n';
import { cancellationReasonI18n } from './cancellationReasonI18n';
import { contractEndOptionI18n, contractEndOptionShortI18n } from './contractEndOptionI18n';
import {
    contractExtendOptionDurationI18n,
    contractExtendOptionExplanationI18n,
    contractExtendOptionI18n,
} from './contractExtendOptionI18n';
import { contractStatusI18n } from './contractStatusI18n';
import { contractTypeI18n } from './contractTypeI18n';
import { emailStatusI18n } from './emailStatusI18n';
import { emailTopicI18n } from './emailTopicI18n';
import { emailTypeI18n } from './emailTypeI18n';
import { fleetSupportedOperatingSystemI18n } from './fleetSupportedOperatingSystemI18n';
import { hardDiskI18n } from './hardDiskI18n';
import { inspectionDeviceCheckStatusI18n } from './inspectionDeviceCheckStatusI18n';
import { inspectionStatusI18n } from './inspectionStatusI18n';
import { invoiceI18n } from './invoiceI18n';
import { invoiceStatusI18n } from './invoiceStatusI18n';
import { itemDocumentTypeI18n } from './itemDocumentTypeI18n';
import { itemPartI18n } from './itemPartI18n';
import { itemStatusI18n } from './itemStatusI18n';
import { messageStatusI18n } from './messageStatusI18n';
import { operatingSystemI18n } from './operatingSystemI18n';
import { orderItemStatusI18n } from './orderItemStatusI18n';
import { orderRequestStatusI18n } from './orderRequestStatusI18n';
import { orderStatusI18n } from './orderStatusI18n';
import { organizationContractStatusI18n } from './organizationContractStatusI18n';
import { organizationContractTermI18n } from './organizationContractTermI18n';
import { organizationContractTypeI18n } from './organizationContractTypeI18n';
import { organizationSizeI18n } from './organizationSizeI18n';
import { organizationStatusI18n } from './organizationStatusI18n';
import { organizationWarehouseTypeI18n } from './organizationWarehouseTypeI18n';
import { ownershipI18n } from './ownershipI18n';
import { parentProductCategoryI18n } from './parentProductCategoryI18n';
import { paidWithSchemaI18n, paymentSchemaI18n, paymentSchemaWithPaymentsI18n } from './paymentSchemaI18n';
import { paymentSessionStatusI18n } from './paymentSessionStatusI18n';
import { paymentTermsI18n } from './paymentTermsI18n';
import { paymentTypeI18n } from './paymentTypeI18n';
import { productBrandI18n } from './productBrandI18n';
import { productCategoryI18n } from './productCategoryI18n';
import { productTierI18n } from './productTierI18n';
import { rentalTermFullI18n, rentalTermI18n, rentalTermShortI18n } from './rentalTermI18n';
import { repaymentPlanTypeI18n } from './repaymentPlanTypeI18n';
import { repaymentStatusI18n } from './repaymentStatusI18n';
import { repaymentSyncStatusI18n } from './repaymentSyncStatusI18n';
import { roleI18n } from './roleI18n';
import { salesOrderStatusI18n } from './salesOrderStatusI18n';
import { seatAssignmentStatusI18n } from './seatAssignmentStatusI18n';
import { shipmentCourierI18n } from './shipmentCourierI18n';
import { shipmentPublicClassI18n } from './shipmentPublicClassI18n';
import { shipmentPublicStatusDatesI18n } from './shipmentPublicStatusDatesI18n';
import { shipmentPublicStatusI18n } from './shipmentPublicStatusI18n';
import { shipmentPublicTypeI18n } from './shipmentPublicTypeI18n';
import { shipmentStatusI18n } from './shipmentStatusI18n';
import { shipmentTrackingExceptionStatusI18n } from './shipmentTrackingExceptionStatusI18n';
import { shipmentTrackingProviderI18n } from './shipmentTrackingProviderI18n';
import { shipmentTrackingStatusI18n } from './shipmentTrackingStatusI18n';
import { shipmentTypeI18n } from './shipmentTypeI18n';
import { storageAssignmentI18n } from './storageAssignmentI18n';
import { storeAndReuseStatusI18n } from './storeAndReuseStatusI18n';
import { taxIdLabelI18n } from './taxIdLabelI18n';
import { teamColorI18n } from './teamColorI18n';
import { unitI18n } from './unitI18n';
import { userStatusI18n } from './userStatusI18n';

export const sharedApiI18n = {
    ...acquisitionTypeI18n,
    ...addonItemI18n,
    ...addonTargetI18n,
    ...addonTypeI18n,
    ...apiError18n,
    ...assetActionDisabledReasonI18n,
    ...assetsGradeI18n,
    ...assetStorageStatusI18n,
    ...assignmentCollectionReasonI18n,
    ...assignmentExceptionI18n,
    ...assignmentShipmentIssueI18n,
    ...assignmentStatusI18n,
    ...basketSizeI18n,
    ...betaFeatureI18n,
    ...cancellationReasonI18n,
    ...contractExtendOptionI18n,
    ...contractExtendOptionDurationI18n,
    ...contractExtendOptionExplanationI18n,
    ...contractEndOptionI18n,
    ...contractEndOptionShortI18n,
    ...contractStatusI18n,
    ...contractTypeI18n,
    ...emailStatusI18n,
    ...emailTopicI18n,
    ...emailTypeI18n,
    ...emailTypeI18n,
    ...fleetSupportedOperatingSystemI18n,
    ...hardDiskI18n,
    ...inspectionDeviceCheckStatusI18n,
    ...inspectionStatusI18n,
    ...invoiceI18n,
    ...invoiceStatusI18n,
    ...itemDocumentTypeI18n,
    ...productBrandI18n,
    ...itemPartI18n,
    ...itemStatusI18n,
    ...messageStatusI18n,
    ...operatingSystemI18n,
    ...orderRequestStatusI18n,
    ...organizationContractStatusI18n,
    ...organizationContractTermI18n,
    ...organizationContractTypeI18n,
    ...organizationSizeI18n,
    ...organizationStatusI18n,
    ...organizationWarehouseTypeI18n,
    ...ownershipI18n,
    ...paidWithSchemaI18n,
    ...paymentSchemaI18n,
    ...paymentSchemaWithPaymentsI18n,
    ...paymentSessionStatusI18n,
    ...paymentTermsI18n,
    ...paymentTypeI18n,
    ...productCategoryI18n,
    ...parentProductCategoryI18n,
    ...productTierI18n,
    ...regionI18n,
    ...rentalTermFullI18n,
    ...rentalTermI18n,
    ...rentalTermShortI18n,
    ...repaymentPlanTypeI18n,
    ...repaymentStatusI18n,
    ...repaymentSyncStatusI18n,
    ...orderItemStatusI18n,
    ...orderStatusI18n,
    ...roleI18n,
    ...salesOrderStatusI18n,
    ...seatAssignmentStatusI18n,
    ...shipmentCourierI18n,
    ...shipmentPublicClassI18n,
    ...shipmentPublicStatusDatesI18n,
    ...shipmentPublicStatusI18n,
    ...shipmentPublicTypeI18n,
    ...shipmentStatusI18n,
    ...shipmentTrackingExceptionStatusI18n,
    ...shipmentTrackingProviderI18n,
    ...shipmentTrackingStatusI18n,
    ...shipmentTypeI18n,
    ...storageAssignmentI18n,
    ...storeAndReuseStatusI18n,
    ...taxIdLabelI18n,
    ...teamColorI18n,
    ...unitI18n,
    ...userStatusI18n,
};

export type SharedI18nKey = keyof typeof sharedApiI18n;
