import { identity } from 'lodash-es';
import React from 'react';

import { InspectionDetailsUnionDto } from '@hofy/api-admin';
import {
    allItemGrades,
    getInspectionStatusVisualType,
    getProductCategoryParts,
    InspectionStatus,
    ItemPart,
    requiresAccessoryResetOnInspection,
    useTrInspectionStatus,
    useTrItemPart,
} from '@hofy/api-shared';
import { RadioGroup } from '@hofy/common';
import { addWorkingDays, formatDate, parseDateTime } from '@hofy/helpers';
import {
    Alert,
    Box,
    CheckboxGroup,
    Form,
    FormCheckbox,
    FormInput,
    FormSection,
    FormSelect,
    FormTextarea,
    Labeled,
    LabelError,
    Paragraph3,
    Span,
    SubmitButton,
} from '@hofy/ui';

import { WarehouseBinSelector } from '../../../components/domain/warehouses/WarehouseBinSelector';
import { getSerialNumberError } from '../../../store/assignments/useScanItem';
import { useUpdateInspectionForm } from '../../../store/inspections/useUpdateInspectionForm';
import { useTrItemGrade } from '../../../store/items/useTrItemGrade';
import { InspectionTimeline } from '../components/InspectionTimeline';

interface InspectionProgressSectionProps {
    inspection: InspectionDetailsUnionDto;
}

export const InspectionProgressSection: React.FC<InspectionProgressSectionProps> = ({ inspection }) => {
    const trItemGrade = useTrItemGrade();
    const trItemPart = useTrItemPart();
    const trInspectionStatus = useTrInspectionStatus();

    const { form, isLoading, isError } = useUpdateInspectionForm(inspection);

    const isFormEnabled = inspection.status === InspectionStatus.InProgress;

    const serialError = getSerialNumberError(inspection.item.serialNumber, inspection.product);

    return (
        <Form
            onSubmit={form.submit}
            isLoading={isLoading}
            isError={isError}
            disabled={!isFormEnabled}
            column
            gap={20}
        >
            <FormSection label='Inspection progress'>
                <Alert
                    description={
                        <Box row gap={10}>
                            <Paragraph3>
                                This item arrived at the warehouse on {formatDate(inspection.statusUpdatedAt)}{' '}
                                and is due for inspection by{' '}
                                <Span bold>
                                    {formatDate(addWorkingDays(parseDateTime(inspection.statusUpdatedAt), 2))}
                                </Span>
                                .
                            </Paragraph3>
                        </Box>
                    }
                    type={getInspectionStatusVisualType(inspection.status)}
                >
                    <InspectionTimeline
                        statusUpdates={inspection.statusUpdates}
                        statusSequence={[
                            InspectionStatus.Pending,
                            InspectionStatus.InProgress,
                            InspectionStatus.Completed,
                        ]}
                        toStatus={trInspectionStatus}
                    />
                </Alert>
            </FormSection>

            <FormSection label='Item condition'>
                <Labeled
                    label='Is the item damaged?'
                    content={
                        <RadioGroup
                            orientation='horizontal'
                            gap={10}
                            items={binaryOptions}
                            onChange={v =>
                                form.setValues({
                                    isDamaged: v === 'Yes',
                                })
                            }
                            value={form.values.isDamaged ? 'Yes' : 'No'}
                            labelFormatter={option => <Paragraph3>{option}</Paragraph3>}
                            disabled={!isFormEnabled}
                        />
                    }
                />

                {form.values.isDamaged && (
                    <FormTextarea
                        label='Damage description'
                        api={form.fields.damageNote ?? ''}
                        nullable
                        isRequired
                    />
                )}

                <Labeled
                    label='Are there damaged parts/accessories?'
                    content={
                        <RadioGroup
                            orientation='horizontal'
                            gap={10}
                            items={binaryOptions}
                            onChange={v =>
                                form.setValues({
                                    isWithDamagedParts: v === 'Yes',
                                })
                            }
                            value={form.values.isWithDamagedParts ? 'Yes' : 'No'}
                            labelFormatter={option => <Paragraph3>{option}</Paragraph3>}
                            disabled={!isFormEnabled}
                        />
                    }
                />

                {form.values.isWithDamagedParts && (
                    <>
                        <CheckboxGroup
                            options={getProductCategoryParts(inspection.product.category)}
                            onChange={damagedParts =>
                                form.setValues({
                                    damagedParts,
                                })
                            }
                            value={form.values.damagedParts}
                            toKey={identity}
                            toLabel={trItemPart}
                        />
                        {form.errors.damagedParts && (
                            <LabelError message={form.errors.damagedParts.toString()} />
                        )}
                        {form.values.damagedParts.includes(ItemPart.Other) && (
                            <FormTextarea
                                label='Damaged part/accessory description'
                                api={form.fields.damagedPartsNote ?? ''}
                                nullable
                                isRequired
                            />
                        )}
                    </>
                )}

                <Labeled
                    label='Are there missing parts/accessories?'
                    content={
                        <RadioGroup
                            orientation='horizontal'
                            gap={10}
                            items={binaryOptions}
                            onChange={v =>
                                form.setValues({
                                    isWithMissingParts: v === 'Yes',
                                })
                            }
                            value={form.values.isWithMissingParts ? 'Yes' : 'No'}
                            labelFormatter={option => <Paragraph3>{option}</Paragraph3>}
                            disabled={!isFormEnabled}
                        />
                    }
                />

                {form.values.isWithMissingParts && (
                    <>
                        <CheckboxGroup
                            options={getProductCategoryParts(inspection.product.category)}
                            onChange={missingParts =>
                                form.setValues({
                                    missingParts,
                                })
                            }
                            value={form.values.missingParts}
                            toKey={v => v}
                            toLabel={trItemPart}
                        />
                        {form.errors.missingParts && (
                            <LabelError message={form.errors.missingParts.toString()} />
                        )}
                        {form.values.missingParts.includes(ItemPart.Other) && (
                            <FormTextarea
                                label='Missing part/accessory description'
                                api={form.fields.missingPartsNote ?? ''}
                                nullable
                                isRequired
                            />
                        )}
                    </>
                )}

                <FormSelect
                    label='Item grade'
                    toText={trItemGrade}
                    api={form.fields.grade}
                    options={allItemGrades}
                    nullable
                    isRequired={form.values.hasAdminConfirmedCompletion}
                    width={160}
                />

                <Labeled
                    label='Is the item missing original packaging?'
                    content={
                        <RadioGroup
                            orientation='horizontal'
                            gap={10}
                            items={binaryOptions}
                            onChange={v =>
                                form.setValues({
                                    isMissingOriginalPackaging: v === 'Yes',
                                })
                            }
                            value={form.values.isMissingOriginalPackaging ? 'Yes' : 'No'}
                            labelFormatter={option => <Paragraph3>{option}</Paragraph3>}
                            disabled={!isFormEnabled}
                        />
                    }
                />
            </FormSection>

            {requiresAccessoryResetOnInspection(inspection.product.category, inspection.product.brand) && (
                <FormSection label='Apple accessory reset'>
                    <FormCheckbox
                        label='I confirm this item is fully reset'
                        api={form.fields.isAccessoryReset}
                    />
                </FormSection>
            )}

            <FormSection label='Item cleaning'>
                <FormCheckbox
                    label='I confirm the item has been cleaned to the best of my ability'
                    api={form.fields.isCleaned}
                />
            </FormSection>

            <FormSection label='Item location'>
                <WarehouseBinSelector
                    api={form.fields.hofyWarehouseBinIdentifier}
                    warehouse={inspection.warehouse}
                    isAddBinPromptEnabled={isFormEnabled}
                    width={200}
                />
            </FormSection>

            {serialError && (
                <FormSection label='Item serial number'>
                    <FormInput
                        label='Serial number'
                        placeholder='Serial number'
                        api={form.fields.serialNumber}
                        disabled={form.fields.cantGetSerialNumber.value}
                        isRequired
                        nullable
                    />

                    <FormCheckbox
                        label='Cannot acquire serial number'
                        api={form.fields.cantGetSerialNumber ?? false}
                    />
                </FormSection>
            )}

            <FormTextarea label='Additional notes' api={form.fields.additionalNotes ?? ''} nullable />

            {isFormEnabled && (
                <FormSection label='Check answers and complete'>
                    <Alert
                        type='informative'
                        description={
                            <Box row gap={10} justify='space-between'>
                                <FormCheckbox
                                    label='I confirm all inspection steps above are done'
                                    api={form.fields.hasAdminConfirmedCompletion}
                                />

                                <SubmitButton
                                    label={
                                        form.values.hasAdminConfirmedCompletion
                                            ? 'Complete inspection'
                                            : 'Save and complete later'
                                    }
                                    type={form.values.hasAdminConfirmedCompletion ? 'primary' : 'secondary'}
                                />
                            </Box>
                        }
                    />
                </FormSection>
            )}
        </Form>
    );
};

const binaryOptions = ['No' as const, 'Yes' as const];
