import { groupBy, max, orderBy } from 'lodash-es';
import React, { FC } from 'react';

import { ListInspectionsFilter, useInspectionsQuery } from '@hofy/api-admin';
import { formatUserName } from '@hofy/api-shared';
import { IconItem, InfiniteScroll } from '@hofy/common';
import { parseOptionalDateTime } from '@hofy/helpers';
import { Color } from '@hofy/theme';
import { Box, ErrorStatePlaceholder, Heading3, Paragraph3, Skeleton, SvgIcon } from '@hofy/ui';

import { getUserLink } from '../../../components/routing/adminLinks';
import { Link } from '../../../components/routing/Link';
import { InspectionCard } from './InspectionCard';
import { useInspectionsPageLinks } from './InspectionsPageContext';

interface InspectionsListProps {
    viewType: 'rows' | 'columns';
    filters: ListInspectionsFilter;
}

export const InspectionsList: FC<InspectionsListProps> = ({ viewType, filters }) => {
    const { inspections, isLoading, isError, hasNextPage, fetchNextPage, isFetchingNextPage } =
        useInspectionsQuery(filters);

    const { getShipmentLink } = useInspectionsPageLinks();

    if (isLoading) {
        return <Skeleton flex={1} />;
    }

    if (isError) {
        return <ErrorStatePlaceholder />;
    }

    if (inspections.length === 0) {
        const title = filters.withDeviceCheckOnly ? 'No device checks' : 'No inspections';
        return (
            <Box marginVertical='auto'>
                <Heading3 textAlign='center'>{title}</Heading3>
                <Paragraph3 marginTop={12} textAlign='center'>
                    {title} for the selected filters
                </Paragraph3>
            </Box>
        );
    }

    const inspectionsGroupedByShipment = orderBy(
        Object.entries(groupBy(inspections, i => i.shipment.id)),
        ([, inspections]) =>
            max(
                inspections.map(i =>
                    parseOptionalDateTime(
                        filters.withDeviceCheckOnly && i.deviceCheck
                            ? i.deviceCheck.deviceCheckStatusUpdatedAt
                            : i.statusUpdatedAt,
                    )?.toUnixInteger(),
                ),
            ),
        'desc',
    );

    return (
        <InfiniteScroll
            direction='column'
            gap={10}
            overflow='auto'
            hasMore={!!hasNextPage}
            isLoadingMore={isFetchingNextPage}
            loadMore={fetchNextPage}
            flex='auto'
        >
            {inspectionsGroupedByShipment.map(([shipmentId, inspections]) => (
                <Box
                    key={shipmentId}
                    column
                    border
                    rounded
                    padding={10}
                    gap={10}
                    bg={Color.BackgroundSubtleNeutral}
                >
                    <Box row gap={10} justify='space-between'>
                        <Link to={getShipmentLink(inspections[0].shipment.id)} color={Color.ContentPrimary}>
                            <IconItem icon={SvgIcon.Truck} textNoWrap>
                                {inspections[0].shipment.publicId}
                            </IconItem>
                        </Link>
                        <Link to={getUserLink(inspections[0].assignmentUser.id)} color={Color.ContentPrimary}>
                            <IconItem icon={SvgIcon.User}>
                                {formatUserName(inspections[0].assignmentUser)}
                            </IconItem>
                        </Link>
                    </Box>
                    {inspections.map(inspection => (
                        <InspectionCard
                            key={inspection.id}
                            inspection={inspection}
                            viewType={viewType}
                            filters={filters}
                        />
                    ))}
                </Box>
            ))}
        </InfiniteScroll>
    );
};
