import { sumBy } from 'lodash-es';

export type Decimal = string;

export const decimalToNumber = (v: Decimal) => parseFloat(v);
export const isZeroDecimal = (v: Decimal) => decimalToNumber(v) === 0;
export const isGreaterThanZeroDecimal = (v: Decimal) => decimalToNumber(v) > 0;
export const isGreaterThanDecimal = (v: Decimal, v2: Decimal) => decimalToNumber(v) > decimalToNumber(v2);
export const isLessThanZeroDecimal = (v: Decimal) => decimalToNumber(v) < 0;
export const isLessThanDecimal = (v: Decimal, v2: Decimal) => decimalToNumber(v) < decimalToNumber(v2);
export const sumDecimals = (items: Decimal[]): Decimal => sumBy(items, decimalToNumber).toFixed(2);
export const minusDecimal = (d1: Decimal, d2: Decimal) => {
    return (decimalToNumber(d1) - decimalToNumber(d2)).toFixed(2);
};
export const isEqualDecimal = (d1: Decimal, d2: Decimal) => decimalToNumber(d1) === decimalToNumber(d2);
export const isDecimal = (v: unknown): v is Decimal => typeof v === 'string' && isFinite(parseFloat(v));
